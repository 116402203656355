import React, { useContext, useState, useEffect } from 'react';
import { CustomerContextUseReducer } from '../Customer/CustomerContext';
import { ToastContainer, toast } from 'react-toastify';

import { handleResponse } from '../autorization/handleResponse';
import {   fetchPricePerStudentAPICall, fetchProgramList, fetchShowAPICall,} from '../services/DataServices';

import {  makeStyles,  Grid,  CircularProgress,  } from '@material-ui/core';


import { Types } from './ShowReducer';
import { GeneralCard } from '../general/GeneralCard';
import { Colors, LightenDarkenColor, isBlank,   } from '../Utils';

import Moment from 'react-moment';


import { ShowContextUseReducer } from './ShowContext';
import { IProgram } from '../interfaces/IProgram';
import { IShowCardData, IShow } from '../interfaces/IShow';
import { GeneralAddButton } from '../general/GeneralButton';
import ShowEdit from './ShowEdit';

const useStyles = makeStyles({
  root: {},
  wrapper: {
    marginTop: '20px',
  },
  dueDate: {
    textAlign: 'left',
    backgroundColor: 'lightgray',
    borderRadius: '12px',
    padding: '12px',
    marginBottom: '10px',
  },

  gray: {
    backgroundColor: 'lightgray',
  },
  generalButton: {
    background: Colors.mainBackgroundColor,
    '&:hover': {
      color: 'black',
      background: LightenDarkenColor(Colors.mainBackgroundColor, 40),
    },
  },

  customerName: {
    textAlign: 'left',
    fontSize: '20px',
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 1,
  },
});

const ShowPresenter = (props: any) => {

  const customerObject = useContext(CustomerContextUseReducer);
  const showObject = useContext(ShowContextUseReducer);
  const [programList, SetProgramList] = useState<IProgram[]>([]);
  const [pricePStudent, SetPricePStudent] = useState<number>(0);
  

  const [shows, SetShows] = useState<IShowCardData[]>([]);
  const [isLoading, SetIsLoading] = useState(false);
  const classes = useStyles();
  

  const showSuccessMessage = (value: string, success: boolean) => {
    if (success)
      toast.success(value, { autoClose: 6000 });
    else
      toast.error(value, { autoClose: 6000 });
  }

  const getPricePerStudent = async () => {
    try {
      const response = await fetchPricePerStudentAPICall();
      response
        .json()
        .then((data: number) => {
          SetPricePStudent(data);
        });
    } catch (error) {
      console.log(error);
    }


  };
    
  const getPrograms = async () => {
    try {
      const response = await fetchProgramList();
      response
        .json()

        .then((data: IProgram[]) => {
          console.log(data);
          /*  data.forEach((element) => {
            console.log(element.description);
          }); */
          SetProgramList(data);
        });
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    if (!isBlank(showObject.state.show.operationOutcome.message))
        showSuccessMessage(showObject.state.show.operationOutcome.message, true);

  }, [showObject.state.show.operationOutcome]);



  const SetMyShows = (passedShows: IShow[]) => {
    let shows = passedShows.map((v) => {
      return { show: v };
    }) as IShowCardData[];

    
    SetShows(shows.sort((a, b) => ((a.show?.datePerformance === undefined ? {} : a.show?.datePerformance) < (b.show?.datePerformance === undefined ? {} : b.show?.datePerformance)) ? 1 : -1));
    
    SetIsLoading(false);
  };

  const addNewShow = () => {
    showObject.dispatch({ type: Types.SET_SHOW_EDIT_VISIBLE, payload: { EditVisible: true }, });
    showObject.dispatch({ type: Types.SET_SHOW_FOUND_VISIBLE, payload: { FoundVisible: false }, });
    showObject.dispatch({ type: Types.SET_CURRENT_SHOW, payload: { selectedShow: {} }, });
    showObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: false }, });
    showObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: true }, });
    showObject.dispatch({ type: Types.SET_IS_DIRTY, payload: { isDirty: false }, });

    
  };

  useEffect(() => {
    SetIsLoading(true);

    const fetchShows = async (customerID: number | undefined) => {
      try {
        console.log('fetch show');
        await fetchShowAPICall(customerID === undefined ? 0 : customerID)
          .then(handleResponse)
          .then((rowData) => SetMyShows(rowData));
      } catch (e) {
        console.log(e);
      }
    };
    getPrograms();
    getPricePerStudent();
    fetchShows(
      customerObject?.state.customer.selectedCustomer?.customer === undefined
        ? 0
        : customerObject?.state.customer.selectedCustomer?.customer
          ?.customerID
    );
  }, [
    customerObject?.state.customer,
    showObject?.state.show.needToUpdate,
  ]);
  
  const handleClick = (passedBackShow: IShowCardData) => {
    showObject.dispatch({ type: Types.SET_CURRENT_SHOW, payload: { selectedShow: passedBackShow }, });
    showObject.dispatch({ type: Types.SET_SHOW_EDIT_VISIBLE, payload: { EditVisible: true }, });
    showObject.dispatch({ type: Types.SET_SHOW_FOUND_VISIBLE, payload: { FoundVisible: false }, });
    showObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: false } });
    showObject.dispatch({ type: Types.UPDATE_SHOW_FLAG, payload: { needToUpdate: false }, });
    showObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: false }, });
    showObject.dispatch({ type: Types.SET_OPEARTION_DISABLED, payload: { operationDisabled: false  }, });
  };

  return (
    <div>
      <ToastContainer autoClose={6000} />

        {showObject.state.show.setShowNewButton ? (
          <div>
            <GeneralAddButton 
             onClick={addNewShow}
            />
          </div>
        ) : null}
      

      <div className="mt-1">
      {showObject.state.show.FoundVisible ? (
        <Grid container className={classes.root} spacing={1}>
          {!shows ||
            shows.map((showsObj) => (
              <Grid key={showsObj.show?.showID} item sm={4} xs={12}>                {/*   <AdministratorCard admin={adm.admin} /> */}

                <GeneralCard
                  entity={showsObj}
                  onSelect={handleClick}
                  defaultBackgroundColor={Colors.showColor}
                  entityId={
                    showsObj.show?.showID === undefined ? 0 : showsObj.show?.showID
                  }
                  children={() => (
                    <div>

                      <div className='mb-1'>
                        <span className='white'>Performance: </span>
                        <b className='white'>
                          {programList.find(item => item.programID === showsObj.show?.programID)?.description}
                        </b>
                      </div>


                      <div className={classes.dueDate}>
                        <span>Performance date: </span>
                        <b>
                          <Moment format="DD MMMM, YYYY HH:mm">
                            {showsObj.show?.datePerformance}
                          </Moment>
                        </b>
                      </div>

                    </div>
                  )}
                />
              </Grid>
            ))}
        </Grid>
      ) : null}

</div>

      {isLoading ? <CircularProgress className="centered" /> : null}

      {showObject.state.show.EditVisible ? (
        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={12}>
            <ShowEdit
              programList={programList}
              pricePerStudent={pricePStudent}
              show={
                showObject?.state.show.selectedShow?.show == null
                  ? undefined
                  : showObject?.state.show.selectedShow?.show
              }
            />
          </Grid>
        </Grid>
      ) : null}

    </div>
  );
};

export default ShowPresenter;
