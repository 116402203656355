import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom'
import { CustomerContextUseReducer } from './CustomerContext';
import { Types } from './CustomerReducer';
import GeneralButton from '../general/GeneralButton';
import { Grid } from '@material-ui/core';

const CustomerCommander = (props: any) => {
  const { history } = props;
  
  const customerObject = useContext(CustomerContextUseReducer);

  const handleFoundCustomers = () => {
    customerObject.dispatch({type: Types.SET_CUSTOMER_EDIT_VISIBLE, payload: { customerEditVisible : false }});
    customerObject.dispatch({type: Types.SET_CUSTOMER_FOUND_VISIBLE, payload: { customerFoundVisible : true }});
    customerObject.dispatch({type: Types.SET_IS_DIRTY, payload: { isDirty: false}});

  };

  const handleEditCustomers = () => {
    customerObject.dispatch({type: Types.SET_CUSTOMER_EDIT_VISIBLE, payload: { customerEditVisible : true }});
    customerObject.dispatch({type: Types.SET_CUSTOMER_FOUND_VISIBLE, payload: { customerFoundVisible : false }});
  };

  const handleProceedToEntities = (props: string) => {
    history.push(props);
  };

  

  

  return (
    <div id="customer-commander">
    <Grid container spacing={1}>
    <Grid item sm={5} md={2} xs={12} lg={2}>
        <GeneralButton
        className='full-width'
        onClick={handleFoundCustomers}
        content='Found Customers'
    /> 
        </Grid>

       <Grid item sm={4}  md={3}  xs={12} lg={2}>
        <GeneralButton
        className='full-width'
        disabled={customerObject.state.customer.editDisable}
        onClick={handleEditCustomers}
        content='Edit Customer'
    /> 
        </Grid>
        <Grid item sm={2} md={1} xs={12} lg={1}>
        <GeneralButton
        className='full-width'
        onClick={() => handleProceedToEntities('/administrators')}
        content='Admins'
    /> 

        </Grid>
        <Grid item sm={2} md={1} xs={12} lg={1}>
        <GeneralButton
        className='full-width'
        onClick={() => handleProceedToEntities('/calls')}
        content='Calls'
    /> 

        </Grid>

        <Grid item sm={2} md={1} xs={12} lg={2}>
        <GeneralButton
        className='full-width'
        onClick={() => handleProceedToEntities('/shows')}
        content='Shows'
    /> 

        
</Grid>
        <Grid item sm={3} xs={12} lg={2}>
        <GeneralButton
        className='full-width'
        onClick={() => handleProceedToEntities('/reminders')}
        content='Reminders'
    /> 
        </Grid>
        <Grid item sm={2} xs={12} lg={2}>
        <GeneralButton
        className='full-width'
        onClick={() => handleProceedToEntities('/invoices')}
        content='Invoices'
    /> 



        
        </Grid>

        

   </Grid>

    



    </div>
  );
};

export default withRouter(CustomerCommander);

