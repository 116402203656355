import React, { useContext } from 'react';
import { ShowContextUseReducer } from './ShowContext';
import { Types } from './ShowReducer';
import { BackToEntityButton } from '../general/GeneralButton';


const ShowCommander = () => {
  const showObject = useContext(ShowContextUseReducer);
 

  const handleFoundShows = () => {
    showObject.dispatch({ type: Types.SET_SHOW_EDIT_VISIBLE, payload: { EditVisible: false }, });
    showObject.dispatch({ type: Types.SET_SHOW_FOUND_VISIBLE, payload: { FoundVisible: true }, });
    showObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true }, });
  };

  return (
    <div>
      {showObject.state.show.EditVisible ? (
        <div>
          <BackToEntityButton 
             content='Show list'
             onClick={handleFoundShows}
        />
        </div>
      ) : null}
      
    </div>
  );
};

export default ShowCommander;
