import React, { useContext } from 'react';

import { BackToEntityButton } from '../general/GeneralButton';
import { QuoteContextUseReducer } from './QuoteContext';

import { Types } from './QuoteReducer';

const QuoteCommander = () => {
  const quoteObject = useContext(QuoteContextUseReducer);

  const handleFoundQuotes = () => {
    quoteObject.dispatch({ type: Types.SET_QUOTE_EDIT_VISIBLE, payload: { EditVisible: false }, });
    quoteObject.dispatch({ type: Types.SET_QUOTE_FOUND_VISIBLE, payload: { FoundVisible: true }, });
    quoteObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true }, });
  };

  return (
    <div>
      {quoteObject.state.quote.EditVisible ? (
        <div>
          <BackToEntityButton
            content='quote list'
            onClick={handleFoundQuotes}
          />

        </div>
      ) : null}

    </div>
  );
};

export default QuoteCommander;
