import React, { useContext, useState, useEffect } from 'react';
import { Colors, LightenDarkenColor, isBlank } from '../Utils';
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import BackToCustomers from '../general/BackToCustomers';
import { CustomerContextUseReducer } from '../Customer/CustomerContext';
import GeneralButton from '../general/GeneralButton';
import { Grid } from '@material-ui/core';
import { classicNameResolver } from 'typescript';
import { InvoiceContextUseReducer, InvoiceProviderUseReducer } from './InvoiceContext';
import InvoiceDue from './InvoiceDue';

const useStyles = makeStyles({

  margin_top_10:
  {
    width: '95%',
    marginTop: '2em',
    margin: '0 auto',
  },
  wrapper: {
    width: '95%',
    margin: '0 auto',
  },

  gray: {
    backgroundColor: 'lightgray',
  },


});

const InvoiceCommander = (props: any) => {
  const { history } = props;
  const classes = useStyles();
  const customerObject = useContext(CustomerContextUseReducer);
  const invoiceObject = useContext(InvoiceContextUseReducer);



  const handleProceedToEntities = (props: string) => {
    history.push(props);
  };


  return (
    <div className={classes.margin_top_10}>
       
      <InvoiceProviderUseReducer>
     
      
        <Grid container spacing={1}>
        <Grid item  lg={2}>
            <div className="left">
              <BackToCustomers />
            </div>
          </Grid>
          <Grid item  lg={12}>
               <InvoiceDue/>  
            </Grid>
          </Grid>
        
     {/*  
      {customerObject?.state.customer.selectedCustomer !== undefined ? (
        <div >
          <Grid container spacing={2}>
            <Grid item sm={12} md={4} xs={12} lg={6}>
              <GeneralButton
                className='full-width'
                onClick={() => handleProceedToEntities('/customerinvoice')}
                content='Customer Invoices'
              />
            </Grid>

          </Grid>
        </div>
      ) : (
        <div>
          <h3>Please, select a customer</h3>


        </div>


      )}
 */}













      </InvoiceProviderUseReducer>

    </div>
  );
};

export default withRouter(InvoiceCommander);

