import React, { useContext, useState, useEffect, useCallback, useRef } from 'react';
import { Colors, LightenDarkenColor, TealSwitch, isBlank } from '../Utils';
import { withRouter } from 'react-router-dom'
import { Button, TextField, makeStyles } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import BackToCustomers from '../general/BackToCustomers';
import { CustomerContextUseReducer } from '../Customer/CustomerContext';
import GeneralButton from '../general/GeneralButton';
import { Grid } from '@material-ui/core';
import { classicNameResolver } from 'typescript';


import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import {
  ColDef, GridReadyEvent, ValueFormatterParams, RowSelectedEvent,
  SelectionChangedEvent,
} from 'ag-grid-community';



import 'ag-grid-community/styles/ag-grid.css';
//import 'ag-grid-community/styles/ag-theme-quartz.css'; 
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';


import { fetchEmailJobsAPI, fetchNextAvailableEmailJobsAPI, sendEmailAPICall } from '../services/DataServices';
import { handleResponse } from '../autorization/handleResponse';
import { IEmailJob } from '../interfaces/IEmailJob';
import { Guid } from 'guid-typescript';
import ProgressBar from '@ramonak/react-progress-bar';




const EmailCampaign = (props: any) => {

  const period = 3000;
  const containerStyle = React.useMemo(() => ({ width: '100%', height: '70vh' }), []);
  const gridStyle = React.useMemo(() => ({ height: '100%', width: '100%' }), []);

  const [rowData, setRowData] = useState<IEmailJob[]>();
  const [relativeCounter, setRelativeCounter] = useState<number>(0);
  const [absoluteCounter, setAbsoluteCounter] = useState<number>(0);
  const [maxEmailJobToProcess, setmaxEmailJobToProcess] = useState<number>(0);
  const [progressBarValue, setProgressBarValue] = useState<number>(0);

  const [currentEmailJob, setCurrentEmailJob] = useState<IEmailJob>();
  const [startJob, setStartJob] = useState<boolean>(false);

  const [password, setPassword] = useState<string>('');
  const [intervFunction, setIntervFunction] = useState<NodeJS.Timeout>();
  const [numberAvailableJobs, setNumberAvailableJobs] = useState<number>(0);
  

  const [isOfficeAccount, setisOfficeAccount] = useState<boolean>(true);
  const onSelectionChanged = React.useCallback((event: SelectionChangedEvent) => {
    var rowCount = event.api.getSelectedNodes();
    //  console.log(rowCount);
    //window.alert('selection changed, ' + rowCount + ' rows selected');
  }, []);

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    { field: 'receiverName', width: 270, },
    { field: 'address', width: 370 },
    { field: 'dateCreated', width: 230 },
    { field: 'dateProcessed', width: 230 },
    { field: 'errorMessage', width: 670 },
    { field: 'status', width: 120 },
  ]);
  const onRowSelected = React.useCallback((event: RowSelectedEvent) => {
    if (event.node.isSelected()) {

      console.log(event.node.data);
    }

  }, []);

   const sendEmail = async (emailJobtoSend: IEmailJob) => {
    if (!isOfficeAccount)
       emailJobtoSend.password = password;
    
    await sendEmailAPICall(emailJobtoSend)
      .then(handleResponse)
      .then(
        (result) => {
          console.log('Email has been sent');
          const id = setInterval(incrementCount, period); // 1000 milliseconds = 1 second
          setIntervFunction(id);
          console.log('new IntervalID (from SendEmail): ' + id);
          setProgressBarValue(Math.round((absoluteCounter * 100) / numberAvailableJobs));
        },
        (error) => {
          console.log(error);
        }
      );
  };


  const loadGridData = async () => {
    await fetchEmailJobsAPI()
      .then(handleResponse)
      .then((data: IEmailJob[]) => {
        setNumberAvailableJobs(data.filter((detail: IEmailJob) => detail.status == 0).length);
        let processedRecords = data.filter((detail: IEmailJob) => detail.status != 0).length;
        setRelativeCounter(processedRecords);
        setRowData(data);
        //console.log(data);
      });
  }

  useEffect(() => {
    //gotoPage();
    loadGridData();
  }, [currentEmailJob]);

  useEffect(() => {
    // loadGridData();
   }, []);
 

  useEffect(() => {
    if (intervFunction != undefined)
      clearInterval(intervFunction);
    if (!startJob) return;
    try {

      fetchNextAvailableEmailJobsAPI()
        .then(handleResponse)
        .then((data: IEmailJob) => {

          console.log('retrieved DATA: ' + data.jobID);
          if (data.jobID == 0) {
            setProgressBarValue(100);
            console.log('No more jobs...');
            //console.log(myCounter);
            console.log(intervFunction);

          } else {
            console.log('IntervalID from useEffect : ' + intervFunction);
            sendEmail(data);
            setCurrentEmailJob(data);
            if (absoluteCounter >= maxEmailJobToProcess)
            {
              clearInterval(intervFunction);
              setStartJob(false);
            }

          }

        });
    } catch (e) {
      console.log(e);
    }


  }, [absoluteCounter]);
  const gridRef = useRef<AgGridReact>(null);
  
  const scrambleAndRefreshAll = useCallback(() => {
    console.log('sdfsdf');
    var params = {
      force: true,
      suppressFlash: true,
    };
    gridRef.current!.api.paginationGoToPage(2);
  }, []);


  
  const gotoPage = () => {
    // Calculate the page number
      const pageNumber = Math.ceil((relativeCounter + 1) / 20); // 100 - pagesize
      
      if  (gridRef.current?.api != undefined)
      {
        console.log('page: '+ pageNumber);
        gridRef.current!.api.paginationGoToPage(pageNumber - 1);
      }
      
  }


  const onGridReady = React.useCallback((params: GridReadyEvent) => {
    // fetch('https://www.ag-grid.com/example-assets/olympic-winners.json')
    //   .then((resp) => resp.json())
    //   .then((data: IEmailJob[]) => setRowData(data));

     //  loadGridData();

    //  try {

    //     fetchEmailJobsAPI()
    //      .then(handleResponse)
    //      .then((data: IEmailJob[]) => { 
    //        setRowData(data);
    //        console.log(data);
    //      } );

    //  } catch (e) {
    //    console.log(e);
    //  }




  }, []);

  const defaultColDef = React.useMemo<ColDef>(() => {
    return {
      editable: false,
      filter: true,
    };
  }, []);

  const incrementCount = () => {
    setAbsoluteCounter((prevCount) => prevCount + 1);
  };



  const startEmailJobs = () => {
    setStartJob(true);
    const id = setInterval(incrementCount, period); // 1000 milliseconds = 1 second
    setIntervFunction(id);
    console.log('Brand new IntervalID: ' + id);

  }



  const useStyles = makeStyles({
    root: {},
    wrapper: {
      width: '95%',
      margin: '0 auto',
      height: '75vh',
    },
    margin_top_10:
    {
      marginTop: 10,
    },
    margin_top_15:
    {
      marginTop: 15,
    },
    gray: {
      backgroundColor: 'lightgray',
    },

    yellow: {
      backgroundColor: '#F6D55C',
    },

    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 16,
    },
    pos: {
      marginBottom: 1,
    },
  });

  const classes = useStyles();

  const stopEmailJobProcess = async () => {
   
    setStartJob(false);
    console.log("sending stopped....");
  }



  return (

    <div className={classes.wrapper}>
      <Grid container spacing={0} style={gridStyle}>

        <Grid item xs={1}>
          <h3 className="left">Email jobs</h3>
        </Grid>
        <Grid item xs={12} style={gridStyle}>
          <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact<IEmailJob>
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              enableCellChangeFlash={true}
              //onRowSelected={onRowSelected}
              rowSelection={'single'}
              pagination={true}
              paginationPageSize={20}
              onSelectionChanged={onSelectionChanged} />
          </div>

        </Grid>
        <Grid item xs={12} className={classes.margin_top_10}>
            <ProgressBar completed={progressBarValue} />
          </Grid>
             
          <Grid item sm={3} md={2} lg={2} xs={12} className={classes.margin_top_15}>
            <label>Total email jobs to proceed:</label><b> {numberAvailableJobs}</b>
            
          </Grid>
        <Grid item sm={3} md={2} lg={2} xs={12} className={classes.margin_top_10}>
          <label >Educational Magic Account ?</label>
          <TealSwitch
            checked={isOfficeAccount}
            onChange={(event) => setisOfficeAccount(event.target.checked)}
          />

        </Grid>

        <Grid item sm={3} md={2} lg={2} xs={12}>
          <TextField
            className="full-width"
            label="Password"
            onChange={(data) => setPassword(data.target.value)}
          />
        </Grid>
        <Grid item sm={3} md={2} lg={2} xs={12}>
          <TextField
            className="full-width"
            label="Max Emails to process"
            onChange={(data) => setmaxEmailJobToProcess(parseInt(data.target.value))}
          />
        </Grid>

        <Grid item sm={3} md={2} lg={1} xs={12} >
          <GeneralButton className={classes.margin_top_10}
            onClick={startEmailJobs}
            content='SEND EMAILS'
          />
        </Grid>
        <Grid item sm={3} md={2} lg={1} xs={12} >
          <GeneralButton className={classes.margin_top_10}
            onClick={stopEmailJobProcess}
            content='STOP'
          />
          </Grid>
          <GeneralButton className={classes.margin_top_10}
            onClick={loadGridData}
            content='refresh'
          />      

      </Grid>

    </div>
  );
};

export default withRouter(EmailCampaign);

