import React, { useEffect, useState, useContext } from 'react';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { ToastContainer, toast } from 'react-toastify';

import {

  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';


import { messages, CreateStringDate } from '../Utils';
import { useForm } from 'react-hook-form';

import { TextField, CircularProgress, Grid, Button } from '@material-ui/core';

import { Select, MenuItem, } from '@material-ui/core';
import { IBookingForm } from '../interfaces/IBookingForm';
import { fetchProgramList, createBooking } from '../services/DataServices';
import { IProgram } from '../interfaces/IProgram';

import { handleResponse } from '../autorization/handleResponse';

import { CustomerContextUseReducer } from '../Customer/CustomerContext';
import { Types } from '../Customer/CustomerReducer';
import GeneralButton, { GeneralCancelButton } from '../general/GeneralButton';
import { Label } from '@material-ui/icons';


const CreateBooking = (props: IBookingForm) => {
  const emptyBooking: IBookingForm = {
    Name: '',
    AdminName: '',
    CustomerEmail: '',
    CustomerMobileNumber: '',
    DatePerformance: new Date(),
    DatePerformanceStr: '',
    Address: '',
    Price: 250,
    GPS: '',
    ProgramID: 1,
    ShortNote: '',
    Note: '',
  };


  const fetchLocalStorage = (): IBookingForm => {
    var values = localStorage.getItem('bookingForm') as string;
    let currentFormValues = JSON.parse(values);
    return currentFormValues;
  };
  const storedValues = fetchLocalStorage();
  const [prgID, SetPrgId] = useState<number>(storedValues == null ? 1 : storedValues.ProgramID);
  const [dateValue, SetDateValue] = useState<Date | null>(new Date());


  useEffect(() => {
    getPrograms();


    customerObjectnew.dispatch({ type: Types.SET_CURRENT_CUSTOMER, payload: { selectedCustomer: undefined } });
  }, []);


  const [initialValues] = useState<IBookingForm | {}>(
    storedValues == null ? 'undefined' : storedValues
  );
  const [isProcessing, SetIsProcessing] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    reset,
    setValue,
  } = useForm<IBookingForm>({
    defaultValues: initialValues,
  });

  const onChanger = () => {
    const values = getValues()
    localStorage.setItem('bookingForm', JSON.stringify(values));
  };

  const handleCustomerNameEntry = () => {
    const values = getValues();
    values.AdminName = values.Name;
    setValue('AdminName', values.AdminName);
    onChanger();
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const values = getValues();
    values.ProgramID = Number(event.target.value as string);
    localStorage.setItem('bookingForm', JSON.stringify(values));
    setValue('ProgramID', values.ProgramID);
    SetPrgId(values.ProgramID);
  };

  const ResetForm = () => {
    reset(emptyBooking);
    var now = new Date();
    let formatted = now.toISOString().slice(0, -8);
    setValue('DatePerformance', formatted);
    SetIsProcessing(false);
    localStorage.setItem('bookingForm', JSON.stringify(emptyBooking));
  };

  const getPrograms = async () => {
    try {
      const response = await fetchProgramList();
      response
        .json()

        .then((data: IProgram[]) => {
          console.log(data);
          /*  data.forEach((element) => {
            console.log(element.description);
          }); */
          setProgramList(data);
        });
    } catch (error) {
      console.log(error);
    }
  };


  const onSubmit = async (data: IBookingForm) => {
    SetIsProcessing(true);
    data.DatePerformanceStr = CreateStringDate(dateValue === null ? new Date() : dateValue);
    data.DatePerformance = dateValue === null ? new Date() : dateValue;
    await createBooking(data)
      .then(handleResponse)
      .then(
        (result) => {
          ResetForm();
          showSuccessMessage(messages.SuccessCreatedBooking, true);
        },
        (error) => {
          showSuccessMessage(`Error: ${error}`, false);
          SetIsProcessing(false);
        }
      );
  };
  const [programList, setProgramList] = useState<IProgram[]>([]);
  const customerObjectnew = useContext(CustomerContextUseReducer);

  const showSuccessMessage = (value: string, success: boolean) => {
    if (success)
      toast.success(value, { autoClose: 6000 });
    else
      toast.error(value, { autoClose: 6000 });

    SetIsProcessing(false);
  }


  return (
    <div className="create-booking">
      <ToastContainer autoClose={6000} />
      <h1>Create a booking</h1>

      <input
        type="hidden"
        name="ProgramID"

        ref={register}

      ></input>



      {isProcessing ? <CircularProgress className="centered" /> : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <TextField
            className="full-width"
            onChange={(event) => handleCustomerNameEntry()}
            name="Name"
            label="Name"
            inputRef={register({ required: true, maxLength: 30 })}
          />
        </div>
        <small className="red">{errors.Name && 'Name is required'} </small>
        <div>
          <span className="left">Administrator name</span>
        </div>
        <div>
          <TextField
            className="full-width"
            onChange={(event) => onChanger()}
            name="AdminName"
            //label="Administrator name"
            inputRef={register({ required: true, maxLength: 30 })}
          />
        </div>


        <div>
          <TextField
            name="CustomerEmail"
            onChange={(event) => onChanger()}
            className="full-width"
            label="Customer email"
            inputRef={register({ required: false, maxLength: 50, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i })}
          />
        </div>
        <small className="red">{errors.CustomerEmail && 'Email is invalid'} </small>
        <div>
          <TextField
            name="CustomerMobileNumber"
            onChange={(event) => onChanger()}
            className="full-width"
            label="Mobile"
            inputRef={register}
          />
        </div>
        <div>
          <div className='left mt-1'>
            <label>Performance date</label>
          </div>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              className='full-width'
              name="DatePerformance"
              inputRef={register({ required: true })}
              onChange={SetDateValue}
              value={dateValue}
            />
          </MuiPickersUtilsProvider>
          {/* <TextField
            inputRef={register({ required: true })}
            onChange={(event) => onChanger()}
            className="full-width"
            name="DatePerformance"
            id="datetime-local"
            label="Performance Date"
            type="datetime-local"
            defaultValue={new Date()}
            InputLabelProps={{
              shrink: true,
            }}
          /> */}
        </div>
        <div>
          <TextField
            name="Address"
            onChange={(event) => onChanger()}
            className="full-width"
            label="Address"
            inputRef={register({ required: true, maxLength: 200 })}
          />
        </div>
        <small className="red">
          {errors.Address && 'Address is too long'}{' '}
        </small>

        <div>
          <TextField
            name="GPS"
            onChange={(event) => onChanger()}
            className="full-width"
            label="GPS"
            inputRef={register({ required: true, pattern: /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/i })}
          />
        </div>
        <small className="red">{errors.GPS && 'GPS is invalid'} </small>

        <div>
          <TextField
            name="Price"
            onChange={(event) => onChanger()}
            className="full-width"
            id="standard-number"
            label="Price"
            type="number"
            inputRef={register({ required: true, maxLength: 5 })}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <small className="red">{errors.Price && 'Price is required'} </small>
        <div className='left mt-1'>
          <label>Select a program</label>
        </div>


        {/* 
        <div>
           <select className="full-width"
              onChange={(event) => onChanger()}
              name="ProgramID" 
              ref={register}>
            {!programList ||
              programList.map((prg) => (
                <option key={prg.programID} value={prg.programID}>
                  {prg.description}
                </option>
              ))}
          </select> 
        </div> */}





        <Select
          className="full-width"
          onChange={handleChange}
          value={prgID}
        >
          {!programList ||
            programList.map((prg) => (
              <MenuItem key={prg.programID} value={prg.programID}>
                {prg.description}
              </MenuItem>
            ))}
        </Select>





        {/* <Select
 inputProps ={{
   "name", inputRef: (ref) => {
     if (!ref) return;
     register({name, value: ref.value});
   }
 }}
 >
   {options.map(item => (
     <MenuItem key={item.id} value={item.value}>
       {item.label}
     </MenuItem>
   ))}
 </Select> */}

        {/* working example */}
        {/*  <FormControl className="full-width">
          <Controller
              
            as={
              <Select 
                  onChange={(event) => onChanger()}
                
                 >
                {!programList ||
                  programList.map((prg) => (
                    <MenuItem key={prg.programID} value={prg.programID}>
                      {prg.description}
                    </MenuItem>
                  ))}
              </Select>
            }
            name="ProgramID"
        
            
            control={control}
          />
        </FormControl>  */}

        {/* 
     <Controller
        name="ProgramID"
        
        as={
          <Select>
        {!programList ||
          programList.map((prg) => (
            <MenuItem   key={prg.programID} value={prg.programID}>
              {prg.description}
            </MenuItem>
          ))}
        control={control}
        </Select>
        }
        /> */}

        {/* 

        <Select
          name="ProgramID"
          inputRef={register}
          id="demo-simple-select"
       >

          {!programList ||
            programList.map((prg) => (
              <MenuItem   key={prg.programID} value={prg.programID}>
                {prg.description}
              </MenuItem>
            ))}

        </Select>   */}

        <div>
          <TextField
            name="ShortNote"
            label="Short Note"
            className="full-width"
            onChange={(event) => onChanger()}
            inputRef={register}
          />
        </div>
        <small className="red">
          {errors.ShortNote && 'Short Note is required'}{' '}
        </small>

        <div>
          <TextField
            name="Note"
            onChange={(event) => onChanger()}
            className="full-width"
            label="Note"
            multiline
            inputRef={register}
          />
        </div>
        <small className="red">{errors.Note && 'Note is required'} </small>

        <div className='mt-1'>
          <Grid container spacing={0} >
            <Grid item sm={3} md={2} lg={1} xs={12}>
              <GeneralButton
                type='submit'
                content='Submit'
              />

            </Grid>
            <Grid item sm={3} md={2} lg={1} xs={12}>

              <GeneralCancelButton
                type='button'
                onClick={ResetForm}
                content='RESET'
              />


            </Grid>
          </Grid>
        </div>

      </form>


    </div>
  );
};

export default CreateBooking;
