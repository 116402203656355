import React, { useContext, useState, useEffect } from 'react';
import { CustomerContextUseReducer } from './CustomerContext';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '95%',
    margin: '0 auto',
  },
  countryStyle:{
    fontWeight: 600,
    fontSize: 18,
    color:'teal',
    margin: 'auto',
    width: '50%',
    padding: '10px',
  }
}));

const CurrentCustomer = () => {

  const customerObjectnew = useContext(CustomerContextUseReducer);
  const classes = useStyles();

  return (
    <div>
      {customerObjectnew.state.customer.selectedCustomer != undefined ?
        (<div className={classes.wrapper}>
          <h2 className='left'>Customer: {customerObjectnew.state.customer.selectedCustomer?.customer?.name}</h2>
        </div>)
        : null}
      <div className={classes.countryStyle}>{customerObjectnew.state.customer.country}</div>
    </div>

  )

}

export default CurrentCustomer
