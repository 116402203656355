import React, {  useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';

import { CustomerContextUseReducer } from '../Customer/CustomerContext';
import { CallProviderUseReducer } from './CallContext';
import CallCommander from './CallCommander';
import CallPresenter from './CallPresenter';
import BackToCustomers from '../general/BackToCustomers';

const useStyles = makeStyles({
  root: {},
  wrapper: {
    width: '95%',
    margin: '0 auto',
    //backgroundColor: 'red',
  },
  margin_top_10:
  {
    width: '95%',
    marginTop: '1em',
    margin: '0 auto',
  },
  gray: {
    backgroundColor: 'lightgray',
  },

  yellow: {
    backgroundColor: '#F6D55C',
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 1,
  },
});
const Call = () => {
  const customerObject = useContext(CustomerContextUseReducer);
  const classes = useStyles();

  return (
    <div>
      <CallProviderUseReducer>
      <div className={classes.margin_top_10}>
            <Grid container spacing={1}>
                <Grid item xs={5}>
                  <div className="left">
                  <BackToCustomers />
                  </div>
                </Grid>
              </Grid>
          </div>

        {customerObject?.state.customer.selectedCustomer !== undefined ? (
          <div className={classes.wrapper}>
            <Grid container spacing={1}>
            <Grid item xs={1}>
                <h3 className="left">Calls</h3>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <div className="left">
                    <CallCommander />
                  </div>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                 <CallPresenter /> 
              </Grid>
            </Grid>
          </div>
        ) : (
          <div className={classes.wrapper}>
            <h3>Please, select a customer</h3>
          </div>
        )}

    </CallProviderUseReducer>
    </div>
  );
};

export default Call;
