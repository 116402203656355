import React, { useState, useContext, useEffect } from 'react';
import { TextField, makeStyles, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import GeneralButton from '../general/GeneralButton';
import { handleResponse } from '../autorization/handleResponse';
import { ICustomerHistory } from '../interfaces/ICustomerHistory';
import { getCustomerSearchHistory } from '../services/DataServices';
import { CustomerContextUseReducer } from './CustomerContext';
import { Types } from './CustomerReducer';


const getStyles = makeStyles({
  textField: {
    width: '100%',
  },
  error_msg: {
    color: 'red',
  },

  buttonsGroup: {
    marginTop: '20px',
    float: 'left',
  },
});

const CustomerSearch = () => {
  const customerObjectnew = useContext(CustomerContextUseReducer);

  const [historyList, SetHistoryList] = useState<ICustomerHistory[]>([]);
  const [searchValue, SetSearchValue] = useState<string>('');
  const [historyValue, SetHistoryValue] = useState<string | undefined>('');

  const classes = getStyles();

  const handleHistoryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    
    let selectedHistory = event.target.value as string
    SetHistoryValue(selectedHistory);
    SetSearchValue(selectedHistory);
    customerObjectnew.dispatch({type: Types.SET_SEARCH_STRING, payload: { whatToSearch : selectedHistory }});
    customerObjectnew.dispatch({type: Types.SET_CUSTOMER_EDIT_VISIBLE, payload: { customerEditVisible : false }});
    customerObjectnew.dispatch({type: Types.SET_CUSTOMER_FOUND_VISIBLE, payload: { customerFoundVisible : true }});
  };

  useEffect(() => {
    fetchHistory();
  }, [customerObjectnew]);

  const fetchHistory = async () => {
    try {
      await getCustomerSearchHistory()
        .then(handleResponse)
        .then((historyList) => SetHistoryList(historyList));
    } catch (e) {
      console.log(e);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      SetData();
    }
  };

  const SetData = () => {
    customerObjectnew.dispatch({type: Types.SET_SEARCH_STRING, payload: { whatToSearch : searchValue }});
    customerObjectnew.dispatch({type: Types.SET_CUSTOMER_FOUND_VISIBLE, payload: { customerFoundVisible : true }});
    customerObjectnew.dispatch({type: Types.SET_CUSTOMER_EDIT_VISIBLE, payload: { customerEditVisible : false }});
    customerObjectnew.dispatch({ type: Types.SET_EXACT_SEARCH_STRING, payload: { exactSearch : false }, });
    
  };

  const handleSearchResult = async () => {
    try {
      SetData();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
        <TextField
          className={classes.textField}
          value={searchValue}
          onChange={(e) => SetSearchValue(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
          label="Search customer"
        />
    
    <FormControl className="full-width">
        <InputLabel >
          Select from history
        </InputLabel>
        <Select
          onChange={handleHistoryChange}
          value={historyValue}
        >
          {!historyList ||
            historyList.map((history) => (
              <MenuItem key={history.id} value={history.customer}>
                {history.customer}
              </MenuItem>
            ))}
        </Select>
        
        </FormControl>
      

      <div className={classes.buttonsGroup}>
      <GeneralButton

        onClick={handleSearchResult}
        content='Search'
    /> 

      </div>
    </div>
  );
};

export default CustomerSearch;
