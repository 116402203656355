import React, { createContext, useReducer, Dispatch } from "react";
import { AdministratorDataType, adminReducer, AdministratorActions } from "./AdministratorReducer";
import { IChildrenProp } from "../interfaces/IChildren";

type InitialStateType = {
  admin: AdministratorDataType;


};

const initialState = {
    admin: { 
      selectedAdministrator: undefined, 
      needToUpdate: false,
      FoundVisible: true,
      EditVisible: false,
      setShowNewButton: true,
      isNew: true,
      operationDisabled: false,
      operationOutcome: {message: '', success: true},
      isDirty: false,
    },
};

const AdministratorContextUseReducer = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<AdministratorActions>;
}>({
  state: initialState,
  dispatch: () => null
});

const mainReducer = (
  { admin  }: InitialStateType,
  action: AdministratorActions 
) => ({
  admin: adminReducer(admin, action),
  
});

const AdministratorProviderUseReducer: React.FC<IChildrenProp> = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <AdministratorContextUseReducer.Provider value={{ state, dispatch }}>
      {children}
    </AdministratorContextUseReducer.Provider>
  );
};

export { AdministratorProviderUseReducer, AdministratorContextUseReducer };