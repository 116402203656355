import React, { useContext, useState, useEffect } from 'react';
import {
  CreateTodayDate4Control,
  messages
} from '../Utils';


import {
  Paper,
  Grid,
  TextField,

  FormControl,
  Select,
  MenuItem,

  InputLabel,
  CircularProgress,
} from '@material-ui/core';
import { Theme, makeStyles, createStyles } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { IQuickBooking } from '../interfaces/IQuickBooking';
import { CustomerContextUseReducer } from './CustomerContext';
import { IProgram } from '../interfaces/IProgram';


import {

  fetchProgramList,
  createCustomerAPICall,
  createQuickCallAPICall,

} from '../services/DataServices';
import { handleResponse } from '../autorization/handleResponse';

import { ToastContainer, toast } from 'react-toastify';

import { Types } from './CustomerReducer';
import GeneralButton, { GeneralAddButton } from '../general/GeneralButton';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },

    buttons_area:
    {
      textAlign: 'left',
      paddingTop: '10px',
    },
    dirty_state:
    {
      borderColor: 'red',
      borderStyle: 'solid',
      borderWidth: '2px',
      padding: '5px',
    },
    new_state:
    {
      borderColor: 'green',
      borderStyle: 'solid',
      borderWidth: '2px',
      padding: '5px',
    },
    clean_state:
    {
      borderColor: 'white',
      borderStyle: 'solid',
      borderWidth: '2px',
    },


    wrapper: {
      width: '100%',
      margin: '0 auto',
      marginTop: '10px',
    },
    innerForm: {
      padding: '10px',
    },
  })
);

const CustomerQuickBooking = (props: IQuickBooking) => {
  const customerObject = useContext(CustomerContextUseReducer);
  const classes = useStyles();
  const emptyQuickBooking: IQuickBooking = {
    programID: 2,
  };

  const [initialValues] = useState<IQuickBooking | {}>(
    emptyQuickBooking == null ? {} : emptyQuickBooking
  );

  const [isLoading, SetIsLoading] = useState(false);
  const [programList, SetProgramList] = useState<IProgram[]>([]);


  const { register, handleSubmit, errors, control, reset } = useForm<IQuickBooking>(
    {
      defaultValues: initialValues,
    }
  );

  const showSuccessMessage = (value: string, success: boolean) => {
    if (success)
      toast.success(value, { autoClose: 6000 });
    else
      toast.error(value, { autoClose: 6000 });
    SetIsLoading(false);
  }


  useEffect(() => {
    getPrograms();
  }, []);

  const onSubmit = async (data: IQuickBooking) => {
    SetIsLoading(true);
    CreateQuickBooking(data);
    reset(emptyQuickBooking);
  };

  const CreateQuickBooking = async (values: IQuickBooking) => {
    console.log(values);
    await createQuickCallAPICall(values)
      .then(handleResponse)
      .then(
        (result) => {
          showSuccessMessage(messages.SuccessCreateCall, true);
        },
        (error) => {
          showSuccessMessage(error, false);

        }
      );
  };

  const getPrograms = async () => {
    try {
      const response = await fetchProgramList();
      response
        .json()

        .then((data: IProgram[]) => {
          console.log(data);
          SetProgramList(data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getSTyle = () => {
    if (customerObject.state.customer.isDirty) return classes.dirty_state;
    else if (customerObject.state.customer.isNew) return classes.new_state;
    else return classes.clean_state;

  }

  return (
    <div className={getSTyle()}>
      <ToastContainer autoClose={6000} />
      {isLoading ? <CircularProgress className="centered" /> : null}

      {customerObject?.state.customer.customerEditVisible ? (
        <div className={classes.wrapper}>
          <Grid container className={classes.root}>

            <Grid item sm={6} xs={12}>
              <div className={classes.root}>
                <Paper elevation={6} className={classes.innerForm}>
                  <form onSubmit={handleSubmit(onSubmit)} >
                    <input
                      type="hidden"
                      name="customerID"
                      ref={register}
                      value={customerObject.state.customer.selectedCustomer === undefined ? 0 : customerObject.state.customer.selectedCustomer.customer?.customerID}
                    ></input>

                    <TextField
                      inputRef={register({ required: false })}
                      className="full-width"
                      name="datePerformance"
                      id="datetime-local"
                      label="Due Date"
                      type="datetime-local"
                      defaultValue={CreateTodayDate4Control()}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Grid item xs={12}>
                      <TextField
                        className="full-width"
                        name="shortNote"
                        label="Short Note"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputRef={register({ required: true })}
                      />

                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="note"
                        className="full-width"
                        label="Notes"
                        multiline
                        inputRef={register({ required: false })}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="price"
                        className="full-width"
                        id="standard-number"
                        defaultValue={'222'}
                        label="Price"
                        type="decimal"
                        inputRef={register({ required: true, pattern: /^^(\d*\.)?\d+$/i })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FormControl className="full-width">
                        <InputLabel>Show</InputLabel>

                        <Controller
                          as={
                            <Select>
                              {!programList ||
                                programList.map((prg) => (
                                  <MenuItem key={prg.programID} value={prg.programID}>
                                    {prg.description}
                                  </MenuItem>
                                ))}
                            </Select>
                          }
                          name="programID"
                          rules={{ required: true }}
                          control={control}
                        />
                      </FormControl>
                      <small className="red">{errors.programID && 'Program is required'} </small>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="gps"
                        className="full-width"
                        label="GPS address for this call"
                        inputRef={register({ required: false })}
                      />
                    </Grid>
                    <Grid container className='mt-1'>
                      <Grid item sm={3} md={3} xs={12}>
                        <GeneralButton
                          className="full-width"
                          type="submit"
                          content="SUBMIT"
                        />
                      </Grid>
                    </Grid>









                  </form>
                </Paper>
              </div>
            </Grid>
          </Grid>
        </div>
      ) : null}
    </div>
  );
};

export default CustomerQuickBooking;
