import React, { useContext, useEffect, useState } from 'react';
import { CustomerCallContextUseReducer } from './CustomerCallContext';

import Moment from 'react-moment';
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import { ICustomerCall } from '../interfaces/ICustomerCall';

import {  Grid, makeStyles, Fab } from '@material-ui/core';
import { fetchCustomerCallNotesByCallId } from '../services/DataServices';
import { handleResponse } from '../autorization/handleResponse';
import { ICallNote } from '../interfaces/ICallNote';
import { ICustomerCallDetail } from '../interfaces/ICustomerCallDetail';
import {
  inBoundCallNoteColor,
  outBoundCallNoteColor,
  LightenDarkenColor,
  Colors,
  copyToClipBoard,
} from '../Utils';
import { Types } from './CustomerCallReducer';
import { Types as TypesCustomer } from '../Customer/CustomerReducer';
import GeneralButton from '../general/GeneralButton';
import { CustomerContextUseReducer } from '../Customer/CustomerContext';
import { ICustomerCardData, ICustomer } from '../interfaces/ICustomer';
import { useHistory } from 'react-router';

const useStyles = makeStyles({
  outbound_call_note: {
    float: 'right',
    backgroundColor: outBoundCallNoteColor,
    width: '70%',
    textAlign: 'left',
    padding: '10px',
    borderRadius: '10px',
  },
  inbound_call_note: {
    width: '70%',
    float: 'left',
    backgroundColor: inBoundCallNoteColor,
    textAlign: 'left',
    padding: '10px',
    borderRadius: '10px',
  },
  padding_top_bottom_10: {
    paddingTop: '0px',
    paddingBottom: '10px',
  },
  cell_name_entity_style: {
    padding: '10px',
  },
  copy_email_button:
  {
    marginLeft: '10px',
    backgroundColor: Colors.mainBackgroundColor,
    "&:hover": {
      color: "black",
      background: LightenDarkenColor(Colors.mainBackgroundColor, 40),
    },
  },
  cell_entity_style: {
    padding: '10px',
  },

  grid_cell_entity_name: {
    textAlign: 'left',
    backgroundColor: Colors.customerCallCellColor,
    fontWeight: 'bold',
    borderRadius: '10px',
    fontSize: 'larger',
    margin: '0px',
  },

  grid_cell_entity: {
    textAlign: 'left',
    backgroundColor: Colors.customerCallCellColor,
    fontWeight: 'normal',
    borderRadius: '10px',
    fontSize: 'larger',
    margin: '0px',
  },

  bold: {
    fontWeight: 'bold',
  },
});

 const CustomerCallDetail = (props: ICustomerCallDetail) => {
  const classes = useStyles();
  let history = useHistory();

  const customerCallObject = useContext(CustomerCallContextUseReducer);
  const customerObject = useContext(CustomerContextUseReducer);
  const [callNotes, SetCallNotes] = useState<ICallNote[]>([]);

  const handleSelectThisCustomer = (passedCustomerCall: ICustomerCall | undefined) => 
  {
    let customer = {name: passedCustomerCall?.customerName, customerID: passedCustomerCall?.customer.customerID, typeCustomerID: passedCustomerCall?.customer.typeCustomerID} as ICustomer;
    let cCust = {customer : customer} as ICustomerCardData;
    customerObject.dispatch({type: TypesCustomer.SET_CURRENT_CUSTOMER, payload: {selectedCustomer : cCust}});
    customerObject.dispatch({type: TypesCustomer.SET_SEARCH_STRING, payload: {whatToSearch : cCust.customer?.name === undefined ? '' : cCust.customer?.name}});
    customerObject.dispatch({ type: TypesCustomer.SET_CUSTOMER_EDIT_VISIBLE, payload: { customerEditVisible: false }, });
    customerObject.dispatch({ type: TypesCustomer.SET_CUSTOMER_FOUND_VISIBLE, payload: { customerFoundVisible: true }, });
    customerObject.dispatch({ type: TypesCustomer.SET_EDIT_DISABLE, payload: { editDisable : true }, });
    customerObject.dispatch({ type: TypesCustomer.SET_EXACT_SEARCH_STRING, payload: { exactSearch : true }, });


  }
  const handleProceedToTheCustomer = (passedCustomerCall: ICustomerCall | undefined) => 
  {
    handleSelectThisCustomer(passedCustomerCall);
    history.push('/customers');
    
  }

  const fetchCallNotes = async () => {
    try {
      await fetchCustomerCallNotesByCallId(
        props.customerCall === undefined ? 0 : props.customerCall?.callID
      )
        .then(handleResponse)
        .then((rowData) => SetCallNotes(rowData));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    console.log('CustomerCallDetail');
    fetchCallNotes();
  }, [props.customerCall]);

  const backToList = () => {
    customerCallObject.dispatch({
      type: Types.SET_PRESENTER_VISIBLE,
      payload: { presenterVisible: true },
    });
  };

  return (
    <div>
      {/* <Grid className="red" container spacing={0}>
        <Grid className="green" item xs={12}>
          <Button onClick={backToList} variant="contained" color="primary">
            BACK TO LIST
          </Button>
        </Grid>
      </Grid> */}

      <Grid className={classes.padding_top_bottom_10} container spacing={0}>
        <Grid className={classes.grid_cell_entity_name} item xs={12} sm={3}>
          <div className={classes.cell_name_entity_style}>Date:</div>
        </Grid>

        <Grid className={classes.grid_cell_entity} item xs={12} sm={9}>
          <div className={classes.cell_entity_style}>
            <Moment format="DD MMMM, YYYY HH:mm">
              {props.customerCall?.dueDate}
            </Moment>
          </div>
        </Grid>
      </Grid>

      <Grid className={classes.padding_top_bottom_10} container spacing={0}>
        <Grid className={classes.grid_cell_entity_name} item xs={12} sm={3}>
          <div className={classes.cell_name_entity_style}>Customer:</div>
        </Grid>

        <Grid className={classes.grid_cell_entity} item xs={12} sm={9}>
          <div className={classes.cell_entity_style}>
            {props.customerCall?.customerName}

            
            <GeneralButton
               className={classes.copy_email_button}
              onClick={() => handleSelectThisCustomer(props.customerCall)}
              content='SELECT THIS CUSTOMER'
          /> 
          <GeneralButton
               className={classes.copy_email_button}
              onClick={() => handleProceedToTheCustomer(props.customerCall)}
              content='proceed to the  CUSTOMER'
          /> 
          </div>
        </Grid>
      </Grid>

      <Grid className={classes.padding_top_bottom_10} container spacing={0}>
        <Grid className={classes.grid_cell_entity_name} item xs={12} sm={3}>
          <div className={classes.cell_name_entity_style}>
            Administrator in charge:
          </div>
        </Grid>

        <Grid className={classes.grid_cell_entity} item xs={12} sm={9}>
          <div className={classes.cell_entity_style}>
            {props.customerCall?.administratorName}
          </div>
        </Grid>
      </Grid>


      <Grid className={classes.padding_top_bottom_10} container spacing={0}>
        <Grid className={classes.grid_cell_entity_name} item xs={12} sm={3}>
          <div className={classes.cell_name_entity_style}>
            Invoice total:
          </div>
        </Grid>

        <Grid className={classes.grid_cell_entity} item xs={12} sm={9}>
          <div className={classes.cell_entity_style}>
            ${props.customerCall?.invoiceTotal}
          </div>
        </Grid>

      </Grid>



      <Grid className={classes.padding_top_bottom_10} container spacing={0}>
        <Grid className={classes.grid_cell_entity_name} item xs={12} sm={3}>
          <div className={classes.cell_name_entity_style}>
            Administrator's email:
          </div>
        </Grid>

        <Grid className={classes.grid_cell_entity} item xs={12} sm={9}>
          <div className={classes.cell_entity_style}>
            {props.customerCall?.adminEmail}
            <Fab
              className={classes.copy_email_button}
              
              onClick={() =>
                copyToClipBoard(
                  props.customerCall?.adminEmail === undefined
                    ? ''
                    : props.customerCall?.adminEmail
                )
              }
              aria-label="add"
            >
              <FileCopyTwoToneIcon />
            </Fab>
          </div>
        </Grid>
      </Grid>

      <Grid className={classes.padding_top_bottom_10} container spacing={0}>
        <Grid className={classes.grid_cell_entity_name} item xs={12} sm={3}>
          <div className={classes.cell_name_entity_style}>Short note:</div>
        </Grid>

        <Grid className={classes.grid_cell_entity} item xs={12} sm={9}>
          <div className={classes.cell_entity_style}>
            {props.customerCall?.shortNote}
          </div>
        </Grid>
      </Grid>

      
      <Grid container spacing={1}>
        {!callNotes ||
          callNotes.map((cNotes) => (
            <Grid key={cNotes.callNoteID} item sm={12} xs={12}>
              <div
                className={
                  cNotes.outbound
                    ? classes.outbound_call_note
                    : classes.inbound_call_note
                }
              >
                <div className={classes.bold}>
                  <Moment format="DD MMMM, YYYY HH:mm">
                    {cNotes.dateCreated}
                  </Moment>
                </div>

                <p
                  dangerouslySetInnerHTML={{
                    __html: cNotes.note === undefined ? '' : cNotes.note,
                  }}
                ></p>
              </div>
            </Grid>
          ))}
      </Grid>
    </div>
  );
                };

export default CustomerCallDetail;
