import React, { useContext, useState, useEffect } from 'react';
import { CustomerContextUseReducer } from '../Customer/CustomerContext';
import Moment from 'react-moment';
import { handleResponse } from '../autorization/handleResponse';
import {
  fetchProgramList,
  fetchInvoicesAPI,
  getPositionsAPICall,
  fetchFilteredProgramList,
  fetchPricePerStudentAPICall,
} from '../services/DataServices';
import {
  IInvoice,
  IInvoiceCardData,
} from '../interfaces/IInvoice';
import {
  makeStyles,
  Grid,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import InvoiceEdit from './InvoiceEdit';
import { InvoiceContextUseReducer } from './InvoiceContext';
import { IPosition } from '../interfaces/IPosition';

import { Types } from './InvoiceReducer';
import { GeneralCard } from '../general/GeneralCard';
import { Colors, LightenDarkenColor, isBlank } from '../Utils';
import { withRouter } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import { IProgram } from '../interfaces/IProgram';
const useStyles = makeStyles({
  root: {},
  wrapper: {
    marginTop: '20px',
  },
  gray: {
    backgroundColor: 'lightgray',
  },
  generalButton: {
    background: Colors.mainBackgroundColor,
    '&:hover': {
      color: 'black',
      background: LightenDarkenColor(Colors.mainBackgroundColor, 40),
    },
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 1,
  },
});

const InvoicePresenter = (props: any) => {
  const { history } = props;

  const customerObjectNew = useContext(CustomerContextUseReducer);
  const invoiceObject = useContext(InvoiceContextUseReducer);

  const [positionList, SetPositionList] = useState<IPosition[]>([]);
  const [invoices, SetInvoices] = useState<IInvoiceCardData[]>([]);
  const [programList, SetProgramList] = useState<IProgram[]>([]);
  const [pricePStudent, SetPricePStudent] = useState<number>(0);
  const [isLoading, SetIsLoading] = useState(false);

 console.log('PRESENTER');
 console.log(invoiceObject?.state.invoice.selectedInvoice?.invoice);

  const classes = useStyles();
  const SetMyInvoices = (passedInvoices: IInvoice[]) => {
    let inv = passedInvoices.map((v) => {
      return { invoice: v };
    }) as IInvoiceCardData[];

    SetInvoices(inv);
    SetIsLoading(false);
  };

   const showSuccessMessage = (value: string, success: boolean) => {
    if (success)
      toast.success(value, { autoClose: 6000 });
    else
      toast.error(value, { autoClose: 6000 });
  }

  const getPricePerStudent = async () => {
    try {
      const response = await fetchPricePerStudentAPICall();
      response
        .json()
        .then((data: number) => {
          SetPricePStudent(data);
          
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    SetIsLoading(true);

    const fetchInvoices = async (customerID: number | undefined) => {
      try {
        console.log('fetch invoice');
        await fetchInvoicesAPI(customerID)
          .then(handleResponse)
          .then((rowData) => SetMyInvoices(rowData));
      } catch (e) {
        console.log(e);
      }
    };

    let typeCUtomerId = customerObjectNew?.state.customer.selectedCustomer?.customer?.typeCustomerID;
    getFilteredPrograms(typeCUtomerId);
    
    getPricePerStudent();    
    fetchInvoices(
      customerObjectNew?.state.customer.selectedCustomer?.customer === undefined
        ? 0
        : customerObjectNew?.state.customer.selectedCustomer?.customer
          ?.customerID
    );
  }, [
    customerObjectNew?.state.customer,
    invoiceObject?.state.invoice.needToUpdate,

  ]);

  const getFilteredPrograms = async (typeCustomerID: number | undefined) => {
    try {
      const response = await fetchFilteredProgramList(typeCustomerID);
      response
        .json()

        .then((data: IProgram[]) => {
          console.log(data);
          SetProgramList(data);
        });
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    const fetchPositions = async () => {
      try {
        console.log('fetch positions');
        await getPositionsAPICall()
          .then(handleResponse)
          .then((data) => SetPositionList(data));
      } catch (e) {
        console.log(e);
      }
    };

    fetchPositions();

    

    
  }, []);








  useEffect(() => {
    if (!isBlank(invoiceObject.state.invoice.operationOutcome.message))
      showSuccessMessage(invoiceObject.state.invoice.operationOutcome.message, true);

  }, [invoiceObject.state.invoice.operationOutcome]);

  //useEffect(() => {
//    console.log("VASYA");
//  }, [invoiceObject?.state.invoice.selectedInvoice]);


  const handleClick = (passedBackInvoice: IInvoiceCardData) => {
    invoiceObject.dispatch({ type: Types.SET_CURRENT_INVOICE, payload: { selectedInvoice: passedBackInvoice }, });
    invoiceObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: false } });
    invoiceObject.dispatch({ type: Types.SET_IS_DIRTY, payload: { isDirty: false } });
    invoiceObject.dispatch({ type: Types.UPDATE_INVOICE_FLAG, payload: { needToUpdate: false }, });
    invoiceObject.dispatch({ type: Types.SET_OPEARTION_DISABLED, payload: { operationDisabled: false }, });
  };

  return (
    <div>
      <ToastContainer autoClose={6000} />
     

      <Grid container className={classes.root} spacing={1}>
        <Grid item sm={6} xs={12}>
          <Grid container sm={12} xs={12} spacing={1}>


            {!invoices ||
              invoices.map((inv) => (
                <Grid key={inv.invoice?.invoiceID} item sm={12} xs={12}>                {/*   <AdministratorCard admin={adm.admin} /> */}

                  <GeneralCard
                    entity={inv}
                    onSelect={handleClick}
                    defaultBackgroundColor={'#66a1af'}
                    typeId={inv.invoice?.status}
                    colorArray={[
                      { id: 1, color: Colors.performanceCallColor },
                      { id: 2, color: Colors.closedCallColor, },
                    ]}

                    entityId={
                      inv.invoice?.invoiceID === undefined ? 0 : inv.invoice?.invoiceID
                    }
                    children={() => (
                      <div>
                        <b>
                          <Moment format="DD MMMM, YYYY">
                            {inv.invoice?.date}
                          </Moment>
                        </b>

                        <Typography>
                          Status: <b>{inv.invoice?.status == 1 ? 'Due' : 'CLOSED'}</b>
                        </Typography>
                        <Typography>
                          Total: <b>${inv.invoice?.total}</b>
                        </Typography>
                      </div>
                    )}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
        



     

        <Grid item sm={6} xs={12}>
          <InvoiceEdit
            programList={programList}
            pricePerStudent={pricePStudent}
            invoice={
              invoiceObject?.state.invoice.selectedInvoice?.invoice == null
                ? undefined
                : invoiceObject?.state.invoice.selectedInvoice?.invoice 
            }
            
            
          /* admin={editAdmin}   */
          />
        </Grid>
        
      </Grid>
      {isLoading ? <CircularProgress className="centered" /> : null}

      
    </div>
  );
};

export default withRouter(InvoicePresenter);
