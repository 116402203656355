import React, { createContext, useReducer, Dispatch } from "react";
import { CallDataType, callReducer, CallActions } from "./CallReducer";
import { IChildrenProp } from "../interfaces/IChildren";

type InitialStateType = {
  call: CallDataType;

};

const initialState = {
    call: { 
      selectedCall: undefined, 
      needToUpdate: false,
      FoundVisible: true,
      EditVisible: false,
      setShowNewButton: true,
      isNew: true,
      operationDisabled: false,
      operationOutcome: {message: '', success: true},
      isDirty: false,      
    },

  
};

const CallContextUseReducer = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<CallActions>;
}>({
  state: initialState,
  dispatch: () => null
});

const mainReducer = (
  { call  }: InitialStateType,
  action: CallActions 
) => ({
  call: callReducer(call, action),
  
});

const CallProviderUseReducer: React.FC<IChildrenProp> = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <CallContextUseReducer.Provider value={{ state, dispatch }}>
      {children}
    </CallContextUseReducer.Provider>
  );
};

export { CallProviderUseReducer, CallContextUseReducer };