import React, { createContext, useReducer, Dispatch } from "react";
import { HotelDataType, HotelActions, hotelReducer } from "./HotelReducer";
import { IChildrenProp } from "../interfaces/IChildren";

type InitialStateType = {
  hotel: HotelDataType;
};

const initialState = {
  hotel: { 
      selectedHotel: undefined, 
      needToUpdate: false,
      FoundVisible: true,
      EditVisible: false,
      setShowNewButton: true,
      isNew: true,
      operationDisabled: false,
      operationOutcome: {message: '', success: true},
      isDirty: false,
    },
};

const HotelContextUseReducer = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<HotelActions>;
}>({
  state: initialState,
  dispatch: () => null
});

const mainReducer = (
  { hotel  }: InitialStateType,
  action: HotelActions 
) => ({
  hotel: hotelReducer(hotel, action),
});

const HotelProviderUseReducer: React.FC<IChildrenProp> = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <HotelContextUseReducer.Provider value={{ state, dispatch }}>
      {children}
    </HotelContextUseReducer.Provider>
  );
};

export { HotelProviderUseReducer, HotelContextUseReducer };