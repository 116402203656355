import React, { useContext, useState } from 'react';

import {
  messages,
  Colors,

  inBoundCallNoteColor,
  outBoundCallNoteColor,
  generalControlColor,
  LightenDarkenColor,
  CreateTodayDate4Control,
} from '../Utils';
import {
  Theme,
  makeStyles,
  createStyles,
  Switch,
  withStyles,
} from '@material-ui/core';

import 'react-toastify/dist/ReactToastify.css';

import {
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from '@material-ui/core';

import { useForm, Controller } from 'react-hook-form';

import { handleResponse } from '../autorization/handleResponse';




import GeneralButton, {
  GeneralAddButton,
  GeneralDeleteButton,
} from '../general/GeneralButton';

import ConfirmationDialog from '../general/ConfirmationDialog';
import { IHotel, IHotelCardData } from '../interfaces/IHotel';
import { HotelContextUseReducer } from './HotelContext';
import { createHotelAPICall, deleteHotelAPICall, updateHotelAPICall } from '../services/DataServices';
import { Types } from './HotelReducer';



const TealSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: generalControlColor,
    },
    '&$checked + $track': {
      backgroundColor: generalControlColor,
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      margin: theme.spacing(1),
    },
    generalButton: {
      background: Colors.mainBackgroundColor,
      '&:hover': {
        color: 'black',
        background: LightenDarkenColor(Colors.mainBackgroundColor, 40),
      },
    },

    addNoteButton: {
      width: '100%',
      background: Colors.privateCustomerCustomerColor,
      color: 'white',
      '&:hover': {
        color: 'white',
        background: LightenDarkenColor(Colors.privateCustomerCustomerColor, 40),
      },
    },
    clearNoteButton: {
      width: '100%',
      background: Colors.deputyPrincipalColor,
      color: 'white',
      '&:hover': {
        color: 'white',
        background: LightenDarkenColor(Colors.deputyPrincipalColor, 40),
      },
    },
    buttonRemove: {
      background: Colors.dangerBackgroundColor,
    },
    alignedRight: {
      float: 'right',
    },
    outbound_call_note: {
      float: 'right',
      backgroundColor: outBoundCallNoteColor,
      width: '70%',
      textAlign: 'left',
      padding: '10px',
      borderRadius: '10px',
    },
    inbound_call_note: {
      width: '70%',
      float: 'left',
      backgroundColor: inBoundCallNoteColor,
      textAlign: 'left',
      padding: '10px',
      borderRadius: '10px',
    },
    bold: {
      fontWeight: 'bold',
    },

    admin_emails: {
      backgroundColor: Colors.secondBackgroundColor,
      padding: '10px',
      borderRadius: '10px',
      marginTop: '5px',
      marginBottom: '5px',
    },
    admin_emails_empty: {
      display: 'none',
    },

    innerForm: {
      padding: '10px',
    },
  })
);

const HotelEdit = (props: IHotelCardData) => {
  const hotelObject = useContext(HotelContextUseReducer);


  const emptyHotel: IHotel = {
    hotelID: undefined,
    name: '',
    description:'',
    address: '',
    physicalAddress: '',
  };

  const deleteHotelHandler = () => {
    SetOpenConfirmationDialog(true);
  };

  const [openConfirmationDialog, SetOpenConfirmationDialog] = useState<boolean>(
    false
  );

  const [isLoading, SetIsLoading] = useState<boolean>(false);

  const [initialValues] = useState<IHotel | {}>(
    props.hotel == null ? {} : props.hotel
  );

  const {
    register,
    control,
    handleSubmit,
    reset,
    errors,
    getValues,
    setValue,
  } = useForm<IHotel>({
    defaultValues: initialValues,
  });

  const handleNewHotelEvent = () => {
    
    reset(emptyHotel);
    hotelObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: true }, });
    hotelObject.dispatch({ type: Types.SET_OPEARTION_DISABLED, payload: { operationDisabled: false }, });
  };

  const onSubmit = async (data: IHotel) => {
    data.hotelID = hotelObject.state.hotel.selectedHotel?.hotel?.hotelID;
    hotelObject.dispatch({ type: Types.SET_OPEARTION_DISABLED, payload: { operationDisabled: true }, });

    SetIsLoading(true);
    if (hotelObject.state.hotel.isNew) CreateHotel(data);
    else UpdateHotel(data);
  };


  const CreateHotel = async (hotel: IHotel) => {
    await createHotelAPICall(hotel)
      .then(handleResponse)
      .then(
        (hotelID) => {
          hotelObject.dispatch({ type: Types.SET_CURRENT_HOTEL, payload: { selectedHotel: {} }, });
          hotelObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: true, message: messages.SuccessCreateHotel } }, });
          hotelObject.dispatch({ type: Types.SET_HOTEL_EDIT_VISIBLE, payload: { EditVisible: false }, });
          hotelObject.dispatch({ type: Types.SET_HOTEL_FOUND_VISIBLE, payload: { FoundVisible: true }, });
          hotelObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true }, });
          let flagToChange = hotelObject.state.hotel.needToUpdate;
          hotelObject.dispatch({ type: Types.UPDATE_HOTEL_FLAG, payload: { needToUpdate: !flagToChange }, });
          SetIsLoading(false);
        },
        (error) => {
          SetIsLoading(false);
          hotelObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: false, message: error } }, });
        }
      );
  };

const UpdateHotel = async (hotel: IHotel) => {
  await updateHotelAPICall(hotel)
    .then(handleResponse)
    .then(
      (result) => {
        hotelObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: true, message: messages.SuccessUpdatedHotel, }, }, });
        hotelObject.dispatch({ type: Types.SET_HOTEL_EDIT_VISIBLE, payload: { EditVisible: false }, });
        hotelObject.dispatch({ type: Types.SET_HOTEL_FOUND_VISIBLE, payload: { FoundVisible: true }, });
        hotelObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true }, });
        let flagToChange = hotelObject.state.hotel.needToUpdate;
        hotelObject.dispatch({ type: Types.UPDATE_HOTEL_FLAG, payload: { needToUpdate: !flagToChange }, });

        SetIsLoading(false);
      },
      (error) => {
        SetIsLoading(false);
        hotelObject.dispatch({
          type: Types.SET_OUTCOME,
          payload: { operationOutcome: { success: false, message: error } },
        });
      }
    );
};


const DeleteHotel = async (quoteToDelete: IHotel) => {
  await deleteHotelAPICall(quoteToDelete)
    .then(handleResponse)
    .then(
      (result) => {
        hotelObject.dispatch({ type: Types.UPDATE_HOTEL_FLAG, payload: { needToUpdate: true }, });
        hotelObject.dispatch({ type: Types.SET_HOTEL_FOUND_VISIBLE, payload: { FoundVisible: true }, });
        hotelObject.dispatch({ type: Types.SET_HOTEL_EDIT_VISIBLE, payload: { EditVisible: false }, });
        hotelObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true }, });
        hotelObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: true, message: messages.SuccessDeleteQuote, }, }, });
      },
      (error) => {
        hotelObject.dispatch({
          type: Types.SET_OUTCOME,
          payload: { operationOutcome: { success: false, message: error } },
        });
      }
    );
};

const handleConfirmationDeleteDialog = (event: boolean) => {
  SetOpenConfirmationDialog(false);
  if (event === true) {
    DeleteHotel(hotelObject.state.hotel.selectedHotel?.hotel as IHotel);
  }
};

const classes = useStyles();

return (
  <div>
    {openConfirmationDialog ? (
      <ConfirmationDialog
        open={true}
        content="Are you sure you like to delete this call ?"
        onResult={(event) => handleConfirmationDeleteDialog(event)}
      />
    ) : null}

    {isLoading ? <CircularProgress className="centered" /> : null}
    <form onSubmit={handleSubmit(onSubmit)}>
      
      <Grid container spacing={1}>

       
        <Grid item sm={6} xs={12}>
          <TextField
            className="full-width"
            name="name"
            label="Hotel Title"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={register({ required: true })}
          />

        </Grid>
        

        <Grid item sm={6} xs={12}>
          <TextField
            className="full-width"
            name="description"
            label="Description"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={register({ required: true })}
          />
          
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            name="address"
            className="full-width"
            label="GPS Address"
            inputRef={register({ required: true })}
          />
          
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            name="physicalAddress"
            className="full-width"
            label="Street Address"
            inputRef={register({ required: true })}
          />

        </Grid>

      </Grid>

      <div className="mt-1">
        <Grid container spacing={2}>
          <Grid item sm={3} md={3} xs={12}>
            <GeneralButton
              className="full-width"
              type="submit"
              content="update"
            />
          </Grid>


          <Grid item sm={1} md={1} xs={12}>
            <GeneralAddButton
              onClick={handleNewHotelEvent}
              type="reset"
              size="small" />
          </Grid>
          {hotelObject.state.hotel.isNew ? null : (<Grid item sm={2} md={1} lg={1} xs={12}>
            <GeneralDeleteButton
              className="full-width"
              content="delete"
              onClick={deleteHotelHandler}
            />
          </Grid>
          )}
        </Grid>
      </div>

     
    </form>
   
  </div>
);
};
export default HotelEdit;
