import React from 'react';
import { styled } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import { LightenDarkenColor, Colors } from '../Utils';

import {
  Theme,
  makeStyles,
  createStyles,
  Fab,
} from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    generalButton: {
      background: Colors.teacherColor,
      '&:hover': {
        color: 'white',
        background: LightenDarkenColor(Colors.teacherColor, 40),
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },

  
  })
);



interface IData 
{    
    disabled?: boolean;
    className?: string; 
    content : string;
    type? : string;
    onClick?: () => void;
}

interface IButton
{
  onClick?: () => void;
  size?: any;
  type?: any;
}

const CommonButtonStyle = styled(Button)({
  background: Colors.teacherColor,
  "&:hover": {
    color: "white",
    background: LightenDarkenColor(Colors.teacherColor, 40),
  },

  borderRadius: 10,
  color: 'white',
  height: 40,
  padding: '0 30px',
});


const CommonBackToEntityButtonStyle = styled(Fab)({
  background: Colors.teacherColor,
  "&:hover": {
    color: "white",
    background: LightenDarkenColor(Colors.teacherColor, 40),
  },
  color: 'white',
});



const CommonDeleteButtonStyle = styled(Button)({
  background: Colors.rejectedCustomerColor,
  "&:hover": {
    color: "black",
    background: LightenDarkenColor(Colors.rejectedCustomerColor, 40),
  },

  borderRadius: 10,
  color: 'black',
  height: 40,
  padding: '0 30px',
});

const CommonButtonCancelStyle = styled(Button)({
  background: Colors.teamLeaderColor,
  "&:hover": {
    color: "black",
    background: LightenDarkenColor(Colors.mainBackgroundColor, 40),
  },

  borderRadius: 10,
  color: 'black',
  height: 40,
  padding: '0 30px',
});

export default function GeneralButton(props: IData) {
  return <CommonButtonStyle 
           disabled={props.disabled}
           onClick={props.onClick}
           type={props.type === 'submit' ? 'submit' : 'button'}
           className={props.className}
           >{props.content}</CommonButtonStyle>;
}

export  const GeneralCancelButton = (props: IData) => {
  return <CommonButtonCancelStyle 
           disabled={props.disabled}
           onClick={props.onClick}
           type={props.type === 'submit' ? 'submit' : 'button'}
           className={props.className}
           >{props.content}</CommonButtonCancelStyle>;
}

export  const GeneralDeleteButton = (props: IData) => {
  return <CommonDeleteButtonStyle
           disabled={props.disabled}
           onClick={props.onClick}
           type="button"
           className={props.className}
           >{props.content}</CommonDeleteButtonStyle>;
}

export const BackToEntityButton = (props: IData) =>
{
  const classes = useStyles();  
  return <CommonBackToEntityButtonStyle 
             disabled={props.disabled}
             variant="extended"
             onClick={props.onClick}
             type={'button'}
             className={props.className}
        ><NavigateBeforeIcon className={classes.extendedIcon} />
          {props.content}</CommonBackToEntityButtonStyle>;
}


export const GeneralAddButton  = (props: IButton) =>
{
  const classes = useStyles();

    return (
    <div>
            <Fab
              className={classes.generalButton}
              type={props.type}
              size={props.size}
              aria-label="add"
              onClick={props.onClick}>
              <AddIcon />
            </Fab>

    </div>
  );

}