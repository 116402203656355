import { authenticationService } from "./authentication.service";

export function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        console.log('response : ' + response.ok);
        if (!response.ok) {

            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api

                //authenticationService.logout();
                //window.location.reload();
            }
            const error = (data && data.Message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}