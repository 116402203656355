import React, { useContext } from 'react';

import { BackToEntityButton } from '../general/GeneralButton';
import { ExpenseContextUseReducer } from './ExpenseContext';
import { Types } from './ExpenseReducer';

const ExpenseCommander = () => {
  const expenseObject = useContext(ExpenseContextUseReducer);

  const handleFoundExpenses = () => {
    expenseObject.dispatch({ type: Types.SET_EXPENSE_EDIT_VISIBLE, payload: { EditVisible: false }, });
    expenseObject.dispatch({ type: Types.SET_EXPENSE_FOUND_VISIBLE, payload: { FoundVisible: true }, });
    expenseObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true }, });
  };

  return (
    <div>
      {expenseObject.state.expense.EditVisible ? (
        <div>
          <BackToEntityButton
            content='business expense list'
            onClick={handleFoundExpenses}
          />

        </div>
      ) : null}

    </div>
  );
};

export default ExpenseCommander;
