import React, { createContext, useReducer, Dispatch } from "react";
import { ExpenseDataType, ExpenseActions, expenseReducer } from "./ExpenseReducer";
import { CreateDate4ControlwithValue, CreateTodayDate4Control } from "../Utils";
import { IChildrenProp } from "../interfaces/IChildren";


type InitialStateType = {
  expense: ExpenseDataType;
};


let start_date = new Date(new Date().setMonth(new Date().getMonth() - 1));

const initialState = {
  expense: { 
      selectedExpense: undefined, 
      needToUpdate: false,
      FoundVisible: true,
      EditVisible: false,
      setShowNewButton: true,
      isNew: true,
      operationDisabled: false,
      operationOutcome: {message: '', success: true},
      isDirty: false,
      startTime: CreateDate4ControlwithValue(start_date),
      endTime: CreateTodayDate4Control(),
      hotelDropdownVisible: false,
    },
};

const ExpenseContextUseReducer = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<ExpenseActions>;
}>({
  state: initialState,
  dispatch: () => null
});

const mainReducer = (
  { expense  }: InitialStateType,
  action: ExpenseActions 
) => ({
  expense: expenseReducer(expense, action),
});

const ExpenseProviderUseReducer: React.FC<IChildrenProp> = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <ExpenseContextUseReducer.Provider value={{ state, dispatch }}>
      {children}
    </ExpenseContextUseReducer.Provider>
  );
};

export { ExpenseProviderUseReducer, ExpenseContextUseReducer };