import React, { useContext, useState, useEffect } from 'react';
import { CustomerContextUseReducer } from '../Customer/CustomerContext';
import { handleResponse } from '../autorization/handleResponse';
import {
  fetchAdministratorsCall,
  getPositionsAPICall,
} from '../services/DataServices';
import {
  IAdministrator,
  IAdministratorCardData,
} from '../interfaces/IAdministrator';
import {
  makeStyles,
  Grid,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import AdministratorEdit from './AdministratorEdit';
import { AdministratorContextUseReducer } from './AdministratorContext';
import { IPosition } from '../interfaces/IPosition';

import { Types } from './AdministratorReducer';
import { GeneralCard } from '../general/GeneralCard';
import { Position, Colors, LightenDarkenColor, isBlank } from '../Utils';
import { withRouter } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
const useStyles = makeStyles({
  root: {},
  wrapper: {
    marginTop: '20px',
  },
  gray: {
    backgroundColor: 'lightgray',
  },
  generalButton: {
    background: Colors.mainBackgroundColor,
    '&:hover': {
      color: 'black',
      background: LightenDarkenColor(Colors.mainBackgroundColor, 40),
    },
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 1,
  },
});

const AdministratorPresenter = (props: any) => {
  const { history } = props;

  const customerObjectNew = useContext(CustomerContextUseReducer);
  const adminObject = useContext(AdministratorContextUseReducer);

  const [positionList, SetPositionList] = useState<IPosition[]>([]);
  const [admins, SetAdmins] = useState<IAdministratorCardData[]>([]);
  const [isLoading, SetIsLoading] = useState(false);
  const classes = useStyles();
  const SetMyAdmins = (passedAdmins: IAdministrator[]) => {
    let admin_1 = passedAdmins.map((v) => {
      return { admin: v };
    }) as IAdministratorCardData[];

    SetAdmins(admin_1);
    SetIsLoading(false);
  };

   const showSuccessMessage = (value: string, success: boolean) => {
    if (success)
      toast.success(value, { autoClose: 6000 });
    else
      toast.error(value, { autoClose: 6000 });
  }

  useEffect(() => {
    SetIsLoading(true);

    const fetchAdministrators = async (customerID: number | undefined) => {
      try {
        console.log('fetch administrator');
        await fetchAdministratorsCall(customerID)
          .then(handleResponse)
          .then((rowData) => SetMyAdmins(rowData));
      } catch (e) {
        console.log(e);
      }
    };


    fetchAdministrators(
      customerObjectNew?.state.customer.selectedCustomer?.customer === undefined
        ? 0
        : customerObjectNew?.state.customer.selectedCustomer?.customer
          ?.customerID
    );
  }, [
    customerObjectNew?.state.customer,
    adminObject?.state.admin.needToUpdate,

  ]);

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        console.log('fetch positions');
        await getPositionsAPICall()
          .then(handleResponse)
          .then((data) => SetPositionList(data));
      } catch (e) {
        console.log(e);
      }
    };

    fetchPositions();
  }, []);


  useEffect(() => {
    if (!isBlank(adminObject.state.admin.operationOutcome.message))
      showSuccessMessage(adminObject.state.admin.operationOutcome.message, true);

  }, [adminObject.state.admin.operationOutcome]);

// useEffect(() => {
    //console.log("VASYA");
  //}, [adminOb */ject?.state.admin.selectedAdministrator]);


  const handleClick = (passedBackAdmin: IAdministratorCardData) => {
    adminObject.dispatch({ type: Types.SET_CURRENT_ADMIN, payload: { selectedAdministrator: passedBackAdmin }, });
    adminObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: false } });
    adminObject.dispatch({ type: Types.SET_IS_DIRTY, payload: { isDirty: false } });
    adminObject.dispatch({ type: Types.UPDATE_ADMIN_FLAG, payload: { needToUpdate: false }, });
    adminObject.dispatch({ type: Types.SET_OPEARTION_DISABLED, payload: { operationDisabled: false }, });

  };

  return (
    <div>
      <ToastContainer autoClose={6000} />
     

      <Grid container className={classes.root} spacing={1}>
        <Grid item sm={6} xs={12}>
          <Grid container sm={12} xs={12} spacing={1}>


            {!admins ||
              admins.map((adm) => (
                <Grid key={adm.admin?.adminID} item sm={12} xs={12}>                {/*   <AdministratorCard admin={adm.admin} /> */}

                  <GeneralCard
                    entity={adm}
                    onSelect={handleClick}
                    typeId={adm.admin?.positionID}
                    defaultBackgroundColor={'#66a1af'}
                    colorArray={[
                      { id: Position.principal, color: Colors.principalColor },
                      { id: Position.deputyPrincipal, color: Colors.deputyPrincipalColor, },
                      { id: Position.officeManager, color: Colors.officeManagerColor, },
                      { id: Position.inactive, color: Colors.inactivePositionColor, },
                      { id: Position.teamLeader, color: Colors.teamLeaderColor },
                      { id: Position.associatePrincipal, color: Colors.associatePrincipalColor, },
                    ]}
                    entityId={
                      adm.admin?.adminID === undefined ? 0 : adm.admin?.adminID
                    }
                    children={() => (
                      <div>
                        <Typography>{adm.admin?.name}</Typography>

                        <Typography>
                          Position: <b>{adm.admin?.position?.name}</b>
                        </Typography>
                      </div>
                    )}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>


        <Grid item sm={6} xs={12}>
          <AdministratorEdit
            admin={
              adminObject?.state.admin.selectedAdministrator?.admin == null
                ? undefined
                : adminObject?.state.admin.selectedAdministrator?.admin
            }
            positionList={positionList}
          /* admin={editAdmin}   */
          />
        </Grid>
      </Grid>
      {isLoading ? <CircularProgress className="centered" /> : null}
    </div>
  );
};

export default withRouter(AdministratorPresenter);
