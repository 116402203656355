import React from 'react';
import Iframe from 'react-iframe';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
      width: '98%',
      margin: '0 auto',
    },
  }));
  

const ExcelViewer = () => {
    const classes = useStyles();


  return (
    <div className={classes.wrapper}>
      <h2>Bookings</h2>

      <Iframe
        url="https://docs.google.com/spreadsheets/d/1isC7hA-zuUwO0kgZbNaFIVSZ_BJTeSv7rtTG5zCM2dU/edit#gid=1523541966"
        
        
      />
    </div>
  );
};

export default ExcelViewer;



