import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';

import React, { useEffect, useState, useContext, } from 'react';
import { useHistory } from 'react-router';
import GoogleMap, { ICoordinates } from '../general/GoogleMap';
import { useRef } from 'react';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { handleResponse } from '../autorization/handleResponse';
import moment from 'moment';
import { fetchEventsAPI, fetchHotelsAPICall } from '../services/DataServices';
import { IEvent, IEventCardData } from '../interfaces/IEvent';
import { Grid } from '@material-ui/core';
import { Types as TypesCustomer } from '../Customer/CustomerReducer';
import { GeneralCard } from '../general/GeneralCard';
import { Colors, redirectToGoogleMap, isBlank, convertGPSCoordinates } from '../Utils';
import Moment from 'react-moment';
import GeneralButton, { GeneralAddButton } from '../general/GeneralButton';
import { ToastContainer, toast } from 'react-toastify';
import { Types } from './EventManagerReducer';
import { EventContextUseReducer } from './EventContext';
import { CustomerContextUseReducer } from '../Customer/CustomerContext'
import EventEdit from './EventEdit';
import { IHotel } from '../interfaces/IHotel';
import { ICustomer, ICustomerCardData } from '../interfaces/ICustomer';

const useStyles = makeStyles({
  root: {},
  googleButton: {
    marginBottom: '20px',
    float: 'left',
  },

  wrapper: {
    width: '100%',
    margin: '0 auto',
  },
  margin_top_10: {
    width: '95%',
    marginTop: '1em',
    margin: '0 auto',
  },
  gray: {
    backgroundColor: 'lightgray',
  },

  yellow: {
    backgroundColor: '#F6D55C',
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 1,
  },
  white: {
    color: 'white',
  },
});



const EventManagerPresenter = () => {
  const eventObject = useContext(EventContextUseReducer);
  const customerObject = useContext(CustomerContextUseReducer);
  let history = useHistory();

  const [isLoading, SetIsLoading] = useState(false);
  const op = useRef<OverlayPanel>(null);
  const [hotelList, SetHotelList] = useState<IHotel[]>([]);
  const [events, SetEvents] = useState<IEventCardData[]>([]);
 
  let t = new Date(2021, 2, 1)
  const [startDate] = useState<string>(moment(new Date()).format('MM/DD/YYYY H:mm')
  );

  const fetchHotels = async () => {
    
    try {
      await fetchHotelsAPICall()
        .then(handleResponse)
        .then((data) => SetHotelList(data));
    } catch (e) {
      console.log(e);
    }
  };

  const SetMyEvents = (passedEvents: IEvent[]) => {
    let events = passedEvents.map((v) => {
      return { event: v };
    }) as IEventCardData[];
    SetEvents(events);
    SetIsLoading(false);
  };

// const DownloadJSONData = (passedData: IEventCardData[]) => {

//   // Create a Blob from the JSON data
// const blob = new Blob([JSON.stringify(passedData)], { type: 'application/json' });

// // Create a download link
// const link = document.createElement('a');
// link.href = URL.createObjectURL(blob);
// link.download =  'exportedData.json';

// // Append the link to the body
// document.body.appendChild(link);

// // Trigger a click on the link to start the download
// link.click();

// // Remove the link from the body
// document.body.removeChild(link);

// }

 

  const handleSelect = (passedEvent: IEventCardData) => {
    if (passedEvent.event?.typeID === 1) return; // performance

    eventObject.dispatch({
      type: Types.SET_CURRENT_EVENT,
      payload: { selectedEvent: passedEvent },
    });
    eventObject.dispatch({
      type: Types.SET_EVENT_EDIT_VISIBLE,
      payload: { EditVisible: true },
    });
    eventObject.dispatch({
      type: Types.SET_EVENT_FOUND_VISIBLE,
      payload: { FoundVisible: false },
    });
    eventObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: false } });
    eventObject.dispatch({
      type: Types.SET_IS_DIRTY,
      payload: { isDirty: false },
    });
    eventObject.dispatch({
      type: Types.UPDATE_EVENT_FLAG,
      payload: { needToUpdate: false },
    });
    eventObject.dispatch({
      type: Types.SET_OPEARTION_DISABLED,
      payload: { operationDisabled: false },
    });
  };

  const visitingGPS = (gps_coordinates: string) => {
    redirectToGoogleMap(gps_coordinates);
  };

  const addNewEvent = () => {
    eventObject.dispatch({
      type: Types.SET_EVENT_EDIT_VISIBLE,
      payload: { EditVisible: true },
    });
    eventObject.dispatch({
      type: Types.SET_EVENT_FOUND_VISIBLE,
      payload: { FoundVisible: false },
    });
    eventObject.dispatch({
      type: Types.SET_CURRENT_EVENT,
      payload: { selectedEvent: {} },
    });
    eventObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: true } });
    eventObject.dispatch({
      type: Types.SET_IS_DIRTY,
      payload: { isDirty: false },
    });
  };

  useEffect(() => {
    SetIsLoading(true);

    const fetchEvents = async (startDate: string) => {
      try {
        await fetchEventsAPI(startDate, true)
          .then(handleResponse)
          .then((rowData) => SetMyEvents(rowData));
      } catch (e) {
        console.log(e);
      }
    };

    fetchEvents(startDate);
    fetchHotels();
  }, [eventObject?.state.event.needToUpdate]);

  const showSuccessMessage = (value: string, success: boolean) => {
    if (success) toast.success(value, { autoClose: 6000 });
    else toast.error(value, { autoClose: 6000 });
  };

  const handleProceedToTheCustomer = (passedEvent: IEventCardData | undefined) => {
    let customer = { name: passedEvent?.event?.description, customerID: passedEvent?.event?.customerID, typeCustomerID: passedEvent?.event?.typeCustomerID } as ICustomer;
    let cCust = { customer: customer } as ICustomerCardData;
    customerObject.dispatch({ type: TypesCustomer.SET_CURRENT_CUSTOMER, payload: { selectedCustomer: cCust } });
    customerObject.dispatch({ type: TypesCustomer.SET_SEARCH_STRING, payload: { whatToSearch: cCust.customer?.name === undefined ? '' : cCust.customer?.name } });
    customerObject.dispatch({ type: TypesCustomer.SET_CUSTOMER_EDIT_VISIBLE, payload: { customerEditVisible: false }, });
    customerObject.dispatch({ type: TypesCustomer.SET_CUSTOMER_FOUND_VISIBLE, payload: { customerFoundVisible: true }, });
    customerObject.dispatch({ type: TypesCustomer.SET_EDIT_DISABLE, payload: { editDisable: true }, });
    customerObject.dispatch({ type: TypesCustomer.SET_EXACT_SEARCH_STRING, payload: { exactSearch: true }, });

    history.push('/customers');

  }



  const [coordinates, SetCoordinates] = useState<ICoordinates>({
    lat: -36.800247,
    lng: 174.71808,
  });

  useEffect(() => {
    if (!isBlank(eventObject.state.event.operationOutcome.message))
      showSuccessMessage(
        eventObject.state.event.operationOutcome.message,
        true
      );
  }, [eventObject.state.event.operationOutcome]);

  const classes = useStyles();

  const splitGPSCoordinates = (value: string) => {
    let gpsData = convertGPSCoordinates(value);
    SetCoordinates({ lat: gpsData.lat, lng: gpsData.lng });

  };

  return (
    <div>
      <ToastContainer autoClose={6000} />
      {/* <div>
      <GeneralButton 
         className='left'
         content="CREATE JSON OFFLINE FILE"
         onClick={()=>DownloadJSONData(events)}
         />
      </div> */}

      <OverlayPanel ref={op} showCloseIcon id="overlay_panel" style={{ width: '750px' }} dismissable >
        <div><GoogleMap coordinates={coordinates} />
        </div>
      </OverlayPanel>

      <div>{isLoading ? <CircularProgress className="centered" /> : null}</div>

      {eventObject.state.event.FoundVisible ? (
        <div className="white">
          <div className="mb-1">
            <GeneralAddButton onClick={addNewEvent} />
          </div>
          <div className="scrollable">
            <Grid container spacing={1}>
              {!events ||
                events.map((eventObj) => (
                  <Grid key={eventObj.event?.eventID} item sm={12} xs={12}>
                    <GeneralCard
                      entity={eventObj}
                      defaultBackgroundColor={Colors.generalEnquiryColor}
                      typeId={eventObj.event?.typeID}
                      colorArray={[
                        { id: 1, color: Colors.rejectedCustomerColor },
                        { id: 2, color: Colors.customerCallCellColor },
                        { id: 3, color: Colors.travelColor },
                      ]}
                      entityId={
                        eventObj.event?.eventID === undefined
                          ? 0
                          : eventObj.event?.eventID
                      }
                      children={() => (
                        <div>
                          <Grid container spacing={1}>
                            <Grid item sm={12} xs={12}>
                              <div className="mb-1">
                                <h2>{eventObj.event?.eventType}</h2>
                              </div>
                            </Grid>
                          </Grid>

                          <Grid container spacing={1}>
                            <Grid item lg={3} xs={12} className="text-left">
                              <span>Start: </span>
                              <span>
                                <b>
                                  <Moment format="DD MMMM YYYY HH:mm, dddd">
                                    {eventObj.event?.startTime}
                                  </Moment>
                                </b>
                              </span>
                            </Grid>
                            <Grid item lg={3} xs={12} className="text-left">
                              <span>End: </span>
                              <span>
                                {' '}
                                <b>
                                  <Moment format="DD MMMM YYYY HH:mm, dddd">
                                    {eventObj.event?.endTime}
                                  </Moment>
                                </b>{' '}
                              </span>
                            </Grid>
                            <Grid item lg={3} xs={12}>
                              <b>
                                <span>{eventObj.event?.description}</span>
                              </b>
                            </Grid>
                          </Grid>
                          <div className="mt-1">
                            <Grid container spacing={1}>
                              <Grid item lg={2} xs={12} className="text-left">
                                <span>
                                  <b>{eventObj.event?.hotel}</b>
                                </span>
                              </Grid>
                              <Grid item lg={2} xs={12}>

                                {eventObj.event?.destination !== '' ? (
                                  <Button
                                    type="button"
                                    className="p-button-raised p-button-rounded p-button-success"
                                    label={'GOOGLE MAP'}
                                    onClick={(e) => {
                                      splitGPSCoordinates(
                                        eventObj.event?.destination === undefined
                                          ? ''
                                          : eventObj.event?.destination
                                      );

                                      if (null !== op.current) { op.current.toggle(e) }

                                    }
                                    }
                                    aria-haspopup aria-controls="overlay_panel" />
                                ) : null}



                              </Grid>
                              {eventObj.event?.callNote !== '' ? (
                                <Grid item lg={5} xs={12}>
                                  <span className="mr-1">Call note:</span>
                                  <b>{eventObj.event?.callNote}</b>
                                </Grid>
                              ) : null}
                              {eventObj.event?.typeID !== 1 ? (
                                <Grid item lg={2} xs={12}>
                                  <GeneralButton
                                    content="EDIT EVENT"
                                    onClick={() => handleSelect(eventObj)}
                                  />
                                </Grid>
                              ) : null}

                              {eventObj.event?.typeID === 1 ? (
                                <Grid item lg={3} xs={12}>
                                  <GeneralButton
                                    onClick={() => handleProceedToTheCustomer(eventObj)}
                                    content='proceed to the  CUSTOMER'
                                  />
                                </Grid>
                              ) : null}
                            </Grid>
                          </div>
                          <div className="mt-1">
                            <Grid container spacing={0} >
                              <Grid
                                item
                                lg={2}
                                xs={12}
                                className="text-left"
                              >
                                Notes:
                              </Grid>
                              <Grid item lg={10} xs={12} className="text-left">
                                <b>{eventObj.event?.notes}</b>
                              </Grid>
                              {eventObj.event?.destination !== '' ? (
                                <GeneralButton
                                  className={classes.googleButton}
                                  onClick={() =>
                                    visitingGPS(
                                      eventObj.event?.destination === undefined
                                        ? ''
                                        : eventObj.event?.destination
                                    )
                                  }
                                  content="GO TO THIS"
                                />
                              ) : null}
                             <Grid container spacing={0} >
                              <Grid
                                item
                                lg={2}
                                xs={12}
                                className="text-left"
                              >
                              {eventObj.event?.hotelDestination !== '' ? (
                                <GeneralButton
                                  className={classes.googleButton}
                                  onClick={() =>
                                    visitingGPS(
                                      eventObj.event?.hotelDestination === undefined
                                        ? ''
                                        : eventObj.event?.hotelDestination
                                    )
                                  }
                                  content="GO TO ACCOMODATION"
                                />
                              ) : null}
                                
                              </Grid>
                              </Grid>
                              
                              

                            </Grid>
                          </div>
                        </div>
                      )}
                    />
                  </Grid>
                ))}
            </Grid>
          </div>
        </div>
      ) : null}

      {isLoading ? <CircularProgress className="centered" /> : null}

      {eventObject.state.event.EditVisible ? (
        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={12}>
            <EventEdit
              hotelList={hotelList}
              event={
                eventObject?.state.event.selectedEvent?.event == null
                  ? undefined
                  : eventObject?.state.event.selectedEvent.event
              }
            />
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};

export default EventManagerPresenter;
