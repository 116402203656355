import { ActionMap } from "../general/ActionMap";

import { IMessage } from "../interfaces/IMessage";
import { BasicDataType } from "../Calls/CallReducer";
import { IExpenseCardData } from "../interfaces/IExpense";

export enum Types {
  SET_CURRENT_EXPENSE = 'SET_CURRENT_EXPENSE',
  UPDATE_EXPENSE_FLAG = 'UPDATE_EXPENSE_FLAG',
  SET_EXPENSE_EDIT_VISIBLE = 'SET_EXPENSE_EDIT_VISIBLE',
  SET_EXPENSE_FOUND_VISIBLE = 'SET_EXPENSE_FOUND_VISIBLE',
  SET_SHOW_NEW_BUTTON = 'SET_SHOW_NEW_BUTTON',
  SET_IS_NEW = 'SET_IS_NEW',
  SET_OPEARTION_DISABLED = 'SET_OPEARTION_DISABLED',
  SET_OUTCOME = 'SET_OUTCOME',
  SET_IS_DIRTY = 'SET_IS_DIRTY',
  SET_START_TIME = 'SET_START_TIME',
  SET_END_TIME = 'SET_END_TIME',
  SET_HOTEL_VISIBLE = 'SET_HOTEL_VISIBLE',
 
}

export interface ExpenseDataType extends BasicDataType   {
  selectedExpense: IExpenseCardData | undefined;
  hotelDropdownVisible: boolean;
  startTime: string;
  endTime: string;
};

type ExpensePayload = {
  [Types.SET_CURRENT_EXPENSE]: {
    selectedExpense: IExpenseCardData;
    
  };
  [Types.UPDATE_EXPENSE_FLAG]: {
    needToUpdate: boolean;
  };
    [Types.SET_EXPENSE_EDIT_VISIBLE]: {
    EditVisible: boolean;
  }; 
   [Types.SET_EXPENSE_FOUND_VISIBLE]: {
    FoundVisible: boolean;
  };  

  [Types.SET_SHOW_NEW_BUTTON]: {
    setShowNewButton: boolean;
  };  
 
  [Types.SET_IS_NEW]: {
    isNew: boolean;
  };  
  [Types.SET_OPEARTION_DISABLED]: {
    operationDisabled: boolean;
  };  
  [Types.SET_OUTCOME]: 
  {
    operationOutcome: IMessage;
  };
  [Types.SET_IS_DIRTY]: {
    isDirty: boolean;
  }; 
  [Types.SET_START_TIME]: {
    startTime: string;
  }; 
  [Types.SET_END_TIME]: {
    endTime: string;
  }; 
  [Types.SET_HOTEL_VISIBLE]: {
    hotelDropdownVisible: boolean;
  }; 

};

export type ExpenseActions = ActionMap<ExpensePayload>[keyof ActionMap<
  ExpensePayload
>];

export const expenseReducer = (state: ExpenseDataType, action: ExpenseActions) => {
  switch (action.type) {
    case Types.SET_CURRENT_EXPENSE:
      return {
        ...state,
        selectedExpense: action.payload.selectedExpense,
      };
      case Types.UPDATE_EXPENSE_FLAG:
        return {
          ...state,
          needToUpdate: action.payload.needToUpdate,
        };
       case Types.SET_EXPENSE_EDIT_VISIBLE:
          return {
            ...state,
            EditVisible: action.payload.EditVisible,
          };        
           case Types.SET_EXPENSE_FOUND_VISIBLE:
            return {
              ...state,
              FoundVisible: action.payload.FoundVisible,
            };     

            case Types.SET_SHOW_NEW_BUTTON:
              return {
                ...state,
                setShowNewButton: action.payload.setShowNewButton,
              };                 
              case Types.SET_IS_NEW:
                return {
                  ...state,
                  isNew: action.payload.isNew,
                };     
                case Types.SET_OPEARTION_DISABLED:
                  return {
                    ...state,
                    operationDisabled: action.payload.operationDisabled,
                  };     

                  case Types.SET_OUTCOME:
                    return {
                      ...state,
                      operationOutcome: action.payload.operationOutcome,
                    };                       
                    case Types.SET_IS_DIRTY:
                      return {
                        ...state,
                        isDirty: action.payload.isDirty,
                      }; 
                      case Types.SET_START_TIME:
                        return {
                          ...state,
                          startTime: action.payload.startTime,
                        }; 

                        case Types.SET_END_TIME:
                          return {
                            ...state,
                            endTime: action.payload.endTime,
                          }; 
                          case Types.SET_HOTEL_VISIBLE:
                            return {
                              ...state,
                              hotelDropdownVisible: action.payload.hotelDropdownVisible,
                            };                      
    
    
  
    default:
      return state;
  }
};


