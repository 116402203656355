import React, { useContext } from 'react';
import { CallContextUseReducer } from './CallContext';
import { Types } from './CallReducer';
import { BackToEntityButton } from '../general/GeneralButton';


const CallCommander = () => {
  const callObject = useContext(CallContextUseReducer);


  const handleFoundCalls = () => {
    callObject.dispatch({ type: Types.SET_CALL_EDIT_VISIBLE, payload: { EditVisible: false }, });
    callObject.dispatch({ type: Types.SET_CALL_FOUND_VISIBLE, payload: { FoundVisible: true }, });
    callObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true }, });
  };
 
  return (
    <div>
      {callObject.state.call.EditVisible ? (
        <div>
        <BackToEntityButton 
             content='Call list'
             onClick={handleFoundCalls}
        />
          
        </div>
      ) : null}
      
    </div>
  );
};

export default CallCommander;
