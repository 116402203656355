import React, { useState } from 'react';
import { Card, CardContent, Typography, makeStyles } from '@material-ui/core';
import { LightenDarkenColor } from '../Utils';

const useStyles = makeStyles({
  root: {
    //backgroundColor: 'lightgray',
  },
  wrapper: {
    //width: '95%',
    //margin: '0 auto',
    //backgroundColor: 'red',
    marginTop: '12px',
  },
  gray: {
    backgroundColor: 'lightgray',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  pos: {
    marginBottom: 1,
  },
});

// holds the combination color and id for colouring card depending on id
interface IdToColor {
  color: string;
  id: number | boolean;
}


interface GeneralCardProps {
  entityId: number;
  typeId?: number | boolean;
  colorArray?: IdToColor[];
  defaultBackgroundColor: string;
  entity: any;
  onSelect?(passedObject: any): void;
  children(): JSX.Element;
}

export const GeneralCard = (props: GeneralCardProps) => {
  const classes = useStyles();
  
 

  const GetColor = (id: number | undefined | boolean) => {
    let itemFound = props.colorArray?.find(item => item.id === id);
    return itemFound === undefined ? props.defaultBackgroundColor : itemFound.color;
  };

  
  const [color] = useState(
    GetColor(props.typeId)
  );

  const OnClickEventHandler = (passedObject: any) => {
    if (props.onSelect !== undefined)
    props.onSelect(passedObject);
  };

  const onMouseOverEvent = (id: string | undefined) => {
    let myColor = LightenDarkenColor(color, 60);
    document.getElementById(id === undefined ? '' : id)!.style.backgroundColor =  myColor;
    document.getElementById(id === undefined ? '' : id)!.style.cursor = 'hand';
  };
  const onMouseLeaveEvent = (id: string | undefined) => {
    document.getElementById(id === undefined ? '' : id)!.style.backgroundColor = color === undefined ? props.defaultBackgroundColor : color;
    document.getElementById(id === undefined ? '' : id)!.style.cursor =
      'pointer';
  };

  return (
    <Card
      style={{ backgroundColor: GetColor(props.typeId) }}
      id={props.entityId?.toString()}
      onMouseOver={() => onMouseOverEvent(props.entityId?.toString())}
      onMouseLeave={() => onMouseLeaveEvent(props.entityId?.toString())}
      onClick={() => OnClickEventHandler(props.entity)}
    >
      <CardContent>{props.children()}</CardContent>
    </Card>
  );
};
