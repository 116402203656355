import React, { useContext, useState } from 'react';
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import { Guid } from 'guid-typescript';

import AddIcon from '@material-ui/icons/Add';
import {
  messages,
  Colors,
  callResultsList,
  inBoundCallNoteColor,
  outBoundCallNoteColor,
  generalControlColor,
  replaceCrChars,
  CreateStringDate,
  LightenDarkenColor,
  isBlank,
  CreateTodayDate4Control,
  copyToClipBoard,
  CreateDate4ControlwithValue,
} from '../Utils';
import {
  Theme,
  makeStyles,
  createStyles,
  Fab,
  Switch,
  FormControlLabel,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import 'react-toastify/dist/ReactToastify.css';

import {
  Grid,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from '@material-ui/core';

import { useForm, Controller } from 'react-hook-form';

import { handleResponse } from '../autorization/handleResponse';


import Moment from 'react-moment';

import GeneralButton, {
  GeneralAddButton,
  GeneralDeleteButton,
} from '../general/GeneralButton';
import { isNull } from 'util';
import ConfirmationDialog from '../general/ConfirmationDialog';
import { IQuote, IQuoteCardData } from '../interfaces/IQuote';
import { QuoteContextUseReducer } from './QuoteContext';
import { sendQuoteAPICall, createQuoteAPICall, deleteQuoteAPICall, updateQuoteAPICall } from '../services/DataServices';
import { Types } from './QuoteReducer';



const TealSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: generalControlColor,
    },
    '&$checked + $track': {
      backgroundColor: generalControlColor,
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      margin: theme.spacing(1),
    },
    generalButton: {
      background: Colors.mainBackgroundColor,
      '&:hover': {
        color: 'black',
        background: LightenDarkenColor(Colors.mainBackgroundColor, 40),
      },
    },

    addNoteButton: {
      width: '100%',
      background: Colors.privateCustomerCustomerColor,
      color: 'white',
      '&:hover': {
        color: 'white',
        background: LightenDarkenColor(Colors.privateCustomerCustomerColor, 40),
      },
    },
    clearNoteButton: {
      width: '100%',
      background: Colors.deputyPrincipalColor,
      color: 'white',
      '&:hover': {
        color: 'white',
        background: LightenDarkenColor(Colors.deputyPrincipalColor, 40),
      },
    },
    buttonRemove: {
      background: Colors.dangerBackgroundColor,
    },
    alignedRight: {
      float: 'right',
    },
    outbound_call_note: {
      float: 'right',
      backgroundColor: outBoundCallNoteColor,
      width: '70%',
      textAlign: 'left',
      padding: '10px',
      borderRadius: '10px',
    },
    inbound_call_note: {
      width: '70%',
      float: 'left',
      backgroundColor: inBoundCallNoteColor,
      textAlign: 'left',
      padding: '10px',
      borderRadius: '10px',
    },
    bold: {
      fontWeight: 'bold',
    },

    admin_emails: {
      backgroundColor: Colors.secondBackgroundColor,
      padding: '10px',
      borderRadius: '10px',
      marginTop: '5px',
      marginBottom: '5px',
    },
    admin_emails_empty: {
      display: 'none',
    },

    innerForm: {
      padding: '10px',
    },
  })
);

const QuoteEdit = (props: IQuoteCardData) => {
  const quoteObject = useContext(QuoteContextUseReducer);


  const emptyQuote: IQuote = {
    quoteID: undefined,
    customerName: '',
    issueDate: new Date(),
    expiryDate: new Date(),
    programID: 1,
    customerAddressLine1: '',
    customerAddressLine2: '',
    customerAddressLine3: '',
    customerPostCode: '',
    specialNote: '',
    subTotal: 230,
    customerPhoneNumber: '',
    attentionPersonName: '',
    customerEmail: '',
  };

  const deleteQuoteHandler = () => {
    SetOpenConfirmationDialog(true);
  };

  const [openConfirmationDialog, SetOpenConfirmationDialog] = useState<boolean>(
    false
  );

  const [isLoading, SetIsLoading] = useState<boolean>(false);

  const [initialValues] = useState<IQuote | {}>(
    props.quote == null ? {} : props.quote
  );

  const {
    register,
    control,
    handleSubmit,
    reset,
    errors,
    getValues,
    setValue,
  } = useForm<IQuote>({
    defaultValues: initialValues,
  });

  const handleNewQuoteEvent = () => {
    
    reset(emptyQuote);
    quoteObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: true }, });
    quoteObject.dispatch({ type: Types.SET_OPEARTION_DISABLED, payload: { operationDisabled: false }, });
  };

  const onSubmit = async (data: IQuote) => {
    data.quoteID = quoteObject.state.quote.selectedQuote?.quote?.quoteID;
    quoteObject.dispatch({ type: Types.SET_OPEARTION_DISABLED, payload: { operationDisabled: true }, });

    //data.callBackDateStr = CreateStringDate(data.callBackDate);
    SetIsLoading(true);
    if (quoteObject.state.quote.isNew) CreateQuote(data);
    else UpdateQuote(data);
  };

  const sendQuoteHandler = async () => {
    await sendQuoteAPICall(quoteObject.state.quote.selectedQuote?.quote as IQuote)
      .then(handleResponse)
      .then(
        (quoteID) => {
          quoteObject.dispatch({ type: Types.SET_CURRENT_QUOTE, payload: { selectedQuote: {} }, });
          quoteObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: true, message: messages.SuccessSentQuote } }, });
          quoteObject.dispatch({ type: Types.SET_QUOTE_EDIT_VISIBLE, payload: { EditVisible: false }, });
          quoteObject.dispatch({ type: Types.SET_QUOTE_FOUND_VISIBLE, payload: { FoundVisible: true }, });
          quoteObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true }, });
          let flagToChange = quoteObject.state.quote.needToUpdate;
          quoteObject.dispatch({ type: Types.UPDATE_QUOTE_FLAG, payload: { needToUpdate: !flagToChange }, });
          SetIsLoading(false);
        },
        (error) => {
          SetIsLoading(false);
          quoteObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: false, message: error } }, });
        }
      );


  }

  const CreateQuote = async (quote: IQuote) => {
    await createQuoteAPICall(quote)
      .then(handleResponse)
      .then(
        (quoteID) => {
          quoteObject.dispatch({ type: Types.SET_CURRENT_QUOTE, payload: { selectedQuote: {} }, });
          quoteObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: true, message: messages.SuccessCreateQuote } }, });
          quoteObject.dispatch({ type: Types.SET_QUOTE_EDIT_VISIBLE, payload: { EditVisible: false }, });
          quoteObject.dispatch({ type: Types.SET_QUOTE_FOUND_VISIBLE, payload: { FoundVisible: true }, });
          quoteObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true }, });
          let flagToChange = quoteObject.state.quote.needToUpdate;
          quoteObject.dispatch({ type: Types.UPDATE_QUOTE_FLAG, payload: { needToUpdate: !flagToChange }, });
          SetIsLoading(false);
        },
        (error) => {
          SetIsLoading(false);
          quoteObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: false, message: error } }, });
        }
      );
  };

const UpdateQuote = async (quote: IQuote) => {
  await updateQuoteAPICall(quote)
    .then(handleResponse)
    .then(
      (result) => {
        quoteObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: true, message: messages.SuccessUpdatedQuote, }, }, });
        quoteObject.dispatch({ type: Types.SET_QUOTE_EDIT_VISIBLE, payload: { EditVisible: false }, });
        quoteObject.dispatch({ type: Types.SET_QUOTE_FOUND_VISIBLE, payload: { FoundVisible: true }, });
        quoteObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true }, });
        let flagToChange = quoteObject.state.quote.needToUpdate;
        quoteObject.dispatch({ type: Types.UPDATE_QUOTE_FLAG, payload: { needToUpdate: !flagToChange }, });

        SetIsLoading(false);
      },
      (error) => {
        SetIsLoading(false);
        quoteObject.dispatch({
          type: Types.SET_OUTCOME,
          payload: { operationOutcome: { success: false, message: error } },
        });
      }
    );
};


const DeleteQuote = async (quoteToDelete: IQuote) => {
  await deleteQuoteAPICall(quoteToDelete)
    .then(handleResponse)
    .then(
      (result) => {
        quoteObject.dispatch({ type: Types.UPDATE_QUOTE_FLAG, payload: { needToUpdate: true }, });
        quoteObject.dispatch({ type: Types.SET_QUOTE_FOUND_VISIBLE, payload: { FoundVisible: true }, });
        quoteObject.dispatch({ type: Types.SET_QUOTE_EDIT_VISIBLE, payload: { EditVisible: false }, });
        quoteObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true }, });
        quoteObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: true, message: messages.SuccessDeleteQuote, }, }, });
      },
      (error) => {
        quoteObject.dispatch({
          type: Types.SET_OUTCOME,
          payload: { operationOutcome: { success: false, message: error } },
        });
      }
    );
};

const handleConfirmationDeleteDialog = (event: boolean) => {
  SetOpenConfirmationDialog(false);
  if (event === true) {
    DeleteQuote(quoteObject.state.quote.selectedQuote?.quote as IQuote);
  }
};

const classes = useStyles();

return (
  <div>
    {openConfirmationDialog ? (
      <ConfirmationDialog
        open={true}
        content="Are you sure you like to delete this call ?"
        onResult={(event) => handleConfirmationDeleteDialog(event)}
      />
    ) : null}

    {isLoading ? <CircularProgress className="centered" /> : null}
    <form onSubmit={handleSubmit(onSubmit)}>
      
      <Grid container spacing={1}>

        <Grid item sm={6} xs={12}>
        <FormControl className="full-width">
              <InputLabel>Show</InputLabel>

              <Controller
                as={
                  <Select>
                    {!props.programList ||
                      props.programList.map((prg) => (
                        <MenuItem key={prg.programID} value={prg.programID}>
                          {prg.description}
                        </MenuItem>
                      ))}
                  </Select>
                }
                name="programID"
                rules={{ required: true }}
                control={control}
              />
            </FormControl>
            <small className="red">{errors.programID && 'Program is required'} </small>
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            inputRef={register({ required: true })}
            className="full-width"
            name="issueDate"
            id="datetime-local"
            label="Issue Date"
            type="datetime-local"
            defaultValue={CreateTodayDate4Control()}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
        <TextField
            inputRef={register({ required: true })}
            className="full-width"
            name="expiryDate"
            id="datetime-local"
            label="Expiry Date"
            type="datetime-local"
            defaultValue={CreateTodayDate4Control()}
            InputLabelProps={{
              shrink: true,
            }}
          />
         
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            className="full-width"
            name="customerName"
            label="Customer company Name"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={register({ required: true })}
          />
        <small className="red">{errors.customerName && 'Customer name is required'} </small>
        </Grid>
        

        <Grid item sm={6} xs={12}>
          <TextField
            className="full-width"
            name="customerAddressLine1"
            label="Address Line 1"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={register({ required: true })}
          />
          <small className="red">{errors.customerAddressLine1 && 'Required field'} </small>
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            name="customerAddressLine2"
            className="full-width"
            label="Address Line 2"
            inputRef={register({ required: true })}
          />
          <small className="red">{errors.customerAddressLine2 && 'Required field'} </small>
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            name="customerAddressLine3"
            className="full-width"
            label="Address Line 3"
            inputRef={register({ required: true })}
          />
          <small className="red">{errors.customerAddressLine3 && 'Required field'} </small>
        </Grid>
        <Grid item sm={6} xs={12}>
        <TextField
            name="customerPostCode"
            className="full-width"
            label="Post Code"
            inputRef={register({ required: true })}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
        <TextField
            name="attentionPersonName"
            className="full-width"
            label="Person responsible"
            inputRef={register({ required: true })}
          />
          <small className="red">{errors.attentionPersonName && 'Required field'} </small>
        </Grid>

        <Grid item sm={6} xs={12}>
        <TextField
            name="customerPhoneNumber"
            className="full-width"
            label="Customer Phone number"
            inputRef={register({ required: false })}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
        <TextField
            name="subTotal"
            className="full-width"
            type="number"
            label="Quotable amount (NZD)"
            inputRef={register({ required: true, maxLength: 5 })}
          />
          <small className="red">{errors.subTotal && 'Amount is required'} </small>
        </Grid>
        <Grid item sm={6} xs={12}>
        <TextField
            name="customerEmail"
            className="full-width"
            label="Customer Email"
            inputRef={register({ required: false })}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
        <TextField
            name="specialNote"
            className="full-width"
            label="Notes"
            multiline
            inputRef={register({ required: false })}
          />
        </Grid>
      </Grid>

      <div className="mt-1">
        <Grid container spacing={2}>
          <Grid item sm={3} md={3} xs={12}>
            <GeneralButton
              className="full-width"
              type="submit"
              content="update"
            />
          </Grid>

          <Grid item sm={3} md={3} xs={12}>
            <GeneralButton
              className="full-width"
              type="button"
              content="send"
              onClick={sendQuoteHandler}
            />
          </Grid>

          <Grid item sm={1} md={1} xs={12}>
            <GeneralAddButton
              onClick={handleNewQuoteEvent}
              type="reset"
              size="small" />
          </Grid>
          {quoteObject.state.quote.isNew ? null : (<Grid item sm={2} md={1} lg={1} xs={12}>
            <GeneralDeleteButton
              className="full-width"
              content="delete"
              onClick={deleteQuoteHandler}
            />
          </Grid>
          )}
        </Grid>
      </div>

     
    </form>
   
  </div>
);
};
export default QuoteEdit;
