import React, { createContext, useReducer, Dispatch } from "react";
import {
  productReducer,
  CustomerDataType,
  CustomerActions,

} from "./CustomerReducer";
import { IChildrenProp } from "../interfaces/IChildren";

type InitialStateType = {
  customer: CustomerDataType;

};

const initialState = {
    customer: { 
         whatToSearch: 'belfast', 
         customerEditVisible: true, 
         customerFoundVisible: false,
         selectedCustomer: undefined,
         exactSearch: false,
         isDirty: false,
         needToFetch: false,
         isNew: false,
         editDisable: true,
         daysAgo: 12,
         country:'New Zealand',
        },
};

const CustomerContextUseReducer = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<CustomerActions>;
}>({
  state: initialState,
  dispatch: () => null
});

const mainReducer = (
  { customer  }: InitialStateType,
  action: CustomerActions 
) => ({
    customer: productReducer(customer, action),
});

const CustomerProviderUseReducer: React.FC<IChildrenProp> = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <CustomerContextUseReducer.Provider value={{ state, dispatch }}>
      {children}
    </CustomerContextUseReducer.Provider>
  );
};

export { CustomerProviderUseReducer, CustomerContextUseReducer };