import React, { useContext, useState, useEffect } from 'react';
import { convertGPSCoordinates, messages } from '../Utils';

import {
  Paper,
  Grid,
  TextField,

  FormControl,
  Select,
  MenuItem,

  InputLabel,
  CircularProgress,
} from '@material-ui/core';
import { Theme, makeStyles, createStyles } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { ICustomer, ICustomerCardData } from '../interfaces/ICustomer';
import { CustomerContextUseReducer } from './CustomerContext';

import {
  getDistrictAPICall,
  createCustomerAPICall,
  deleteCustomerAPI,
  updateCustomerAPICall,
} from '../services/DataServices';
import { handleResponse } from '../autorization/handleResponse';
import { IDistrict } from '../interfaces/IDistrict';
import { ToastContainer, toast } from 'react-toastify';
import GoogleMap, { ICoordinates } from '../general/GoogleMap';
import { Types } from './CustomerReducer';
import GeneralButton, { GeneralAddButton, GeneralDeleteButton } from '../general/GeneralButton';
import urls from "../Utils";
import ConfirmationDialog from '../general/ConfirmationDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },

    buttons_area:
    {
      textAlign: 'left',
      paddingTop: '10px',
    },
    dirty_state:
    {
      borderColor: 'red',
      borderStyle: 'solid',
      borderWidth: '2px',
      padding: '5px',
    },
    new_state:
    {
      borderColor: 'green',
      borderStyle: 'solid',
      borderWidth: '2px',
      padding: '5px',
    },
    clean_state:
    {
      borderColor: 'white',
      borderStyle: 'solid',
      borderWidth: '2px',
    },


    wrapper: {
      width: '100%',
      margin: '0 auto',
      marginTop: '10px',
    },
    innerForm: {
      padding: '10px',
    },
  })
);

const CustomerEdit = (props: ICustomerCardData) => {
  const customerObject = useContext(CustomerContextUseReducer);
  const classes = useStyles();
  const emptyCustomer: ICustomer = {
    name: '',
    eMail: '',
    webSite: '',
    address: '',
    gps: '',
    customerID: 0,
    districtID: 4,
    numberPeople: 0,
    typeCustomerID: 2,
    decile: 0,
    notes: '',
  };

  const [initialValues] = useState<ICustomer | {}>(
    props.customer == null ? {} : props.customer
  );

  const [openConfirmationDialog, SetOpenConfirmationDialog] = useState<boolean>(false);


  const [coordinates, SetCoordinates] = useState<ICoordinates>({
    lat: -36.800247,
    lng: 174.71808,
  });

  const [districtList, SetDistrictList] = useState<IDistrict[]>([]);
  const [isLoading, SetIsLoading] = useState(false);


  const { register, handleSubmit, errors, control, reset } = useForm<ICustomer>(
    {
      defaultValues: initialValues,
    }
  );

  const onModelChange = () => {
    customerObject.dispatch({ type: Types.SET_IS_DIRTY, payload: { isDirty: true } });

  };

  const handleNewCustomerEvent = () => {
    reset(emptyCustomer);
    customerObject.dispatch({ type: Types.SET_IS_DIRTY, payload: { isDirty: false } });
    customerObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: true } });
    customerObject.dispatch({ type: Types.SET_CURRENT_CUSTOMER, payload: { selectedCustomer: undefined } });
  };

  const DeleteCustomer = async (customerToDelete: ICustomer) => {
    await deleteCustomerAPI(customerToDelete)
      .then(handleResponse)
      .then(
        (result) => {
          reset(emptyCustomer);
          showSuccessMessage(messages.SuccessDeletedCustomer, true);
          customerObject.dispatch({ type: Types.SET_IS_DIRTY, payload: { isDirty: false } });
          customerObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: true } });
          customerObject.dispatch({ type: Types.SET_CURRENT_CUSTOMER, payload: { selectedCustomer: undefined } });
              },
        (error) => {
          showSuccessMessage(error, false);
          }
      );
  };


  const showSuccessMessage = (value: string, success: boolean) => {
    if (success)
      toast.success(value, { autoClose: 6000 });
    else
      toast.error(value, { autoClose: 6000 });

    SetIsLoading(false);
  }

  const handleConfirmationDeleteDialog = (event: boolean) => {
    SetOpenConfirmationDialog(false);
    if (event === true) {
      DeleteCustomer(
        customerObject.state.customer.selectedCustomer?.customer == undefined
          ? {}
          : customerObject.state.customer.selectedCustomer?.customer
      );
    }
  };


  const fetchDistricts = async () => {
    try {
      await getDistrictAPICall(customerObject.state.customer.country)
        .then(handleResponse)
        .then((districtList) => SetDistrictList(districtList));
    } catch (e) {
      console.log(e);
    }
  };

  const splitGPSCoordinates = (value: string) => {
    let gpsData = convertGPSCoordinates(value);
    SetCoordinates({ lat: gpsData.lat, lng: gpsData.lng });
  };

  useEffect(() => {
    fetchDistricts();
    splitGPSCoordinates(
      props.customer?.gps === undefined ? '' : props.customer?.gps
    );
  }, []);

  const onSubmit = async (data: ICustomer) => {
    SetIsLoading(true);
    if (customerObject.state.customer.isNew)
      CreateCustomer(data);
    else
      UpdateCustomer(data);

    reset(emptyCustomer);
  };

  const CreateCustomer = async (values: ICustomer) => {
    console.log(values);
    await createCustomerAPICall(values)
      .then(handleResponse)
      .then(
        (result) => {
          showSuccessMessage(messages.SuccessUpdatedCustomer, true);
          let currentValue = customerObject?.state.customer.needToFetch;
          customerObject.dispatch({ type: Types.SET_NEED_TO_FETCH, payload: { needToFetch: !currentValue } });
          customerObject.dispatch({ type: Types.SET_IS_DIRTY, payload: { isDirty: false } });
        },
        (error) => {
          showSuccessMessage(error, false);

        }
      );
  };

  const UpdateCustomer = async (values: ICustomer) => {
    await updateCustomerAPICall(values)
      .then(handleResponse)
      .then(
        (result) => {
          showSuccessMessage(messages.SuccessCreateCustomer, true);
          let currentValue = customerObject?.state.customer.needToFetch;
          customerObject.dispatch({ type: Types.SET_NEED_TO_FETCH, payload: { needToFetch: !currentValue } });
          customerObject.dispatch({ type: Types.SET_IS_DIRTY, payload: { isDirty: false } });
        },
        (error) => {
          showSuccessMessage(error, false);
        }
      );
  };

  const getSTyle = () => {
    if (customerObject.state.customer.isDirty) return classes.dirty_state;
    else if (customerObject.state.customer.isNew) return classes.new_state;
    else return classes.clean_state;

  }

  const deleteCustomerHandler = () => {
    SetOpenConfirmationDialog(true);
  };

  return (
    <div className={getSTyle()}>
      <ToastContainer autoClose={6000} />
      {isLoading ? <CircularProgress className="centered" /> : null}

      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={true}
          content="Are you sure you like to delete this customer ?"
          onResult={(event) => handleConfirmationDeleteDialog(event)}
        />
      ) : null}


      {customerObject?.state.customer.customerEditVisible ? (
        <div className={classes.wrapper}>
          <Grid container className={classes.root}>
            <Grid item sm={6} xs={12}>
              <GoogleMap coordinates={coordinates} />
            </Grid>
            <Grid item sm={6} xs={12}>
              <div className={classes.root}>
                <Paper elevation={6} className={classes.innerForm}>
                  <form onSubmit={handleSubmit(onSubmit)} >




                    <TextField
                      className="full-width"
                      onChange={(event) => onModelChange()}
                      name="name"
                      label="Name"
                      inputRef={register({ required: true, maxLength: 50 })}
                    />

                    <small className="red">
                      {errors.name && 'Name is required'}{' '}
                    </small>

                    <input
                      type="hidden"
                      name="countryID"

                      ref={register}
                      value={customerObject.state.customer.country === 'New Zealand' ? "1" : "2"}
                    ></input>

                    <input
                      type="hidden"
                      name="customerID"
                      ref={register}
                      value={customerObject.state.customer.selectedCustomer === undefined ? 0 : customerObject.state.customer.selectedCustomer.customer?.customerID}
                    ></input>

                    <input
                      type="hidden"
                      name="printTemplate"
                      ref={register}
                      value=""
                    ></input>

                    <TextField
                      className="full-width"
                      name="eMail"
                      onChange={(event) => onModelChange()}
                      label="Primary Email"
                      inputRef={register({ required: false })}
                    />

                    <TextField
                      className="full-width"
                      name="address"
                      onChange={(event) => onModelChange()}
                      label="Address"
                      inputRef={register({ required: false })}
                    />

                    <TextField
                      className="full-width"
                      name="gps"
                      onChange={(event) => onModelChange()}
                      label="GPS"
                      inputRef={register({ pattern: /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/i })}
                    />

                    <small className="red">
                      {errors.gps && 'Invalid GPS coordinates'}{' '}
                    </small>

                    <TextField
                      className="full-width"
                      name="webSite"
                      onChange={(event) => onModelChange()}
                      label="Web address"
                      inputRef={register({ required: false })}
                    />
                    <TextField
                      className="full-width"
                      name="numberPeople"
                      onChange={(event) => onModelChange()}
                      label="Number people"
                      InputLabelProps={{
                        shrink: true,
                      }}

                      inputRef={register({ required: true })}
                    />
                    <small className="red">
                      {errors.numberPeople && 'Number of people is required'}{' '}
                    </small>

                    <TextField
                      className="full-width"
                      name="decile"
                      onChange={(event) => onModelChange()}
                      label="Decile"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputRef={register({ required: true })}
                    />
                    <small className="red">
                      {errors.numberPeople && 'Decile is required'}{' '}
                    </small>

                    <FormControl className="full-width">
                      <InputLabel>Select district</InputLabel>

                      <Controller
                        as={
                          <Select>
                            {!districtList ||
                              districtList.map((d) => (
                                <MenuItem
                                  key={d.districtID}
                                  value={d.districtID}
                                >
                                  {d.name}
                                </MenuItem>
                              ))}
                          </Select>
                        }
                        name="districtID"
                        defaultValue="1"
                        control={control}
                      />
                    </FormControl>

                    <FormControl className="full-width">
                      <InputLabel>Select type of customer</InputLabel>

                      <Controller
                        as={
                          <Select

                          >
                            {!props.typeCustomers ||
                              props.typeCustomers.map((tc) => (
                                <MenuItem
                                  key={tc.typeCustomerID}
                                  value={tc.typeCustomerID}
                                >
                                  {tc.name}
                                </MenuItem>
                              ))}
                          </Select>
                        }
                        name="typeCustomerID"
                        defaultValue="1"


                        control={control}
                      />
                    </FormControl>

                    <TextField
                      name="notes"
                      className="full-width"
                      label="Note"
                      onChange={(event) => onModelChange()}
                      multiline
                      inputRef={register}
                    />


                    <Grid container className='mt-1'>
                      <Grid item sm={2} md={2} lg={1} xs={12} >
                        <GeneralAddButton
                          size="small"
                          onClick={handleNewCustomerEvent}
                        />
                      </Grid>
                      <Grid item sm={3} md={4} lg={3} xs={12} >
                        <GeneralButton
                          className="full-width"
                          type="submit"
                          content="submit"
                        />

                      </Grid>
                      <Grid>
                      <div className='right ml-1 mb-1'>
                        <GeneralDeleteButton
                          className="full-width"
                          content="delete"
                          onClick={deleteCustomerHandler}
                        />
                      </div>
                      </Grid>
                    </Grid>
                  </form>
                </Paper>
              </div>
            </Grid>
          </Grid>
        </div>
      ) : null}
    </div>
  );
};

export default CustomerEdit;
