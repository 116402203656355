import React, { useContext, useEffect, useState } from 'react';

import { fetchCustomersCall, fetchParticularCustomer, fetchTypeCustomerList } from '../services/DataServices';
import { handleResponse } from '../autorization/handleResponse';
import { makeStyles, Grid, CircularProgress, FormControlLabel } from '@material-ui/core';

import { ICustomerCardData, ICustomer } from '../interfaces/ICustomer';
import CustomerEdit from './CustomerEdit';
import { CustomerContextUseReducer } from './CustomerContext';
import { GeneralCard } from '../general/GeneralCard';
import { Types } from './CustomerReducer';
import { TypeCustomer, Colors, TealSwitch } from '../Utils';
import { ITypeCustomer } from '../interfaces/ITypeCustomer';

const useStyles = makeStyles({
  root: {},
  wrapper: {
    marginTop: '20px',
  },
  gray: {
    backgroundColor: 'lightgray',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 1,
  },
});

const CustomerPresenter = () => {
  const customerObject = useContext(CustomerContextUseReducer);

  const classes = useStyles();
  const [customers, SetCustomers] = useState<ICustomerCardData[]>([]);
  const [isLoading, SetIsLoading] = useState(false);
  const [typeCustomerList, SetTypeCustomerList] = useState<ITypeCustomer[]>([]);

  const getTypeCustomers = async () => {
    try {
      const response = await fetchTypeCustomerList();
      response
        .json()

        .then((data: ITypeCustomer[]) => {
          console.log(data);
          SetTypeCustomerList(data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const SetMyCustomers = (passedCustomers: ICustomer[]) => {
    let cust_1 = passedCustomers.map(v => { return { customer: v }; }) as ICustomerCardData[];
    SetCustomers(cust_1);
    SetIsLoading(false);
  }

  useEffect(() => {
    SetIsLoading(true);
    console.log('customer presenter:' + customerObject?.state.customer.whatToSearch);
    console.log('Exact search:' + customerObject?.state.customer.exactSearch);
    console.log('CustomerID:' + customerObject?.state.customer.selectedCustomer?.customer?.customerID);

    const fetchCustomersWideBroadString = async (value: string) => {
      console.log(`fetchCustomers: ${value}`);
      try {
        await fetchCustomersCall(value, customerObject?.state.customer.country)
          .then(handleResponse)
          .then((rowData) => SetMyCustomers(rowData));
      } catch (e) {
        console.log(e);
      }
    };
    getTypeCustomers();

    const fetchExactCustomer = async (value: string) => {
      console.log(`fetchExactCustomers: ${value}`);
      try {
        await fetchParticularCustomer(value)
          .then(handleResponse)
          .then((rowData) => SetMyCustomers(rowData));
      } catch (e) {
        console.log(e);
      }
    };

    // we call different SEARCH functions depending who is calling: if PROCEED TO THIS CUSTOMER (CustomerCalls) we call EXACT search.
    (customerObject?.state.customer.exactSearch === true) ? 
       fetchExactCustomer(customerObject?.state.customer.selectedCustomer?.customer?.customerID === undefined ? '1' : customerObject?.state.customer.selectedCustomer?.customer?.customerID.toString()) : 
       fetchCustomersWideBroadString(customerObject?.state.customer.whatToSearch === undefined ? 'ALL' : customerObject?.state.customer.whatToSearch); 


  }, [customerObject?.state.customer.whatToSearch, customerObject?.state.customer.needToFetch, ]);

  const GetTypeCustomerName = (typeCustomerId: number | undefined) =>
  {
    let itemFound = typeCustomerList?.find(item => item.typeCustomerID === typeCustomerId);
    return itemFound?.name;
 }


  const handleClick = (passedBackCustomer: ICustomerCardData) => {
    customerObject.dispatch({ type: Types.SET_CURRENT_CUSTOMER, payload: { selectedCustomer: passedBackCustomer }, });
    customerObject.dispatch({ type: Types.SET_CUSTOMER_EDIT_VISIBLE, payload: { customerEditVisible: true }, });
    customerObject.dispatch({ type: Types.SET_CUSTOMER_FOUND_VISIBLE, payload: { customerFoundVisible: false }, });
    customerObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: false }, });
    customerObject.dispatch({ type: Types.SET_EDIT_DISABLE, payload: {editDisable : false }, });

  }

  const changeCountry = (isNewZealand: boolean) => {
    if (isNewZealand)
    customerObject.dispatch({ type: Types.SET_COUNTRY, payload: { country: 'New Zealand' } });
   else 
   customerObject.dispatch({ type: Types.SET_COUNTRY, payload: { country: 'Moldova' } });
  }

  return (
    <div>
      

<FormControlLabel
                    control={
                      <TealSwitch
                        checked={customerObject.state.customer.country === 'New Zealand'}
                        onChange={(event) => changeCountry(event.target.checked)}
                    />
                    }
                      label="New Zealand"
                    />                    

      {customerObject?.state.customer.customerFoundVisible ? (
        <div className={classes.wrapper}>
          <Grid container className={classes.root} spacing={3}>
            {!customers ||
              customers.map((cst) => (
                <Grid key={cst.customer?.customerID} item sm={3} xs={12}>

                  {/*   
                  <CustomerCard
                    customer={cst.customer}
                  /> */}

                  <GeneralCard
                    entity={cst}
                    onSelect={handleClick}
                    typeId={cst.customer?.typeCustomerID}
                    defaultBackgroundColor={'#16a1af'}
                    colorArray={
                      [
                        { id: TypeCustomer.client, color: Colors.clientCustomerColor },
                        { id: TypeCustomer.rejectedSchools, color: Colors.rejectedCustomerColor },
                        { id: TypeCustomer.privateCustomer, color: Colors.privateCustomerCustomerColor },
                        { id: TypeCustomer.school, color: Colors.brownBackgroundColor },

                      ]}

                    entityId={cst.customer?.customerID === undefined ? 0 : cst.customer?.customerID}
                    children={() => (
                      <div>
                        <p><b>{cst.customer?.name}</b></p>
                        <p><b>{GetTypeCustomerName(cst.customer?.typeCustomerID)}</b></p>
                      </div>

                    )}
                  />
                </Grid>
              ))}
          </Grid>
        </div>
      ) : null}

      {isLoading ? (<CircularProgress className='centered' />) : null}


      {customerObject?.state.customer.customerEditVisible ? (
        <div className={classes.wrapper}>
          <Grid container className={classes.root}>
            <Grid item xs={12}>
              <CustomerEdit
                customer={customerObject?.state.customer.selectedCustomer?.customer == null ? undefined : customerObject?.state.customer.selectedCustomer?.customer}
                typeCustomers = {typeCustomerList}
              />
            </Grid>
          </Grid>
        </div>
      ) : null}
    </div>
  );
};

export default CustomerPresenter;
