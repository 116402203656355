import { ActionMap } from "../general/ActionMap";

import { BasicDataType } from "../Calls/CallReducer";
import { IEvent, IEventCardData } from "../interfaces/IEvent";
import { IMessage } from "../interfaces/IMessage";

export enum Types   {
  SET_CURRENT_EVENT = 'SET_CURRENT_EVENT',
  UPDATE_EVENT_FLAG = 'UPDATE_EVENT_FLAG',
  SET_EVENT_EDIT_VISIBLE = 'SET_EVENT_EDIT_VISIBLE',
  SET_EVENT_FOUND_VISIBLE = 'SET_EVENT_FOUND_VISIBLE',
  SET_IS_NEW = 'SET_IS_NEW',
  SET_OPEARTION_DISABLED = 'SET_OPEARTION_DISABLED',
  SET_OUTCOME = 'SET_OUTCOME',
  SET_IS_DIRTY = 'SET_IS_DIRTY',
  SET_HOTEL_VISIBLE = 'SET_HOTEL_VISIBLE',
}

export interface EventDataType  extends BasicDataType {
  hotelDropdownVisible: boolean;
  selectedEvent: IEventCardData | undefined;
};

type EventPayload = {
  [Types.SET_CURRENT_EVENT]: {
    selectedEvent: IEventCardData;
    
  };
  [Types.UPDATE_EVENT_FLAG]: {
    needToUpdate: boolean;
  };
    [Types.SET_EVENT_EDIT_VISIBLE]: {
    EditVisible: boolean;
  }; 
   [Types.SET_EVENT_FOUND_VISIBLE]: {
    FoundVisible: boolean;
  };  

  [Types.SET_IS_NEW]: {
    isNew: boolean;
  };  
  [Types.SET_OPEARTION_DISABLED]: {
    operationDisabled: boolean;
  };  

  [Types.SET_OUTCOME]: 
  {
    operationOutcome: IMessage;
  };
  [Types.SET_IS_DIRTY]: {
    isDirty: boolean;
  }; 
  [Types.SET_HOTEL_VISIBLE]: {
    hotelDropdownVisible: boolean;
  }; 

  
};

export type EventActions = ActionMap<EventPayload>[keyof ActionMap<
  EventPayload
>];

export const eventReducer = (state: EventDataType, action: EventActions) => {
  switch (action.type) {
    case Types.SET_CURRENT_EVENT:
      return {
        ...state,
        selectedEvent: action.payload.selectedEvent,
      };
      case Types.UPDATE_EVENT_FLAG:
        return {
          ...state,
          needToUpdate: action.payload.needToUpdate,
        };
       case Types.SET_EVENT_EDIT_VISIBLE:
          return {
            ...state,
            EditVisible: action.payload.EditVisible,
          };        
           case Types.SET_EVENT_FOUND_VISIBLE:
            return {
              ...state,
              FoundVisible: action.payload.FoundVisible,
            };     

              case Types.SET_IS_NEW:
                return {
                  ...state,
                  isNew: action.payload.isNew,
                };     
                case Types.SET_OPEARTION_DISABLED:
                  return {
                    ...state,
                    operationDisabled: action.payload.operationDisabled,
                  };     
                  case Types.SET_OUTCOME:
                    return {
                      ...state,
                      operationOutcome: action.payload.operationOutcome,
                    };                       
                    case Types.SET_IS_DIRTY:
                      return {
                        ...state,
                        isDirty: action.payload.isDirty,
                      };
                      case Types.SET_HOTEL_VISIBLE:
                        return {
                          ...state,
                          hotelDropdownVisible: action.payload.hotelDropdownVisible,
                        };                      

  
    default:
      return state;
  }
};


