import React, { useContext, useState, useEffect } from 'react';
import { CSVLink, CSVDownload } from "react-csv";
import { handleResponse } from '../autorization/handleResponse';
import {
  makeStyles,
  Grid,
  CircularProgress,
  TextField,
} from '@material-ui/core';


import { GeneralCard } from '../general/GeneralCard';
import { Colors, LightenDarkenColor, isBlank, CreateDate4ControlwithValue, CreateTodayDate4Control, CreateDashStringDate } from '../Utils';
import { GeneralAddButton } from '../general/GeneralButton';
import { ToastContainer, toast } from 'react-toastify';
import { ExpenseContextUseReducer } from './ExpenseContext';
import { IExpenseCardData, IExpense, IAccount, ITypeExpense, IExcelFormatExpense } from '../interfaces/IExpense';
import { Types, expenseReducer } from './ExpenseReducer';
import { fetchExpensesAPIcall, fetchAccountsAPIcall, fetchTypeExpenseAPIcall, fetchHotelsAPICall } from '../services/DataServices';
import Moment from 'react-moment';
import ExpenseEdit from './ExpenseEdit';
import { IHotel } from '../interfaces/IHotel';

const useStyles = makeStyles({
  root: {},
  wrapper: {
    marginTop: '20px',
  },
  gray: {
    backgroundColor: 'lightgray',
  },
  generalButton: {
    background: Colors.mainBackgroundColor,
    '&:hover': {
      color: 'black',
      background: LightenDarkenColor(Colors.mainBackgroundColor, 40),
    },
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 1,
  },
});

const ExpensePresenter = (props: any) => {

  const expenseObject = useContext(ExpenseContextUseReducer);
  const [expenses, SetExpenses] = useState<IExpenseCardData[]>([]);

  const SetStartDate = (value: string) => {
    expenseObject.dispatch({ type: Types.SET_START_TIME, payload: { startTime: value }, });
    let update = !expenseObject.state.expense.needToUpdate;
    expenseObject.dispatch({ type: Types.UPDATE_EXPENSE_FLAG, payload: { needToUpdate: update }, });
  }
  const SetEndDate = (value: string) => {
    expenseObject.dispatch({ type: Types.SET_END_TIME, payload: { endTime: value }, });
    let update = !expenseObject.state.expense.needToUpdate;
    expenseObject.dispatch({ type: Types.UPDATE_EXPENSE_FLAG, payload: { needToUpdate: update }, });
  }

  const [isLoading, SetIsLoading] = useState(false);
  const [accountList, SetAccountList] = useState<IAccount[]>([]);
  const [typeExpense, SetTypeExpense] = useState<ITypeExpense[]>([]);
  const [hotelList, SetHotelList] = useState<IHotel[]>([]);
  const [exportData, SetExportData] = useState<IExcelFormatExpense[]>([]);

  const classes = useStyles();
  const SetMyExpenses = (passedExpenses: IExpense[]) => {

    let expense_1 = passedExpenses.map((v) => {
      return { expense: v };
    }) as IExpenseCardData[];

    SetExpenses(expense_1.sort((a, b) => ((a.expense?.dateExpense as Date) < (b.expense?.dateExpense as Date) ? 1 : -1)));

    SetIsLoading(false);
  };

  const SetExcelData = (passedExpenses: IExpenseCardData[]) => {
    let ttt = passedExpenses.sort((a, b) => ((a.expense?.dateExpense as Date) > (b.expense?.dateExpense as Date) ? 1 : -1));
    var result = ttt.map(data => ({
      date: CreateDashStringDate(data.expense?.dateExpense === undefined ? new Date() : data.expense?.dateExpense),
      description: data.expense?.description,
      account: accountList.find(item => item.accountID === data.expense?.accountID)?.name,
      gstExcludedSum: data.expense?.gst ? data.expense?.amount === undefined ? 0 : data.expense?.amount / 1.15 : data.expense?.amount,
      gstApplicable: data.expense?.gst ? 'YES' : 'NO',
      gstIncludedSum: data.expense?.amount,

    }) as IExcelFormatExpense);
    SetExportData(result);

  };


  const addNewExpense = () => {
    expenseObject.dispatch({ type: Types.SET_EXPENSE_EDIT_VISIBLE, payload: { EditVisible: true }, });
    expenseObject.dispatch({ type: Types.SET_EXPENSE_FOUND_VISIBLE, payload: { FoundVisible: false }, });
    expenseObject.dispatch({ type: Types.SET_CURRENT_EXPENSE, payload: { selectedExpense: {} }, });
    expenseObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: false }, });
    expenseObject.dispatch({ type: Types.SET_OPEARTION_DISABLED, payload: { operationDisabled: true }, });
    expenseObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: true }, });
    expenseObject.dispatch({ type: Types.SET_IS_DIRTY, payload: { isDirty: false }, });
  };


  const showSuccessMessage = (value: string, success: boolean) => {
    if (success)
      toast.success(value, { autoClose: 6000 });
    else
      toast.error(value, { autoClose: 6000 });
  }

  useEffect(() => {
    SetIsLoading(true);

    const fetchExpenses = async (startTime: string, endTime: string) => {
      try {
        console.log('fetch expenses');
        await fetchExpensesAPIcall(startTime, endTime)
          .then(handleResponse)
          .then((rowData) => SetMyExpenses(rowData));
      } catch (e) {
        console.log(e);
      }
    };

    fetchExpenses(expenseObject.state.expense.startTime, expenseObject.state.expense.endTime);

  }, [
    expenseObject?.state.expense.needToUpdate,
  ]);

  useEffect(() => {

    const fetchAccounts = async () => {
      try {
        console.log('fetch accounts');
        await fetchAccountsAPIcall()
          .then(handleResponse)
          .then((rowData) => {
            SetExcelData(expenses);
            SetAccountList(rowData)
          }

          );
      } catch (e) {
        console.log(e);
      }
    };

    const fetchTypeExpense = async () => {
      try {
        console.log('fetch type expense');
        await fetchTypeExpenseAPIcall()
          .then(handleResponse)
          .then((rowData) => SetTypeExpense(rowData));
      } catch (e) {
        console.log(e);
      }
    };

    const fetchHotels = async () => {
      try {
        await fetchHotelsAPICall()
          .then(handleResponse)
          .then((data) => SetHotelList(data));
      } catch (e) {
        console.log(e);
      }
    };


    fetchAccounts();
    fetchHotels();
    fetchTypeExpense();

  }, []);


  useEffect(() => {
    if (!isBlank(expenseObject.state.expense.operationOutcome.message))
      showSuccessMessage(expenseObject.state.expense.operationOutcome.message, true);

  }, [expenseObject.state.expense.operationOutcome]);


  const handleClick = (passedBackExpense: IExpenseCardData) => {
    expenseObject.dispatch({ type: Types.SET_CURRENT_EXPENSE, payload: { selectedExpense: passedBackExpense }, });
    expenseObject.dispatch({ type: Types.SET_EXPENSE_EDIT_VISIBLE, payload: { EditVisible: true }, });
    expenseObject.dispatch({ type: Types.SET_EXPENSE_FOUND_VISIBLE, payload: { FoundVisible: false }, });
    expenseObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: false } });
    expenseObject.dispatch({ type: Types.SET_IS_DIRTY, payload: { isDirty: false } });
    expenseObject.dispatch({ type: Types.UPDATE_EXPENSE_FLAG, payload: { needToUpdate: false }, });
    expenseObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: false }, });
    expenseObject.dispatch({ type: Types.SET_OPEARTION_DISABLED, payload: { operationDisabled: false }, });

  };

  return (
    <div>
      <ToastContainer autoClose={6000} />

      <div className="mt-1 mb-1">
        {expenseObject.state.expense.setShowNewButton ? (
          <div>
            <GeneralAddButton
              onClick={addNewExpense}
            />
          </div>
        ) : null}
      </div>

      {expenseObject.state.expense.FoundVisible ? (<CSVLink className='left mt-1 mb-1'
        data={exportData}
        onClick={() => {
          SetExcelData(expenses);
        }}
      >
        Download CSV file
      </CSVLink>
      ) : null}


      {expenseObject.state.expense.FoundVisible ? (
        <div>
          <div className='mb-1'>
          <Grid container spacing={1} >
            <Grid item sm={4}>
              <TextField
                className="full-width"
                label="Start Time"
                onChange={(event) => SetStartDate(event?.target.value)}
                type="datetime-local"
                defaultValue={expenseObject.state.expense.startTime}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                className="full-width"
                label="End Time"
                onChange={(event) => SetEndDate(event?.target.value)}
                type="datetime-local"
                defaultValue={CreateTodayDate4Control()}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

          </Grid>
          </div>

          <Grid container className={classes.root} spacing={1}>


            {!expenses ||
              expenses.map((expns) => (
                <Grid key={expns.expense?.expenseID} item sm={4} xs={12}>                {/*   <AdministratorCard admin={adm.admin} /> */}

                  <GeneralCard
                    entity={expns}
                    onSelect={handleClick}

                    defaultBackgroundColor={'#66a1af'}

                    entityId={
                      expns.expense?.expenseID === undefined ? 0 : expns.expense?.expenseID
                    }
                    children={() => (
                      <div>
                        <b>{expns.expense?.description}</b>
                        <br />

                        <b>
                          <Moment format="DD MMMM, YYYY HH:mm">
                            {expns.expense?.dateExpense}
                          </Moment>
                        </b>


                      </div>
                    )}
                  />
                </Grid>
              ))}
          </Grid>
        </div>
      ) : null}



      {isLoading ? <CircularProgress className="centered" /> : null}

      {expenseObject.state.expense.EditVisible ? (
        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={12}>
            <ExpenseEdit
              typeExpense={typeExpense}
              accountList={accountList}
              hotelList={hotelList}
              expense={
                expenseObject?.state.expense.selectedExpense?.expense == null
                  ? undefined
                  : expenseObject?.state.expense.selectedExpense?.expense
              }
            />
          </Grid>
        </Grid>
      ) : null}

    </div>
  );
};

export default ExpensePresenter;
