import React, { useContext } from 'react';
import { Fab } from '@material-ui/core';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { Colors, LightenDarkenColor } from '../Utils';
import { EventContextUseReducer } from './EventContext';
import { Types } from './EventManagerReducer';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backToList: {
      margin: theme.spacing(1),
      background: Colors.mainBackgroundColor,
    "&:hover": {
      color: "black",
      background: LightenDarkenColor(Colors.mainBackgroundColor, 40),
    },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  })
);

const EventManagerCommander = () => {
  const eventObject = useContext(EventContextUseReducer);
  const classes = useStyles();

  const handleFoundEvents = () => {
    eventObject.dispatch({ type: Types.SET_EVENT_EDIT_VISIBLE, payload: { EditVisible: false }, });
    eventObject.dispatch({ type: Types.SET_EVENT_FOUND_VISIBLE, payload: { FoundVisible: true }, });
  };

  return (
    <div>
      {eventObject.state.event.EditVisible ? (
        <div>
          <Fab
            variant="extended"
            onClick={handleFoundEvents}
            aria-label="add"
            className={classes.backToList}
          >
            <NavigateBeforeIcon className={classes.extendedIcon} />
            Event list
          </Fab>
        </div>
      ) : null}
      
    </div>
  );
};

export default EventManagerCommander;
