import React, { useContext, useState } from 'react';
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import { Guid } from 'guid-typescript';

import AddIcon from '@material-ui/icons/Add';
import {
  messages,
  Colors,
  callResultsList,
  inBoundCallNoteColor,
  outBoundCallNoteColor,
  generalControlColor,
  replaceCrChars,
  CreateStringDate,
  LightenDarkenColor,
  isBlank,
  CreateTodayDate4Control,
  copyToClipBoard,
  TealSwitch,
  CreateDate4ControlwithValue,
} from '../Utils';
import {
  Theme,
  makeStyles,
  createStyles,
  Fab,
  Switch,
  FormControlLabel,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import 'react-toastify/dist/ReactToastify.css';

import {
  Grid,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from '@material-ui/core';

import { useForm, Controller } from 'react-hook-form';

import { handleResponse } from '../autorization/handleResponse';

import { CustomerContextUseReducer } from '../Customer/CustomerContext';
import { Types } from './CallReducer';
import { ICallCardData, ICall } from '../interfaces/ICall';
import { CallContextUseReducer } from './CallContext';
import Moment from 'react-moment';
import { ICallNote } from '../interfaces/ICallNote';
import GeneralButton, {
  GeneralAddButton,
  GeneralDeleteButton,
} from '../general/GeneralButton';
import {
  createCallAPICall,
  updateCallAPICall,
  createCallNotesAPICall,
  deleteCallAPICall,
} from '../services/DataServices';
import { isNull } from 'util';
import ConfirmationDialog from '../general/ConfirmationDialog';
import { IAdministrator } from '../interfaces/IAdministrator';
import EventEdit from '../EventManager/EventEdit';
import { EventProviderUseReducer } from '../EventManager/EventContext';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      margin: theme.spacing(1),
    },
    generalButton: {
      background: Colors.mainBackgroundColor,
      '&:hover': {
        color: 'black',
        background: LightenDarkenColor(Colors.mainBackgroundColor, 40),
      },
    },

    addNoteButton: {
      width: '100%',
      background: Colors.privateCustomerCustomerColor,
      color: 'white',
      '&:hover': {
        color: 'white',
        background: LightenDarkenColor(Colors.privateCustomerCustomerColor, 40),
      },
    },
    clearNoteButton: {
      width: '100%',
      background: Colors.deputyPrincipalColor,
      color: 'white',
      '&:hover': {
        color: 'white',
        background: LightenDarkenColor(Colors.deputyPrincipalColor, 40),
      },
    },
    buttonRemove: {
      background: Colors.dangerBackgroundColor,
    },
    alignedRight: {
      float: 'right',
    },
    outbound_call_note: {
      float: 'right',
      backgroundColor: outBoundCallNoteColor,
      width: '70%',
      textAlign: 'left',
      padding: '10px',
      borderRadius: '10px',
    },
    inbound_call_note: {
      width: '70%',
      float: 'left',
      backgroundColor: inBoundCallNoteColor,
      textAlign: 'left',
      padding: '10px',
      borderRadius: '10px',
    },
    bold: {
      fontWeight: 'bold',
    },

    admin_emails: {
      backgroundColor: Colors.secondBackgroundColor,
      padding: '10px',
      borderRadius: '10px',
      marginTop: '5px',
      marginBottom: '5px',
    },
    admin_emails_empty: {
      display: 'none',
    },

    innerForm: {
      padding: '10px',
    },
  })
);

const CallEdit = (props: ICallCardData) => {
  const callObject = useContext(CallContextUseReducer);
  const customerObject = useContext(CustomerContextUseReducer);

  // render ADD EVENT button only if status (result) is PERFORMANCE or CONFIRMED PERFORMANCE
  const RenderAddEventButton = (): boolean => {
    if (props.call?.result === 5 || props.call?.result === 10) return true;
    return false;
  };

  const emptyCall: ICall = {
    shortNotes: '',
    callBackDate: new Date(),
    lastUpdated: new Date(),
    callID: 0,
    administratorID: 0,
    callBackDateStr: '',
    result: 1,
    hotelID: null,
  };

  const deleteCallHandler = () => {
    SetOpenConfirmationDialog(true);
  };

  const [openConfirmationDialog, SetOpenConfirmationDialog] = useState<boolean>(
    false
  );

  const [openDialogEventEdit, setopenDialogEventEdit] = React.useState(false);

  const handleClickEventEditOpen = () => {
    setopenDialogEventEdit(true);
  };

  const handleCloseEventDialog = () => {
    setopenDialogEventEdit(false);
  };

  const setNewCallNotes = (passedValue: ICallNote[]): ICallNote[] => {
    let newV = passedValue.forEach(
      (item) => (item.callNoteIDGuid = Guid.raw())
    );
    return passedValue;
  };

  const [isLoading, SetIsLoading] = useState<boolean>(false);
  const [outbound, SetOutBound] = useState<boolean>(false);
  const [callNotes, SetCallNotes] = useState<ICallNote[]>(
    setNewCallNotes(
      props.call?.callNotes === undefined ? [] : props.call?.callNotes
    )
  );

  const [initialValues] = useState<ICall | {}>(
    props.call == null ? {} : props.call
  );

  const {
    register,
    control,
    handleSubmit,
    reset,
    errors,
    getValues,
    setValue,
  } = useForm<ICall>({
    defaultValues: initialValues,
  });

  const handleNewCallEvent = () => {
    reset(emptyCall);
    callObject.dispatch({      type: Types.SET_IS_NEW,      payload: { isNew: true },    });
    callObject.dispatch({      type: Types.SET_OPEARTION_DISABLED,      payload: { operationDisabled: false },
    });
  };

  const deleteCallNote = (callNoteGuidID: string | undefined) => {
    SetCallNotes(
      callNotes?.filter((obj) => obj.callNoteIDGuid !== callNoteGuidID)
    );
  };

  const addCallNote = () => {
    
    SetOutBound(!outbound);
    let values = getValues();
    if (isBlank(values.notes)) return;
    let note = replaceCrChars(values.notes);

    let callID = props.call?.callID;

    SetCallNotes([
      {
        note: note,
        callID: callID,
        callNoteIDGuid: Guid.raw(),
        outbound: outbound ? true : false,
      },
      ...callNotes,
    ]);
    
  };

  const onSubmit = async (data: ICall) => {
    callObject.dispatch({ type: Types.SET_OPEARTION_DISABLED, payload: { operationDisabled: true }, });
    data.customerID = customerObject.state.customer.selectedCustomer?.customer?.customerID;
    data.callID = callObject.state.call.selectedCall?.call?.callID;
    data.callBackDateStr = CreateStringDate(data.callBackDate);
    SetIsLoading(true);
    if (callObject.state.call.isNew) CreateCall(data);
    else UpdateCall(data);
  };

  const CreateCall = async (call: ICall) => {
    await createCallAPICall(call)
      .then(handleResponse)
      .then(
        (callID) => {
          CreateCallNotes(
            callNotes === undefined ? [] : callNotes,
            messages.SuccessCreatedCallNotes,
            callID
          );
          callObject.dispatch({
            type: Types.SET_CURRENT_CALL,
            payload: { selectedCall: {} },
          });
          callObject.dispatch({
            type: Types.SET_OUTCOME,
            payload: {
              operationOutcome: {
                success: true,
                message: messages.SuccessCreateCall,
              },
            },
          });
          callObject.dispatch({
            type: Types.SET_CALL_EDIT_VISIBLE,
            payload: { EditVisible: false },
          });
          callObject.dispatch({
            type: Types.SET_CALL_FOUND_VISIBLE,
            payload: { FoundVisible: true },
          });
          callObject.dispatch({
            type: Types.SET_SHOW_NEW_BUTTON,
            payload: { setShowNewButton: true },
          });

          SetIsLoading(false);
        },
        (error) => {
          SetIsLoading(false);
          callObject.dispatch({
            type: Types.SET_OUTCOME,
            payload: { operationOutcome: { success: false, message: error } },
          });
        }
      );
  };
  const UpdateCall = async (call: ICall) => {
    await updateCallAPICall(call)
      .then(handleResponse)
      .then(
        (result) => {
          callObject.dispatch({
            type: Types.SET_OUTCOME,
            payload: {
              operationOutcome: {
                success: true,
                message: messages.SuccessUpdatedCall,
              },
            },
          });
          CreateCallNotes(
            callNotes == undefined ? [] : callNotes,
            messages.SuccessCreatedCallNotes
          );
          SetIsLoading(false);
        },
        (error) => {
          SetIsLoading(false);
          callObject.dispatch({
            type: Types.SET_OUTCOME,
            payload: { operationOutcome: { success: false, message: error } },
          });
        }
      );
  };

  const CreateCallNotes = async (
    callNotesToCreate: ICallNote[] | undefined,
    message: string,
    passedCallID?: number
  ) => {
    let values = getValues();
    let note = replaceCrChars(values.notes);
    if (note === '') {
      let flagToChange = callObject.state.call.needToUpdate;
      callObject.dispatch({
        type: Types.UPDATE_CALL_FLAG,
        payload: { needToUpdate: !flagToChange },
      });
      callObject.dispatch({
        type: Types.SET_CALL_EDIT_VISIBLE,
        payload: { EditVisible: false },
      });
      callObject.dispatch({
        type: Types.SET_CALL_FOUND_VISIBLE,
        payload: { FoundVisible: true },
      });
      callObject.dispatch({
        type: Types.SET_SHOW_NEW_BUTTON,
        payload: { setShowNewButton: true },
      });

      return;
    }

    let objectToCreate = [] as ICallNote[] | undefined;
    if (callObject.state.call.isNew) {
      if (callNotes.length === 0)
        // if nothing added but have some data as note
        objectToCreate = [
          {
            callID: passedCallID,
            note: note,
            callNoteIDGuid: Guid.raw(),
            outbound: outbound ? true : false,
          },
          ...callNotes,
        ] as ICallNote[];
      // adding the whole bunch of notes
      else {
        callNotes.forEach((item) =>
          objectToCreate?.push({
            callID: passedCallID,
            note: item.note,
            callNoteIDGuid: Guid.raw(),
            outbound: item.outbound,
          })
        );
      }
    } // if UPDATE - just add what we have got so far
    else objectToCreate = callNotesToCreate;

    await createCallNotesAPICall(objectToCreate)
      .then(handleResponse)
      .then(
        (result) => {
          let flagToChange = callObject.state.call.needToUpdate;
          callObject.dispatch({
            type: Types.UPDATE_CALL_FLAG,
            payload: { needToUpdate: !flagToChange },
          });
          callObject.dispatch({
            type: Types.SET_OUTCOME,
            payload: {
              operationOutcome: {
                success: true,
                message: messages.SuccessCreatedCallNotes,
              },
            },
          });
          callObject.dispatch({
            type: Types.SET_CALL_EDIT_VISIBLE,
            payload: { EditVisible: false },
          });
          callObject.dispatch({
            type: Types.SET_CALL_FOUND_VISIBLE,
            payload: { FoundVisible: true },
          });
          callObject.dispatch({
            type: Types.SET_SHOW_NEW_BUTTON,
            payload: { setShowNewButton: true },
          });
          SetIsLoading(false);
        },
        (error) => {
          SetIsLoading(false);
          callObject.dispatch({
            type: Types.SET_OUTCOME,
            payload: { operationOutcome: { success: false, message: error } },
          });
        }
      );
  };

  const clearNote = () => {
    setValue('notes', '');
  };

  const CopyAdminNameToClipBoard = () => {
    copyToClipBoard(
      (props.adminList as IAdministrator[]).find(
        (adm) => adm.adminID === getValues('administratorID')
      )?.name as string
    );
  };

  const DeleteCall = async (callToDelete: ICall) => {
    await deleteCallAPICall(callToDelete)
      .then(handleResponse)
      .then(
        (result) => {
          callObject.dispatch({
            type: Types.UPDATE_CALL_FLAG,
            payload: { needToUpdate: true },
          });
          callObject.dispatch({
            type: Types.SET_CALL_FOUND_VISIBLE,
            payload: { FoundVisible: true },
          });
          callObject.dispatch({
            type: Types.SET_CALL_EDIT_VISIBLE,
            payload: { EditVisible: false },
          });
          callObject.dispatch({
            type: Types.SET_SHOW_NEW_BUTTON,
            payload: { setShowNewButton: true },
          });
          callObject.dispatch({
            type: Types.SET_OUTCOME,
            payload: {
              operationOutcome: {
                success: true,
                message: messages.SuccessDeleteCall,
              },
            },
          });
        },
        (error) => {
          callObject.dispatch({
            type: Types.SET_OUTCOME,
            payload: { operationOutcome: { success: false, message: error } },
          });
        }
      );
  };

  const handleConfirmationDeleteDialog = (event: boolean) => {
    SetOpenConfirmationDialog(false);
    if (event === true) {
      DeleteCall(callObject.state.call.selectedCall?.call as ICall);
    }
  };

  const handleShowEditEvent = () => {
    setopenDialogEventEdit(true);
  };

  const classes = useStyles();

  return (
    <div>
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={true}
          content="Are you sure you like to delete this call ?"
          onResult={(event) => handleConfirmationDeleteDialog(event)}
        />
      ) : null}

      {isLoading ? <CircularProgress className="centered" /> : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item sm={5} xs={12}>
            <FormControl className="full-width">
              <InputLabel>Administrator in charge</InputLabel>

              <Controller
                as={
                  <Select>
                    {!props.adminList ||
                      props.adminList.map((adm) => (
                        <MenuItem key={adm.adminID} value={adm.adminID}>
                          {adm.name}
                        </MenuItem>
                      ))}
                  </Select>
                }
                name="administratorID"
                rules={{ required: true }}
                control={control}
              />
            </FormControl>

            <small className="red">
              {errors.administratorID && 'Administrator is required'}{' '}
            </small>
          </Grid>
          <Grid item sm={1} xs={12}>
            <Fab
              className={classes.generalButton}
              onClick={CopyAdminNameToClipBoard}
              aria-label="add"
            >
              <FileCopyTwoToneIcon />
            </Fab>
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormControl className="full-width">
              <InputLabel>Call status</InputLabel>

              <Controller
                as={
                  <Select>
                    {callResultsList.map((res) => (
                      <MenuItem key={res.id} value={res.id}>
                        {res.Name}
                      </MenuItem>
                    ))}
                  </Select>
                }
                name="result"
                defaultValue='6'
                rules={{ required: false }}
                control={control}
              />
            </FormControl>
          </Grid>

          <Grid item  xs={12}>
            <TextField
              inputRef={register({ required: false })}
              className="full-width"
              name="callBackDate"
              id="datetime-local"
              label="Due Date"
              type="datetime-local"
              defaultValue={CreateTodayDate4Control()}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="full-width"
              name="shortNotes"
              label="Short Note"
              InputLabelProps={{
                shrink: true,
              }}
              inputRef={register({ required: true })}
            />
            <small className="red">
              {errors.shortNotes && 'Note is required'}{' '}
            </small>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="notes"
              className="full-width"
              label="Notes"
              multiline
              inputRef={register({ required: false })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="gps"
              className="full-width"
              label="GPS address for this call"
              inputRef={register({ required: false })}
            />
          </Grid>
          <Grid item xs={12}>
          <FormControl className="full-width">
                <InputLabel>Hotel</InputLabel>

                <Controller
                  as={
                    <Select>
                      {!props.hotelList ||
                        props.hotelList.map((htl) => (
                          <MenuItem key={htl.hotelID} value={htl.hotelID}>
                            {htl.name}
                          </MenuItem>
                        ))}
                    </Select>
                  }
                  name="hotelID"
                  rules={{ required: false }}
                  control={control}
                />
              </FormControl>
              </Grid>


          <Grid item sm={1} xs={12}>
            <FormControlLabel
              control={
                <TealSwitch
                  checked={outbound}
                  onChange={(event) => SetOutBound(event.target.checked)}
                />
              }
              label="Outbound"
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item sm={2} md={2} xs={12}>
            <Fab
              variant="extended"
              onClick={addCallNote}
              aria-label="add"
              className={classes.addNoteButton}
            >
              <AddIcon />
              Add note
            </Fab>
          </Grid>

          <Grid item sm={3} md={3} xs={12}>
            <Fab
              variant="extended"
              onClick={clearNote}
              aria-label="add"
              className={classes.clearNoteButton}
            >
              clear note
            </Fab>
          </Grid>
          <Grid item sm={3} md={3} xs={12}>
            {RenderAddEventButton() ? (
              <GeneralButton
                content="ADD EVENT"

                onClick={handleShowEditEvent}
              />
            ) : null}

          </Grid>
        </Grid>

        <div className="mt-1">
          <Grid container spacing={2}>
            <Grid item sm={3} md={3} xs={12}>
              <GeneralButton
                className="full-width"
                type="submit"
                content="update"
              />
            </Grid>
            <Grid item sm={1} md={1} xs={12}>
              <GeneralAddButton 
                onClick={handleNewCallEvent}
                type="reset" 
                size="small" />
            </Grid>
            {callObject.state.call.isNew ? null : (<Grid item sm={2} md={1} lg={1} xs={12}>
              <GeneralDeleteButton
                className="full-width"
                content="delete"
                onClick={deleteCallHandler}
              />
            </Grid>
            )}
          </Grid>
        </div>

        <Grid container spacing={1}>
          {!callNotes ||
            callNotes.map((cNotes) => (
              <Grid key={cNotes.callNoteID} item sm={12} xs={12}>
                <div
                  className={
                    cNotes.outbound
                      ? classes.outbound_call_note
                      : classes.inbound_call_note
                  }
                >
                  <button
                    type="button"
                    onClick={() => deleteCallNote(cNotes.callNoteIDGuid)}
                    className={classes.alignedRight}
                  >
                    X
                  </button>
                  <div className={classes.bold}>
                    <Moment format="DD MMMM, YYYY HH:mm">
                      {cNotes.dateCreated}
                    </Moment>
                  </div>

                  <p
                    dangerouslySetInnerHTML={{
                      __html: cNotes.note === undefined ? '' : cNotes.note,
                    }}
                  ></p>
                </div>
              </Grid>
            ))}
        </Grid>
      </form>

      <Dialog open={openDialogEventEdit} onClose={handleCloseEventDialog}>
        <DialogTitle id="alert-dialog-title">{'ADD EVENT'}</DialogTitle>
        <DialogContent>
          <EventProviderUseReducer>
            <EventEdit
              hotelList={props.hotelList}
              onComplete={handleCloseEventDialog}
              renderNewDeleteButtons={false}
              event={{
                startTime: getValues('callBackDate'),
                endTime: getValues('callBackDate'),
              }}
            />
          </EventProviderUseReducer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEventDialog} color="primary">
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CallEdit;
