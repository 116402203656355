import React, {  useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { QuoteProviderUseReducer } from './QuoteContext';
import QuoteCommander from './QuoteCommander';
import QuotePresenter from './QuotePresenter';

const useStyles = makeStyles({
  root: {},
  margin_top_10:
  {
    width: '95%',
    marginTop: '1em',
    margin: '0 auto',
  },

  wrapper: {
    width: '95%',
    margin: '0 auto',
    //backgroundColor: 'red',
  },
  gray: {
    backgroundColor: 'lightgray',
  },

  yellow: {
    backgroundColor: '#F6D55C',
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 1,
  },
});


const Quote = () => {

  const classes = useStyles();


  return (
    <div>
       <QuoteProviderUseReducer>

        
          <div className={classes.wrapper}>
            <Grid container spacing={1}>
              <Grid item xs={1}>
                <h3 className="left">Quotes</h3>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <div className="left">
                    <QuoteCommander />
                  </div>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <QuotePresenter />
              </Grid>
            </Grid>
          </div>
        
      </QuoteProviderUseReducer>
    </div>
  );
};

export default Quote;
