import React, { useContext } from 'react';
import { LightenDarkenColor, Colors } from '../Utils';
import GeneralButton from '../general/GeneralButton';
import { CustomerCallContextUseReducer } from './CustomerCallContext';
import { Types } from './CustomerCallReducer';
import { CustomerCallQueryID, CustomerCallOperationName } from '../Utils';

import {
  makeStyles,
  Grid,
  
  Typography,
  Button,
} from '@material-ui/core';
import classes from '*.module.css';

const useStyles = makeStyles({
  allPerformancesButtonStyle: {
    background: Colors.dangerBackgroundColor,
    '&:hover': {
      color: 'black',
      background: LightenDarkenColor(Colors.dangerBackgroundColor, 40),
    },
    borderRadius: 10,
    width: '100%',
    color: 'black',
    height: 40,
    padding: '0 30px',
  },
});

const CustomerCallsCommander = () => {
  const customerCallObject = useContext(CustomerCallContextUseReducer);
  const classes = useStyles();
 
  const handleOperation = (queryID: string, operation: string ) => {
    customerCallObject.dispatch({ type: Types.SET_QUERY_ID, payload: { queryID: queryID }, });
    customerCallObject.dispatch({ type: Types.SET_OPERATION, payload: { operation: operation }, });
    customerCallObject.dispatch({ type: Types.SET_PRESENTER_VISIBLE, payload: { presenterVisible: true }, });
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item sm={3} md={3} lg={3} xs={12}>
          <GeneralButton
            className="full-width"
            onClick={() => handleOperation(CustomerCallQueryID.PerformancesAround, CustomerCallOperationName.Performances_around_today)}
            content="PERFORMANCES AROUND"
          />
        </Grid>

        <Grid item sm={3} md={3} lg={2} xs={12}>
          <GeneralButton
            className="full-width"
            onClick={() => handleOperation(CustomerCallQueryID.DateDiscussions, CustomerCallOperationName.DateDiscussions)}
            content="DATE DISCUSSIONS"
          />
        </Grid>

        <Grid item sm={3} md={3} lg={2} xs={12}>
          <GeneralButton
            className="full-width"
            onClick={() => handleOperation(CustomerCallQueryID.ReshedulingProcess, CustomerCallOperationName.ReshedulingProcess)}
            content="RESCHEDULING"
          />
        </Grid>

        <Grid item sm={3} md={3} lg={2} xs={12}>
          <GeneralButton
            className="full-width"
            onClick={() => handleOperation(CustomerCallQueryID.GeneralEnquiry, CustomerCallOperationName.GeneralEnquiry)}
            content="GENREAL ENQUIRES"
          />
        </Grid>

        <Grid item sm={3} md={3} lg={3} xs={12}>
          <Button
            className={classes.allPerformancesButtonStyle}
            onClick={() => handleOperation(CustomerCallQueryID.AllPerformances, CustomerCallOperationName.AllPerformances)}
          >
            ALL PERFORMANCES
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomerCallsCommander;
