import React, { createContext, useReducer, Dispatch } from "react";
import { CustomerCallActions, CustomerCallDataType, customerCallReducer } from "./CustomerCallReducer";
import { CustomerCallQueryID, CustomerCallOperationName } from "../Utils";
import { IChildrenProp } from "../interfaces/IChildren";

type InitialStateType = {
  customerCall: CustomerCallDataType;

};

const initialState = {
  customerCall: { 
    queryID: CustomerCallQueryID.PerformancesAround, 
    operation: CustomerCallOperationName.Performances_around_today,
    currentCustomerCall: undefined,
    presenterVisible: true,
    needToRefresh: false,
    filterType: 'DUE_DATE',
    
  },
};

const CustomerCallContextUseReducer = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<CustomerCallActions>;
}>({
  state: initialState,
  dispatch: () => null
});

const mainReducer = (
  { customerCall  }: InitialStateType,
  action: CustomerCallActions 
) => ({
  customerCall: customerCallReducer(customerCall, action),
  
});

const CustomerCallProviderUseReducer: React.FC<IChildrenProp> = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <CustomerCallContextUseReducer.Provider value={{ state, dispatch }}>
      {children}
    </CustomerCallContextUseReducer.Provider>
  );
};

export { CustomerCallProviderUseReducer, CustomerCallContextUseReducer };