import React from 'react';
import GoogleMapReact, { Maps } from 'google-map-react';

export interface ICoordinates {
  lat: number;
  lng: number;
}

interface Props {
  coordinates: ICoordinates;
}

const GoogleMap = (props: Props) => {
  const renderMarkers = (map: any, maps: any) => {
    let marker = new maps.Marker({
      position: { lat: props.coordinates.lat, lng: props.coordinates.lng },
      map,
      title: 'Hello World!',
    });
    return marker;
  };

  const mapsOptions = (maps: Maps) =>
  {
    return {
        streetViewControl: true,
        scaleControl: true,
        fullscreenControl: true,
        styles: [{
            featureType: "poi.business",
            elementType: "labels",
            stylers: [{
                visibility: "on"
            }]
        }],
        gestureHandling: "greedy",
        disableDoubleClickZoom: true,
        minZoom: 8,
        maxZoom: 18,

        mapTypeControl: true,
        
        mapTypeControlOptions: {
            style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: maps.ControlPosition.BOTTOM_CENTER,
            mapTypeIds: [
                maps.MapTypeId.ROADMAP,
                maps.MapTypeId.SATELLITE,
                maps.MapTypeId.HYBRID
            ]
        },

        zoomControl: true,
        clickableIcons: false
    };
  }

  return (
    <div style={{ height: '500px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyATYJEWEvDFlvc1SFOV8tYhOuqCiQM3yks' }}
        defaultCenter={props.coordinates}
        yesIWantToUseGoogleMapApiInternals
        options={mapsOptions}
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        defaultZoom={8}
      ></GoogleMapReact>
    </div>
  );
};

export default GoogleMap;
