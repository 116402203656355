import React, { useContext, useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { GeneralAddButton } from '../general/GeneralButton';
import { Types } from './HotelReducer';
import { handleResponse } from '../autorization/handleResponse';
import {
  makeStyles,
  Grid,
  CircularProgress,

} from '@material-ui/core';

import { GeneralCard } from '../general/GeneralCard';
import HotelEdit from './HotelEdit';
import { HotelContextUseReducer } from './HotelContext';
import urls, { redirectToGoogleMap, Colors, LightenDarkenColor, isBlank } from '../Utils';
import { IHotelCardData, IHotel } from '../interfaces/IHotel';
import { fetchHotelsAPICall } from '../services/DataServices';
import { authHeader } from '../autorization/auth-header';
import { authenticationService } from '../autorization/authentication.service';

const useStyles = makeStyles({
  root: {},
  wrapper: {
    marginTop: '20px',
  },
  gray: {
    backgroundColor: 'lightgray',
  },
  generalButton: {
    background: Colors.mainBackgroundColor,
    '&:hover': {
      color: 'black',
      background: LightenDarkenColor(Colors.mainBackgroundColor, 40),
    },
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 1,
  },
});

const HotelPresenter = () => {
 
  const hotelObject = useContext(HotelContextUseReducer);
  
  const [hotels, SetHotels] = useState<IHotelCardData[]>([]);
  const [isLoading, SetIsLoading] = useState(false);
  const classes = useStyles();
  const SetMyHotels = (passedHotels: IHotel[]) => {

    let hotel_1 = passedHotels.map((v) => {
      return { hotel: v };
    }) as IHotelCardData[];

    SetHotels(hotel_1.sort((a, b) => ((a.hotel?.name as String) > (b.hotel?.name as String) ? 1 : -1)));
    SetIsLoading(false);
  };

  const addNewHotel = () => {
    hotelObject.dispatch({ type: Types.SET_HOTEL_EDIT_VISIBLE, payload: { EditVisible: true }, });
    hotelObject.dispatch({ type: Types.SET_HOTEL_FOUND_VISIBLE, payload: { FoundVisible: false }, });
    hotelObject.dispatch({ type: Types.SET_CURRENT_HOTEL, payload: { selectedHotel: {} }, });
    hotelObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: false }, });

    hotelObject.dispatch({ type: Types.SET_OPEARTION_DISABLED, payload: { operationDisabled: true }, });
    hotelObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: true }, });
    hotelObject.dispatch({ type: Types.SET_IS_DIRTY, payload: { isDirty: false }, });
  };


  useEffect(() => {
    SetIsLoading(true);

    const requestOptions = () => { 
      return { method: 'GET', headers: authHeader() } 
  };
  
    const fetchHotels = async () => {
      try {
        const response = await fetch(`${urls.getHotelsUrl}`, requestOptions());
  
        if (!response.ok) {
          // Handle HTTP errors explicitly
        console.log('errr!!!!!');
        }
  
        const result = await response.json();
        SetMyHotels(result);
  
        
      } catch (error) {
        // Handle other errors (e.g., network issues, JSON parsing)
        console.log('error after catch : ' + error);
        window.location.reload();
      }
    };
  
    fetchHotels();
  }, [
    hotelObject?.state.hotel.needToUpdate,    
  ]);


  const visitingGPS = (gps_coordinates: string) => {
    redirectToGoogleMap(gps_coordinates);
  };

  const showSuccessMessage = (value: string, success: boolean) => {
    if (success)
      toast.success(value, { autoClose: 6000 });
    else
      toast.error(value, { autoClose: 6000 });
  }


  const handleClick = (passedBackhotel: IHotelCardData) => {

    visitingGPS(
      passedBackhotel.hotel?.address === undefined
        ? ''
        : passedBackhotel.hotel?.address
    )
  };

  useEffect(() => {
    if (!isBlank(hotelObject.state.hotel.operationOutcome.message))
      showSuccessMessage(hotelObject.state.hotel.operationOutcome.message, true);
  }, [hotelObject.state.hotel.operationOutcome]);


  return (
    <div>
       <ToastContainer autoClose={6000} />

       <div className="mt-1 mb-1">
        {hotelObject.state.hotel.setShowNewButton ? (
          <div>
            <GeneralAddButton
              onClick={addNewHotel}
            />
          </div>
        ) : null}
      </div>

      {hotelObject.state.hotel.FoundVisible ? (
        <Grid container className={classes.root} spacing={1}>
          {!hotels ||
            hotels.map((qt) => (
              <Grid key={qt.hotel?.hotelID} item sm={4} xs={12}>                

                <GeneralCard
                  entity={qt}
                  onSelect={handleClick}
                  defaultBackgroundColor={'#66a1af'}
                  entityId={
                    qt.hotel?.hotelID === undefined ? 0 : qt.hotel?.hotelID
                  }
                  children={() => (
                    <div>
                      <b>{qt.hotel?.name}</b>
                      
                    </div>
                  )}
                />
              </Grid>
            ))}
        </Grid>
        ) : null}

      {isLoading ? <CircularProgress className="centered" /> : null}

      {hotelObject.state.hotel.EditVisible ? (
        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={12}>
            <HotelEdit
              hotel={
                hotelObject?.state.hotel.selectedHotel?.hotel == null
                  ? undefined
                  : hotelObject?.state.hotel.selectedHotel?.hotel
              }
            />
          </Grid>
        </Grid>
      ) : null}

    </div>
  );
};

export default HotelPresenter;
