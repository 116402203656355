import React, {  useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { EventProviderUseReducer } from './EventContext';
import EventManagerCommander from './EventMangerCommander';
import EventManagerPresenter from './EventManagerPresenter';


const useStyles = makeStyles({
  root: {},
  wrapper: {
    width: '95%',
    margin: '0 auto',
    //backgroundColor: 'red',
  },
  margin_top_10:
  {
    width: '95%',
    marginTop: '1em',
    margin: '0 auto',
  },
  gray: {
    backgroundColor: 'lightgray',
  },

  yellow: {
    backgroundColor: '#F6D55C',
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 1,
  },
});
const EventManager = () => {
  const classes = useStyles();

  return (
    <div>
      <EventProviderUseReducer>
        
          <div className={classes.wrapper}>
            <Grid container spacing={1}>
            <Grid item xs={1}>
                <h3 className="left">Events</h3>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <div className="left">
                    <EventManagerCommander />
                  </div>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                 <EventManagerPresenter /> 
              </Grid>
            </Grid>
          </div>

    </EventProviderUseReducer>
    </div>
  );
};

export default EventManager;
