import React, { useContext, useState, useEffect } from 'react';
import { messages, Colors, LightenDarkenColor, CreateTodayDate4Control, CreateStringDate } from '../Utils';
import { Theme, makeStyles, createStyles, Fab, FormControlLabel, Switch, FormControl, InputLabel, Checkbox, Select, MenuItem } from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import { Grid, TextField, CircularProgress, } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { createExpenseAPICall, updateExpenseAPICall, deleteExpenseAPICall, } from '../services/DataServices';
import { handleResponse } from '../autorization/handleResponse';
import GeneralButton, { GeneralAddButton, GeneralDeleteButton } from '../general/GeneralButton';
import ConfirmationDialog from '../general/ConfirmationDialog';
import { Types } from './ExpenseReducer';
import { IExpenseCardData, IExpense } from '../interfaces/IExpense';
import { ExpenseContextUseReducer } from './ExpenseContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    dirty_state:
    {
      borderColor: 'red',
      borderStyle: 'solid',
      borderWidth: '2px',
    },
    new_state:
    {
      borderColor: 'green',
      borderStyle: 'solid',
      borderWidth: '2px',
    },
    clean_state:
    {
      borderColor: 'white',
      borderStyle: 'solid',
      borderWidth: '2px',
    },
    deleteColor:
    {
      background: Colors.deleteColor,
      '&:hover': {
        color: 'black',
        background: LightenDarkenColor(Colors.deleteColor, 40),
      },
    },

    button: {
      margin: theme.spacing(1),
    },
    buttonRemove: {
      background: Colors.dangerBackgroundColor,
    },
  })
);

const ExpenseEdit = (props: IExpenseCardData) => {
  const expenseObject = useContext(ExpenseContextUseReducer);

  const emptyExpense: IExpense = {
    accountID: 1,
    typeExpenseID: 1,
    description: '',
    note: '',
    dateExpense: new Date(),
    dateExpenseStr: '',
    hotelID: null,
    amount: 0,
    gst: true,
  };

  const deleteExpenseHandler = () => {
    SetOpenConfirmationDialog(true);
  };

  const [openConfirmationDialog, SetOpenConfirmationDialog] = useState<boolean>(false);
  const [gstStatus, SetGSTStatus] = useState<boolean>(props.expense?.gst as boolean);
  const [isLoading, SetIsLoading] = useState<boolean>(false);

  const handleChangeClosedStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    SetGSTStatus(event.target.checked);
  };

  const [initialValues] = useState<IExpense | {}>(
    props.expense == null ? {} : props.expense
  );

  const {
    register,
    control,
    handleSubmit,
    reset,
    errors,
    getValues,
    setValue,
  } = useForm<IExpense>({
    defaultValues: initialValues,
  });

  const handleNewExpenseEvent = () => {
    reset(emptyExpense);
    SetExpenseTypeID(2);
    SetGSTStatus(true);
    expenseObject.dispatch({ type: Types.SET_HOTEL_VISIBLE, payload: { hotelDropdownVisible: false }, });
    
  
  expenseObject.dispatch({ type: Types.SET_CURRENT_EXPENSE, payload: { selectedExpense: {} }, });
  expenseObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: false }, });

  expenseObject.dispatch({ type: Types.SET_OPEARTION_DISABLED, payload: { operationDisabled: true }, });
  expenseObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: true }, });
  expenseObject.dispatch({ type: Types.SET_IS_DIRTY, payload: { isDirty: false }, });  
  };

  const [expenseTypeID, SetExpenseTypeID] = useState<number>(
    props.expense?.typeExpenseID === undefined ? 2 : props.expense?.typeExpenseID
  );

  const handleChangeExpenseType = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const values = getValues();
    values.typeExpenseID = Number(event.target.value as string);
    setValue('typeExpenseID', values.typeExpenseID);
    SetExpenseTypeID(values.typeExpenseID);
    console.log(values.typeExpenseID);
    if (values.typeExpenseID === 1)
      expenseObject.dispatch({ type: Types.SET_HOTEL_VISIBLE, payload: { hotelDropdownVisible: true }, });
    else
    expenseObject.dispatch({ type: Types.SET_HOTEL_VISIBLE, payload: { hotelDropdownVisible: false }, });
  };


  useEffect(() => {
    expenseObject.dispatch({ type: Types.SET_HOTEL_VISIBLE, payload: { hotelDropdownVisible: false }, });
    if (props.expense?.typeExpenseID === 1) {
      expenseObject.dispatch({ type: Types.SET_HOTEL_VISIBLE, payload: { hotelDropdownVisible: true }, });
    } 
  }, []);



  const onSubmit = async (data: IExpense) => {

    data.gst = gstStatus;
    //data.dateExpenseStr = CreateStringDate(data.dateExpense);

    console.log('data.dateExpense: ', data.dateExpense);
    data.expenseID = expenseObject.state.expense.selectedExpense?.expense?.expenseID;
    data.typeExpenseID = expenseTypeID;
    expenseObject.dispatch({ type: Types.SET_OPEARTION_DISABLED, payload: { operationDisabled: true } });

    SetIsLoading(true);
    if (expenseObject.state.expense.isNew)
      CreateExpense(data);
    else
      UpdateExpense(data);
  };

  const CreateExpense = async (expense: IExpense) => {
    await createExpenseAPICall(expense)
      .then(handleResponse)
      .then(
        (reminderID) => {
          expenseObject.dispatch({ type: Types.SET_CURRENT_EXPENSE, payload: { selectedExpense: {} }, });
          expenseObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: true, message: messages.SuccessCreateReminder } }, });
          expenseObject.dispatch({ type: Types.SET_EXPENSE_EDIT_VISIBLE, payload: { EditVisible: false }, });
          expenseObject.dispatch({ type: Types.SET_EXPENSE_FOUND_VISIBLE, payload: { FoundVisible: true }, });
          expenseObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true }, });
          let flagToChange = expenseObject.state.expense.needToUpdate;
          expenseObject.dispatch({ type: Types.UPDATE_EXPENSE_FLAG, payload: { needToUpdate: !flagToChange }, });


          SetIsLoading(false);
        },
        (error) => {
          SetIsLoading(false);
          expenseObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: false, message: error } }, });
        }
      );
  };
  const UpdateExpense = async (expense: IExpense) => {
    await updateExpenseAPICall(expense)
      .then(handleResponse)
      .then(
        (result) => {
          expenseObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: true, message: messages.SuccessUpdatedReminder } }, });
          expenseObject.dispatch({ type: Types.SET_EXPENSE_EDIT_VISIBLE, payload: { EditVisible: false }, });
          expenseObject.dispatch({ type: Types.SET_EXPENSE_FOUND_VISIBLE, payload: { FoundVisible: true }, });
          expenseObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true }, });
          let flagToChange = expenseObject.state.expense.needToUpdate;
          expenseObject.dispatch({ type: Types.UPDATE_EXPENSE_FLAG, payload: { needToUpdate: !flagToChange }, });


          SetIsLoading(false);
        },
        (error) => {
          SetIsLoading(false);
          expenseObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: false, message: error } }, });
        }
      );
  };

  const DeleteExpense = async (expenseToDelete: IExpense) => {
    await deleteExpenseAPICall(expenseToDelete)
      .then(handleResponse)
      .then(
        (result) => {
          expenseObject.dispatch({ type: Types.UPDATE_EXPENSE_FLAG, payload: { needToUpdate: true } });
          expenseObject.dispatch({ type: Types.SET_EXPENSE_FOUND_VISIBLE, payload: { FoundVisible: true } });
          expenseObject.dispatch({ type: Types.SET_EXPENSE_EDIT_VISIBLE, payload: { EditVisible: false } });
          expenseObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true } });
          expenseObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: true, message: messages.SuccessDeleteReminder } } });
        },
        (error) => {
          expenseObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: false, message: error } }, });
        }
      );
  };


  const handleConfirmationDeleteDialog = (event: boolean) => {
    SetOpenConfirmationDialog(false);
    if (event === true) {
      DeleteExpense(expenseObject.state.expense.selectedExpense?.expense as IExpense);
    }
  };


  const classes = useStyles();

  return (
    <div>
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={true}
          content="Are you sure you like to delete this expense ?"
          onResult={(event) => handleConfirmationDeleteDialog(event)}
        />
      ) : null}

      {isLoading ? <CircularProgress className="centered" /> : null}
      <form onSubmit={handleSubmit(onSubmit)}>

      <Grid container spacing={1}>
        <Grid item sm={6} xs={12}>
        <TextField
              inputRef={register({ required: false })}
              className="full-width"
              name="dateExpense"
              id="datetime-local"
              label="Date"
              type="datetime-local"
              defaultValue={CreateTodayDate4Control()}

              InputLabelProps={{
                shrink: true,
              }}
            />
        </Grid>
      </Grid>
        <Grid container spacing={1}>
          <Grid item sm={6} xs={12}>

          <TextField
            name="amount"
            className="full-width"
            id="standard-number"
            label="Amount"
            type="decimal"
            inputRef={register({ required: true,  pattern: /^^(\d*\.)?\d+$/i })}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <small className="red">{errors.amount && 'Amount is required'} </small>
          </Grid>
          <Grid item sm={6} xs={12}>
          
          <FormControlLabel
              control={
                <Switch
                  checked={gstStatus}

                  onChange={handleChangeClosedStatus}
                  color="primary"
                />
              }
              label="GST Applicable ?"
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <FormControl className="full-width">
              <InputLabel>Accounts</InputLabel>

              <Controller
                as={
                  <Select>
                    {!props.accountList || props.accountList.map((acc) => (
                      <MenuItem key={acc.accountID} value={acc.accountID}>
                        {acc.name}
                      </MenuItem>
                    ))}
                  </Select>
                }
                name="accountID"
                rules={{ required: true }}
                control={control}
              />
              <small className="red">{errors.accountID && 'Account is required'} </small>
            </FormControl>
          </Grid>
          <Grid item sm={3} xs={12}>
          <InputLabel>Type of business expense</InputLabel>
            <Select
              className="full-width"
              onChange={handleChangeExpenseType}
              value={expenseTypeID}
            >
              {!props.typeExpense || props.typeExpense.map((tpexp) => (
                <MenuItem key={tpexp.typeExpenseID} value={tpexp.typeExpenseID}>
                  {tpexp.description}
                </MenuItem>
              ))}
            </Select>

          </Grid>
          <Grid item sm={3} xs={12}>
          {expenseObject.state.expense.hotelDropdownVisible ? (
              <FormControl className="full-width">
                <InputLabel>Hotel</InputLabel>

                <Controller
                  as={
                    <Select>
                      {!props.hotelList ||
                        props.hotelList.map((htl) => (
                          <MenuItem key={htl.hotelID} value={htl.hotelID}>
                            {htl.name}
                          </MenuItem>
                        ))}
                    </Select>
                  }
                  name="hotelID"
                  rules={{ required: false }}
                  control={control}
                />
              </FormControl>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="description"
              className="full-width"
              label="Description"
              inputRef={register({ required: true })}
            />
          <small className="red">{errors.description && 'Description is required'} </small>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="note"
              className="full-width"
              label="Notes"
              multiline
              inputRef={register({ required: false })}
            />
          </Grid>

        </Grid>
        <div className='mt-1'>
          <Grid container spacing={2}>
            <Grid item sm={3} md={3} xs={12}>
              <GeneralButton
                className="full-width"
                type="submit"
                content="update"
              />
            </Grid>
            

            <Grid item sm={1} md={1} xs={12}>
              <GeneralAddButton
                type="reset"
                onClick={handleNewExpenseEvent}
                size='small'
              />
            </Grid>
            {expenseObject.state.expense.isNew ? null : (            <Grid item sm={2} md={1} lg={1} xs={12}>
              <GeneralDeleteButton
                content='delete'
                onClick={deleteExpenseHandler}
              />
            </Grid>
)}

          </Grid>
        </div>

        <Grid container spacing={1}>

        </Grid>
      </form>
    </div>
  );
};

export default ExpenseEdit;
