import { ActionMap } from "../general/ActionMap";
import {  IInvoiceCardData  } from "../interfaces/IInvoice";
import { IMessage } from "../interfaces/IMessage";
import { BasicDataType } from "../Calls/CallReducer";

export enum Types   {
  SET_CURRENT_INVOICE = 'SET_CURRENT_INVOICE',
  UPDATE_INVOICE_FLAG = 'UPDATE_INVOICE_FLAG',
  SET_INVOICE_EDIT_VISIBLE = 'SET_INVOICE_EDIT_VISIBLE',
  SET_INVOICE_FOUND_VISIBLE = 'SET_INVOICE_FOUND_VISIBLE',
  SET_INVOICE_NEW_BUTTON = 'SET_INVOICE_NEW_BUTTON',
  SET_IS_NEW = 'SET_IS_NEW',
  SET_OPEARTION_DISABLED = 'SET_OPEARTION_DISABLED',
  SET_OUTCOME = 'SET_OUTCOME',
  SET_IS_DIRTY = 'SET_IS_DIRTY',
}

export interface InvoiceDataType  extends BasicDataType {
  selectedInvoice: IInvoiceCardData | undefined;
};

type InvoicePayload = {
  [Types.SET_CURRENT_INVOICE]: {
    selectedInvoice: IInvoiceCardData;
    
  };
  [Types.UPDATE_INVOICE_FLAG]: {
    needToUpdate: boolean;
  };
    [Types.SET_INVOICE_EDIT_VISIBLE]: {
    EditVisible: boolean;
  }; 
   [Types.SET_INVOICE_FOUND_VISIBLE]: {
    FoundVisible: boolean;
  };  

  [Types.SET_INVOICE_NEW_BUTTON]: {
    setInvoiceNewButton: boolean;
  };  
 
  [Types.SET_IS_NEW]: {
    isNew: boolean;
  };  
  [Types.SET_OPEARTION_DISABLED]: {
    operationDisabled: boolean;
  };  

  [Types.SET_OUTCOME]: 
  {
    operationOutcome: IMessage;
  };
  [Types.SET_IS_DIRTY]: {
    isDirty: boolean;
  }; 

  
};

export type InvoiceActions = ActionMap<InvoicePayload>[keyof ActionMap<
  InvoicePayload
>];

export const invoiceReducer = (state: InvoiceDataType, action: InvoiceActions) => {
  switch (action.type) {
    case Types.SET_CURRENT_INVOICE:
      return {
        ...state,
        selectedInvoice: action.payload.selectedInvoice,
      };
      case Types.UPDATE_INVOICE_FLAG:
        return {
          ...state,
          needToUpdate: action.payload.needToUpdate,
        };
       case Types.SET_INVOICE_EDIT_VISIBLE:
          return {
            ...state,
            EditVisible: action.payload.EditVisible,
          };        
           case Types.SET_INVOICE_FOUND_VISIBLE:
            return {
              ...state,
              FoundVisible: action.payload.FoundVisible,
            };     

            case Types.SET_INVOICE_NEW_BUTTON:
              return {
                ...state,
                setInvoiceNewButton: action.payload.setInvoiceNewButton,
              };                 
              case Types.SET_IS_NEW:
                return {
                  ...state,
                  isNew: action.payload.isNew,
                };     
                case Types.SET_OPEARTION_DISABLED:
                  return {
                    ...state,
                    operationDisabled: action.payload.operationDisabled,
                  };     
                  case Types.SET_OUTCOME:
                    return {
                      ...state,
                      operationOutcome: action.payload.operationOutcome,
                    };                       
                    case Types.SET_IS_DIRTY:
                      return {
                        ...state,
                        isDirty: action.payload.isDirty,
                      }; 


  
    default:
      return state;
  }
};


