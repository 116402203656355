import React, { useContext } from 'react';

import { BackToEntityButton } from '../general/GeneralButton';
import { ReminderContextUseReducer } from './ReminderContext';
import { Types } from './ReminderReducer';

const ReminderCommander = () => {
  const reminderObject = useContext(ReminderContextUseReducer);

  const handleFoundReminders = () => {
    reminderObject.dispatch({ type: Types.SET_REMINDER_EDIT_VISIBLE, payload: { EditVisible: false }, });
    reminderObject.dispatch({ type: Types.SET_REMINDER_FOUND_VISIBLE, payload: { FoundVisible: true }, });
    reminderObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true }, });
  };

  return (
    <div>
      {reminderObject.state.reminder.EditVisible ? (
        <div>
          <BackToEntityButton
            content='reminder list'
            onClick={handleFoundReminders}
          />

        </div>
      ) : null}

    </div>
  );
};

export default ReminderCommander;
