import moment from 'moment';
import { ICoordinates } from './general/GoogleMap';

import {
  Switch,
  withStyles,
} from '@material-ui/core';

export const tempoPassword = 'WRONG_ONE';
//const mainDomain  = 'api.vaskovalski.co.nz';
//const mainDomain = 'newapi.vaskovalski.co.nz';
const mainDomain = 'server1.vaskovalski.co.nz';
//const httpPrefix = '${httpPrefix}';
//const httpPrefix = 'http://';
const httpPrefix = 'https://';
//const mainDomain = 'experiment.vaskovalski.co.nz';

//const mainDomain = 'kidsmagic.co.nz/WinhostDBAppServer';
//const mainDomain = 'kidsmagic.co.nz/InterserverDBAppServer';
//const mainDomain = 'kidsmagic.co.nz/mobileappserver';

//const mainDomain = 'localhost:29327';

//const mainDomain = 'localhost:52877';



export const NorthIsland = 'North Island';
export const SouthIsland = 'SOUTH ISLAND';



const urls = {
  // Additional
   getConnectionInfoUrl: `${httpPrefix}${mainDomain}/api/CustomerRequest/GetConnectionInfo`,

  CreateCustomerUrl: `${httpPrefix}${mainDomain}/api/Customer/CreateCustomer`,
  UpdateCustomerUrl: `${httpPrefix}${mainDomain}/api/Customer/UpdateCustomer`,
  DeleteCustomerUrl: `${httpPrefix}${mainDomain}/api/Customer/DeleteCustomer`,
  GetCustomersUrl: `${httpPrefix}${mainDomain}/api/Customer/GetAllCustomers`,
  GetParticularCustomerUrl: `${httpPrefix}${mainDomain}/api/Customer/GetParticularCustomer`,


  GetHistorysUrl: `${httpPrefix}${mainDomain}/api/History/GetHistories`,
  GetPositionUrl: `${httpPrefix}${mainDomain}/api/Position/GetPositions`,

  // ADMINS
  GetAdminUrl: `${httpPrefix}${mainDomain}/api/Administrator/getadmins`,
  GetAdminEmailsByAdminIDUrl: `${httpPrefix}${mainDomain}/api/Administrator/GetAdminEmails`,
  CreateAdminEmailUrl: `${httpPrefix}${mainDomain}/api/Administrator/CreateAdminEmails`,
  UpdateAdminUrl: `${httpPrefix}${mainDomain}/api/Administrator/UpdateAdministrator`,
  CreateAdminUrl: `${httpPrefix}${mainDomain}/api/Administrator/CreateAdministrator`,
  DeleteAdminUrl: `${httpPrefix}${mainDomain}/api/Administrator/DeleteAdministrator`,
  MakeInactiveAdminUrl: `${httpPrefix}${mainDomain}/api/Administrator/MakeAdministratorInactive`,

  // SHOWS
  GetShowUrl: `${httpPrefix}${mainDomain}/api/Show/getshows`,
  UpdateShowUrl: `${httpPrefix}${mainDomain}/api/Show/UpdateShow`,
  CreateShowUrl: `${httpPrefix}${mainDomain}/api/Show/CreateShow`,
  DeleteShowUrl: `${httpPrefix}${mainDomain}/api/Show/DeleteShow`,
  GetPricePerStudentUrl: `${httpPrefix}${mainDomain}/api/Show/GetPricePerStudent`,


  // REMINDERS
  GetReminderUrl: `${httpPrefix}${mainDomain}/api/Reminder/GetReminders`,
  UpdateReminderUrl: `${httpPrefix}${mainDomain}/api/Reminder/UpdateReminder`,
  CreateReminderUrl: `${httpPrefix}${mainDomain}/api/Reminder/CreateReminder`,
  DeleteReminderUrl: `${httpPrefix}${mainDomain}/api/Reminder/DeleteReminder`,

  // CALLS
  GetCallByCustomerIDUrl: `${httpPrefix}${mainDomain}/api/Call/GetCalls`,
  CreateCallUrl: `${httpPrefix}${mainDomain}/api/Call/CreateCall`,
  UpdateCallUrl: `${httpPrefix}${mainDomain}/api/Call/UpdateCall`,
  DeleteCallUrl: `${httpPrefix}${mainDomain}/api/Call/DeleteCall`,
  CreateCallNoteUrl: `${httpPrefix}${mainDomain}/api/Call/CreateCallNotes`,
  CreateQuickPerformanceCallUrl: `${httpPrefix}${mainDomain}/api/QuickCustomer/CreateQuickCallBooking`,


  // Hotels
  getHotelsUrl: `${httpPrefix}${mainDomain}/api/Hotel/GetHotels`,
  CreateHotelUrl: `${httpPrefix}${mainDomain}/api/hotel/CreateHotel`,
  UpdateHotelUrl: `${httpPrefix}${mainDomain}/api/hotel/UpdateHotel`,
  DeleteHotelUrl: `${httpPrefix}${mainDomain}/api/hotel/DeleteHotel`,



  GetCustomerCallUrl: `${httpPrefix}${mainDomain}/api/Call/GetCustomerCalls`,
  GetParticularCustomerCallsUrl: `${httpPrefix}${mainDomain}/api/call/GetParticularCustomerCalls`,
  GetPerformancesAroundCustomerCallsUrl: `${httpPrefix}${mainDomain}/api/call/GetPerformancesAroundCustomerCalls`,
  GetCallNotesByCallIdUrl: `${httpPrefix}${mainDomain}/api/call/GetCallNotes`,

  // Events
  GetEventsUrl: `${httpPrefix}${mainDomain}/api/event/GetEvents`,
  CreateEventUrl: `${httpPrefix}${mainDomain}/api/event/CreateEvent`,
  UpdateEventUrl: `${httpPrefix}${mainDomain}/api/event/UpdateEvent`,
  DeleteEventUrl: `${httpPrefix}${mainDomain}/api/event/DeleteEvent`,

  // Expenses
  GetAccountsUrl: `${httpPrefix}${mainDomain}/api/expense/GetAccounts`,
  GetTypeExpenseUrl: `${httpPrefix}${mainDomain}/api/expense/GetTypeExpenses`,

  GetExpensesUrl: `${httpPrefix}${mainDomain}/api/expense/GetExpenses`,
  CreateExpenseUrl: `${httpPrefix}${mainDomain}/api/expense/CreateExpense`,
  UpdateExpenseUrl: `${httpPrefix}${mainDomain}/api/expense/UpdateExpense`,
  DeleteExpenseUrl: `${httpPrefix}${mainDomain}/api/expense/DeleteExpense`,

  // Quotes
  GetQuotesUrl: `${httpPrefix}${mainDomain}/api/quote/GetQuotes`,
  CreateQuoteUrl: `${httpPrefix}${mainDomain}/api/quote/CreateQuote`,
  UpdateQuoteUrl: `${httpPrefix}${mainDomain}/api/quote/UpdateQuote`,
  DeleteQuoteUrl: `${httpPrefix}${mainDomain}/api/quote/DeleteQuote`,
  SendQuoteUrl: `${httpPrefix}${mainDomain}/api/quote/SendQuote`,


  // Invoice
  PayThisInvoiceUrl: `${httpPrefix}${mainDomain}/api/invoice/PayThisInvoice`,
  GetInvoicesUrl: `${httpPrefix}${mainDomain}/api/invoice/GetInvoices`,
  CreateInvoiceUrl: `${httpPrefix}${mainDomain}/api/invoice/CreateInvoice`,
  UpdateInvoiceUrl: `${httpPrefix}${mainDomain}/api/invoice/UpdateInvoice`,
  DeleteInvoiceUrl: `${httpPrefix}${mainDomain}/api/invoice/DeleteInvoice`,
  GetDueInvoicesUrl: `${httpPrefix}${mainDomain}/api/invoice/GetDueInvoices`,
  SendInvoiceUrl: `${httpPrefix}${mainDomain}/api/invoice/SendInvoice`,

  //EMAIL CAMPAIGN
  getEmailJobsUrl: `${httpPrefix}${mainDomain}/api/EmailCampaign/GetEmailJobs`,
  getNextAvailableEnailJobtUrl: `${httpPrefix}${mainDomain}/api/EmailCampaign/GetNextAvailableJob`,
  sendEmailUrl: `${httpPrefix}${mainDomain}/api/EmailCampaign/SendEmail`,
  sendEmailPrivatePartyAccountUrl: `${httpPrefix}${mainDomain}/api/EmailCampaign/SendEmailPrivatePartyAccount`,


  mainDomain: `${httpPrefix}${mainDomain}`,

};

export const messages = {
  SuccessCreateCustomer: `Customer has been updated successfully`,
  SuccessUpdatedAdministrator: `Administrator has been updated successfully`,
  SuccessCreateAdministrator: `Administrator has been created successfully`,
  SuccessDeleteAdministrator: `Administrator has been deleted successfully`,
  SuccessUpdatedCustomer: `Customer has been created successfully`,
  SuccessDeletedCustomer: `Customer has been deleted successfully`,
  SuccessCreatedBooking: `The booking has been created successfully`,

  SuccessUpdatedCall: `Call has been updated successfully`,
  SuccessCreateCall: `Call has been created successfully`,
  SuccessDeleteCall: `Call has been deleted successfully`,
  SuccessCreatedCallNotes: `Call notes have been created successfully`,

  SuccessUpdatedShow: `Show has been updated successfully`,
  SuccessCreateShow: `Show has been created successfully`,
  SuccessDeleteShow: `Show has been deleted successfully`,

  SuccessUpdatedReminder: `Reminder has been updated successfully`,
  SuccessCreateReminder: `Reminder has been created successfully`,
  SuccessDeleteReminder: `Reminder has been deleted successfully`,

  SuccessUpdatedEvent: `Event has been updated successfully`,
  SuccessCreateEvent: `Event has been created successfully`,
  SuccessDeleteEvent: `Event has been deleted successfully`,

  SuccessUpdatedExpense: `Expense has been updated successfully`,
  SuccessCreateExpense: `Expense has been created successfully`,
  SuccessDeleteExpense: `Expense has been deleted successfully`,

  SuccessUpdatedQuote: `Quote has been updated successfully`,
  SuccessCreateQuote: `Quote has been created successfully`,
  SuccessSentQuote: `Quote has been sent successfully`,
  SuccessDeleteQuote: `Quote has been deleted successfully`,

  SuccessUpdatedInvoice: `Invoice has been updated successfully`,
  SuccessCreateInvoice: `Invoice has been created successfully`,
  SuccessSentInvoice: `Invoice has been sent successfully`,
  SuccessDeleteInvoice: `Invoice has been deleted successfully`,
  SuccessPaidInvoice: `Invoice has been paid successfully`,

  HasBeenMoved: 'The person has been moved from the school',
  AddressNotFound: 'Cannot find the email address. Email is not active anymore - from GMAIL',

  SuccessUpdatedHotel: `Hotel has been updated successfully`,
  SuccessCreateHotel: `Hotel has been created successfully`,
  SuccessDeleteHotel: `Hotel has been deleted successfully`,



};

export const customerTabs = {
  customerFoundTab: 0,
  customerEditTab: 1,
  customerNewTab: 2,

};

export const colorConstants = {
  dueDate: 2,
  nonDue: 1,
};

export const CustomerCallQueryID = {
  PerformancesAround: '12',
  ReshedulingProcess: '7',
  DateDiscussions: '6',
  GeneralEnquiry: '1',
  AllPerformances: '5',
};

export const EventTypeID = {
  Performance: 1,
  Travel: 2,
  Accomodation: 3,
  Flight: 4,
};

export const CallResultID = {
  GenralEnquiry: 1,
  Declined: 2,
  Closed: 3,
  PeriodicalMonitoring: 4,
  Performance: 5,
  DateDiscussion: 6,
  ReshedulingProcess: 7,
  Travel: 8,
  CustomerHasNotRead: 9,
  ConfirmedPerformance: 10,
  ReminderSent: 11,

};

export const CustomerCallOperationName = {
  Performances_around_today: 'Performances around today',
  AllPerformances: 'All performances in history',
  DateDiscussions: 'Date discussions',
  GeneralEnquiry: 'General enquires',
  ReshedulingProcess: 'Resheduling Process',

};

const CopperTealColorPallette =
{
  lightRed: '#efbeab',
  darkRed: '#de9e86',
  lightTeal: '#b1d2da',
  normalTeal: '#66a1af',
  darkTeal: '#3f656f',
}

export const TypeCustomer =
{
  school: 1,
  privateCustomer: 2,
  holidayProgramme: 3,
  inactiveSchools: 4,
  rejectedSchools: 5,
  monitoring: 6,
  client: 7,
  kindregarten: 8,

}

export const callResultsList = [
  { id: CallResultID.GenralEnquiry, Name: "Genral Enquiry" },
  { id: CallResultID.Declined, Name: "Declined" },
  { id: CallResultID.Closed, Name: "Closed" },
  { id: CallResultID.PeriodicalMonitoring, Name: "Periodical Monitoring" },
  { id: CallResultID.Performance, Name: "Performance" },
  { id: CallResultID.DateDiscussion, Name: "Date Discussion" },
  { id: CallResultID.ReshedulingProcess, Name: "Resheduling Process" },
  { id: CallResultID.Travel, Name: "Travel" },
  { id: CallResultID.CustomerHasNotRead, Name: "Customer has not read" },
  { id: CallResultID.ConfirmedPerformance, Name: "Confirmed Performance" },
  { id: CallResultID.ReminderSent, Name: "Reminder sent" },
];

export const typeEventList = [
  //{ id: EventTypeID.Performance, Name: "Performance" },
  { id: EventTypeID.Travel, Name: "Travel" },
  { id: EventTypeID.Accomodation, Name: "Accomodation" },
  { id: EventTypeID.Flight, Name: "Flight" },
];


export const Position =
{
  principal: 1,
  deputyPrincipal: 2,
  associatePrincipal: 3,
  artTeacher: 4,
  teacher: 5,
  teamLeader: 6,
  coordinator: 7,
  inactive: 8,
  officeManager: 9,
  assistPricipal: 10,
}

const PaleTealColorScheme =
{
  Teal: '#008080',
  Verdigris: '#35B5AC',
  MiddleBlueGreen: '#89D5D2',
  PaleBlue: '#BBF1F1',
  RosyBrown: '#C09591',
  DeepPuce: '#A86561',

}
const FeminineTealAndPinkColorScheme =
{
  TealGreen: '#008581',
  PolishedPine: '#4C9C8B',
  TealDeer: '#96E4B0',
  MimiPink: '#FFDAE8',
  PaleMagentaPink: '#FE99CB',
  RaspberryPink: '#E4509A',
}

const TealGoldColorPalette =
{
  TealBlue: '#37abb4',
  TealGreen: '#7ed2bb',
  RedOrange: '#f49e89',
  DarkBrown: '#585252',
  Orange: '#d49e4a',

}

const lightGray = '#D3D3D3';
const darkGray = '#A9A9A9';
const red = '#BD2031';
export const generalControlColor = PaleTealColorScheme.Teal;

export const TealSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: generalControlColor,
    },
    '&$checked + $track': {
      backgroundColor: generalControlColor,
    },
  },
  checked: {},
  track: {},
})(Switch);



export const Colors = {
  RedColor: red,
  mainBackgroundColor: CopperTealColorPallette.normalTeal,
  customerCallCellColor: CopperTealColorPallette.lightTeal,
  secondBackgroundColor: CopperTealColorPallette.lightTeal,
  dangerBackgroundColor: CopperTealColorPallette.darkRed,
  brownBackgroundColor: '#d49e4a',

  principalColor: FeminineTealAndPinkColorScheme.RaspberryPink,
  deputyPrincipalColor: TealGoldColorPalette.Orange,
  teacherColor: FeminineTealAndPinkColorScheme.TealGreen,
  officeManagerColor: FeminineTealAndPinkColorScheme.TealDeer,
  teamLeaderColor: FeminineTealAndPinkColorScheme.PolishedPine,
  associatePrincipalColor: TealGoldColorPalette.RedOrange,
  inactivePositionColor: lightGray,

  clientCustomerColor: FeminineTealAndPinkColorScheme.PolishedPine,
  rejectedCustomerColor: TealGoldColorPalette.RedOrange,
  privateCustomerCustomerColor: FeminineTealAndPinkColorScheme.TealGreen,

  closedCallColor: lightGray,
  confirmedPerformanceCallColor: PaleTealColorScheme.Teal,
  performanceCallColor: PaleTealColorScheme.Verdigris,
  DateDiscussionColor: PaleTealColorScheme.RosyBrown,
  ReshedulingProcessColor: PaleTealColorScheme.RosyBrown,
  periodicalMonitoringColor: PaleTealColorScheme.PaleBlue,
  generalEnquiryColor: PaleTealColorScheme.MiddleBlueGreen,
  travelColor: darkGray,
  deleteColor: TealGoldColorPalette.RedOrange,
  showColor: TealGoldColorPalette.DarkBrown,
}

export const outBoundCallNoteColor = CopperTealColorPallette.lightTeal;
export const inBoundCallNoteColor = CopperTealColorPallette.lightRed;

export const validateEmail = (email: string) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const googleApiUrl = "https://www.google.com/maps/place/";

export const redirectToGoogleMap = (gps: string) => {
  if (gps) {
    window.open(googleApiUrl + gps, "_blank");
  }
}




export const replaceCrChars = (inputStr: string | undefined) => {
  let inpStr = inputStr as string;
  return inpStr.replace(/(\r\n|\n|\r)/g, "<br />");
}


export const copyToClipBoard = (valueToCopy: string) => {
  const el = document.createElement("textarea");
  el.value = valueToCopy;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
}

export const CreateDate4ControlwithValue = (value: Date) => {

  let dateTime = moment(value).format('YYYY-MM-DDTHH:mm');
  return dateTime;

}

export const CreateTodayDate4ControlPlus1hour = (): string => {

  let dateTime = moment().add(1, 'hours').format('YYYY-MM-DDTHH:mm');
  return dateTime;

}
export const CreateTodayDate4Control = (): string => {
  //let dd = moment().format('MMMM Do YYYY, h:mm:ss a');
  let dd = moment().format('YYYY-MM-DDTH:mm');

  dd = moment().format('YYYY-MM-DDTHH:mm');

  //dd = moment().format();

  /*  let todayDate = new Date();
  var day = todayDate.getDate();
  var month = todayDate.getMonth() + 1;
  var year = todayDate.getFullYear();
  var hour = todayDate.getHours();
  var minutes = todayDate.getMinutes();

  let monthString = month.toString();
  if (monthString.length === 1)
    monthString = '0' + monthString;

  let sssss = year.toString() + '-' + monthString + '-' + day.toString() + 'T' +hour.toString() + ':' +minutes.toString();
    */
  //return "2020-10-24T09:30";
  return dd;
  return "2020-10-24T23:30";

}



export const CreateStringDate = (passsedValue: Date): string => {
  let dd = moment(new Date(passsedValue)).format('MM/DD/YYYY H:mm');
  /* let passsedDate = new Date(passsedValue);
  var day = passsedDate.getDate();
  var month = passsedDate.getMonth() + 1;
  var year = passsedDate.getFullYear();

  var hour = passsedDate.getHours();     // yields hours 
  var minute = passsedDate.getMinutes(); // yields minutes

  // After this construct a string with the above results as below

  let fff =  month + "/" + day + "/" + year + " " + hour + ':' + minute + ':00';
  let ggg = '231231231213'; */
  return dd;

}
export const CreateDashStringDate = (passsedValue: Date): string => {
  return moment(new Date(passsedValue)).format('DD-MM-YYYY');
}


export const convertGPSCoordinates = (value: string): ICoordinates => {

  let splitedGps = value.split(', ');
  if (splitedGps?.length === 1) {
    splitedGps = value.split(' ');
  }

  if (splitedGps.length === 1) {
    splitedGps = value.split(',');
  }

  let lat = isNaN(Number(splitedGps[0])) ? -36.849 : Number(splitedGps[0]);
  let long = isNaN(Number(splitedGps[1])) ? 174.763 : Number(splitedGps[1]);
  return { lat: lat, lng: long }

};


export const LightenDarkenColor = (col: any, amt: any) => {
  var usePound = false;
  if (col[0] == '#') {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

export const isBlank = (str: string | undefined): boolean => {
  return (!str || /^\s*$/.test(str));
}

export default urls;
