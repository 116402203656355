import React, { useContext, useState, useEffect } from 'react';

import { handleResponse } from '../autorization/handleResponse';
import {
  makeStyles,
  Grid,
  CircularProgress,

} from '@material-ui/core';

import { GeneralCard } from '../general/GeneralCard';
import { Colors, LightenDarkenColor, isBlank } from '../Utils';
import { withRouter } from 'react-router';
import BackToCustomers from '../general/BackToCustomers';
import { GeneralAddButton } from '../general/GeneralButton';
import { ToastContainer, toast } from 'react-toastify';
import { QuoteContextUseReducer } from './QuoteContext';
import { IQuoteCardData, IQuote } from '../interfaces/IQuote';
import { Types } from './QuoteReducer';
import { fetchProgramList, fetchQuotesAPI } from '../services/DataServices';
import Moment from 'react-moment';
import QuoteEdit from './QuoteEdit';
import { IProgram } from '../interfaces/IProgram';

const useStyles = makeStyles({
  root: {},
  wrapper: {
    marginTop: '20px',
  },
  gray: {
    backgroundColor: 'lightgray',
  },
  generalButton: {
    background: Colors.mainBackgroundColor,
    '&:hover': {
      color: 'black',
      background: LightenDarkenColor(Colors.mainBackgroundColor, 40),
    },
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 1,
  },
});

const QuotePresenter = () => {

  const quoteObject = useContext(QuoteContextUseReducer);


  const [quotes, SetQuotes] = useState<IQuoteCardData[]>([]);
  const [programList, SetProgramList] = useState<IProgram[]>([]);
  const [isLoading, SetIsLoading] = useState(false);
  const classes = useStyles();
  const SetMyQuotes = (passedQuotes: IQuote[]) => {

    let quote_1 = passedQuotes.map((v) => {
      return { quote: v };
    }) as IQuoteCardData[];

    SetQuotes(quote_1.sort((a, b) => ((a.quote?.issueDate as Date) < (b.quote?.issueDate as Date) ? 1 : -1)));
    SetIsLoading(false);
  };

  const addNewQuote = () => {
    quoteObject.dispatch({ type: Types.SET_QUOTE_EDIT_VISIBLE, payload: { EditVisible: true }, });
    quoteObject.dispatch({ type: Types.SET_QUOTE_FOUND_VISIBLE, payload: { FoundVisible: false }, });
    quoteObject.dispatch({ type: Types.SET_CURRENT_QUOTE, payload: { selectedQuote: {} }, });
    quoteObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: false }, });

    quoteObject.dispatch({ type: Types.SET_OPEARTION_DISABLED, payload: { operationDisabled: true }, });
    quoteObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: true }, });
    quoteObject.dispatch({ type: Types.SET_IS_DIRTY, payload: { isDirty: false }, });
  };


  const showSuccessMessage = (value: string, success: boolean) => {
    if (success)
      toast.success(value, { autoClose: 6000 });
    else
      toast.error(value, { autoClose: 6000 });
  }

  const getPrograms = async () => {
    try {
      const response = await fetchProgramList();
      response
        .json()

        .then((data: IProgram[]) => {
          console.log(data);
          SetProgramList(data);
        });
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    SetIsLoading(true);

    const fetchReminders = async () => {
      try {
        console.log('fetch quote');
        await fetchQuotesAPI()
          .then(handleResponse)
          .then((rowData) => SetMyQuotes(rowData));
      } catch (e) {
        console.log(e);
      }
    };

    getPrograms();
    fetchReminders();
  }, [
    quoteObject?.state.quote.needToUpdate,
  ]);

  useEffect(() => {
    if (!isBlank(quoteObject.state.quote.operationOutcome.message))
      showSuccessMessage(quoteObject.state.quote.operationOutcome.message, true);
  }, [quoteObject.state.quote.operationOutcome]);

  const handleClick = (passedBackQuote: IQuoteCardData) => {
    quoteObject.dispatch({ type: Types.SET_CURRENT_QUOTE, payload: { selectedQuote: passedBackQuote }, });
    quoteObject.dispatch({ type: Types.SET_QUOTE_EDIT_VISIBLE, payload: { EditVisible: true }, });
    quoteObject.dispatch({ type: Types.SET_QUOTE_FOUND_VISIBLE, payload: { FoundVisible: false }, });
    quoteObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: false } });
    quoteObject.dispatch({ type: Types.SET_IS_DIRTY, payload: { isDirty: false } });
    quoteObject.dispatch({ type: Types.UPDATE_QUOTE_FLAG, payload: { needToUpdate: false }, });
    quoteObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: false }, });
    quoteObject.dispatch({ type: Types.SET_OPEARTION_DISABLED, payload: { operationDisabled: false }, });
  };

  return (
    <div>
      <ToastContainer autoClose={6000} />

      <div className="mt-1 mb-1">
        {quoteObject.state.quote.setShowNewButton ? (
          <div>
            <GeneralAddButton
              onClick={addNewQuote}
            />
          </div>
        ) : null}
      </div>

      {quoteObject.state.quote.FoundVisible ? (
        <Grid container className={classes.root} spacing={1}>
          {!quotes ||
            quotes.map((qt) => (
              <Grid key={qt.quote?.quoteID} item sm={4} xs={12}>                {/*   <AdministratorCard admin={adm.admin} /> */}

                <GeneralCard
                  entity={qt}
                  onSelect={handleClick}
                  defaultBackgroundColor={'#66a1af'}
                  entityId={
                    qt.quote?.quoteID === undefined ? 0 : qt.quote?.quoteID
                  }
                  children={() => (
                    <div>
                      <b>{qt.quote?.customerName}</b>
                      <div>
                      <span>Issue date: </span>
                      <b>
                        <Moment format="DD MMMM, YYYY HH:mm">
                          {qt.quote?.issueDate}
                        </Moment>
                      </b>
                      </div>
                    </div>
                  )}
                />
              </Grid>
            ))}
        </Grid>
      ) : null}



      {isLoading ? <CircularProgress className="centered" /> : null}

      {quoteObject.state.quote.EditVisible ? (
        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={12}>
            <QuoteEdit
              programList={programList}
              quote={
                quoteObject?.state.quote.selectedQuote?.quote == null
                  ? undefined
                  : quoteObject?.state.quote.selectedQuote?.quote
              }
            />
          </Grid>
        </Grid>
      ) : null}


    </div>
  );
};

export default QuotePresenter;
