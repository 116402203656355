import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';

import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useForm } from 'react-hook-form';
import { authenticationService } from './authentication.service';
import { useHistory } from "react-router-dom";
import GeneralButton from '../general/GeneralButton';

export interface ILogin {
  userName?: string;
  password?: string;
  
}


const useStyles = makeStyles((theme: any) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const classes = useStyles();

  const [initialValues] = useState<ILogin>({} );
  //const [initialValues] = useState<ILogin>({userName:'vasya', password:'Atasha@1306'} );

  const { register, handleSubmit, errors } = useForm(
    {
      defaultValues: initialValues,
    }
  
  );
  let history = useHistory();

  const [error, SetError] = useState(false);


  const onSubmit =  async (data: ILogin) => {
    let username : string = data.userName!; 
    let password : string = data.password!; 
    authenticationService.login(username, password).then(
       user => {
  
        const { from } = history.location.state as any || { from: { pathname: "/" }};
        
        
        history.push("/");
        
        console.log("USER" + user);
      },
      error => {
        console.log(error);
        SetError(true);
      },
    );
   
  };








  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form
          className={classes.form}
          
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            variant="outlined"
            margin="normal"
            inputRef={register({ required: true, maxLength: 30 })}
            
            fullWidth
            id="userName"
            label="Username"
            name="userName"
            autoComplete="email"
            autoFocus
          />
          <small className="red">{errors.userName && 'Username is required'} </small>
          <TextField
            variant="outlined"
            margin="normal"
            inputRef={register({ required: true, maxLength: 30 })}
            
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <small className="red">{errors.password && 'Password is required'} </small>
          <small  className={error ? '' : 'hidden'} >Invalid Username/Password</small>
          <GeneralButton
            type="submit"
            content='Sign In'
            className='full-width'
          />
            
          
        </form>
      </div>
    </Container>
  );
}
