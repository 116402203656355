import React, {  useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';

import BackToCustomers from '../general/BackToCustomers';
import { ExpenseProviderUseReducer } from './ExpenseContext';
import ExpenseCommander from './ExpenseCommander';
import ExpensePresenter from './ExpensePresenter';

const useStyles = makeStyles({
  root: {},
  margin_top_10:
  {
    width: '95%',
    marginTop: '1em',
    margin: '0 auto',
  },

  wrapper: {
    width: '95%',
    margin: '0 auto',
    //backgroundColor: 'red',
  },
  gray: {
    backgroundColor: 'lightgray',
  },

  yellow: {
    backgroundColor: '#F6D55C',
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 1,
  },
});


const Expense = () => {

  const classes = useStyles();


  return (
    <div>
       <ExpenseProviderUseReducer>

       <div className={classes.margin_top_10}>
            <Grid container spacing={1}>
                <Grid item xs={5}>
                  <div className="left">
                  <BackToCustomers />
                  </div>
                </Grid>
              </Grid>
          </div>

        
          <div className={classes.wrapper}>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <h3 className="left">Business Expenses</h3>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <div className="left">
                    <ExpenseCommander />
                  </div>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <ExpensePresenter />
              </Grid>
            </Grid>
          </div>
      </ExpenseProviderUseReducer>
    </div>
  );
};

export default Expense;
