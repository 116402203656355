
import { authenticationService } from "./authentication.service";

export function authHeader() : any {
    // return authorization header with jwt token
    
    
       
        return { 
             //  Authorization: `Bearer ${currentUser.token}`, 
               Accept: 'application/json',
               Api_Key: `${authenticationService.currentApiKey}`, 
              'Content-Type': 'application/json' };
    
}
export function authHeaderClientCalendar() : any {
    // return authorization header with jwt token
    
    
       
        return { 
             //  Authorization: `Bearer ${currentUser.token}`, 
               Accept: 'application/json',
               Api_Key: `Parastasik123456`, 
              'Content-Type': 'application/json' };
    
}