import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';

import {
  fetchCustomerAroundPerformancesCustomerCalls, fetchEventsAPI,

} from '../services/DataServices';

import {
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  Inject,
  ResourceDirective,
  ResourcesDirective,
  ResourceDetails,
  ResourcesModel,
} from '@syncfusion/ej2-react-schedule';
import { handleResponse } from '../autorization/handleResponse';
import { ICustomerCall } from '../interfaces/ICustomerCall';
import { IEvent } from '../interfaces/IEvent';
import { Colors } from '../Utils';

interface IScheduleData {
  Id: number;
  Subject: string;
  StartTime: Date;
  EndTime: Date;
  isAllDay: boolean;
  Status: string;
  Location: string;
  Priority: string;
  ResourceId: number;
}


export const CalendarScheduler = () => {

  const location = useLocation();
  const [path] = useState(location.pathname);

  const [data, SetData] = useState<IScheduleData[]>([]);
  const [isLoading, SetIsLoading] = useState(false);

  const [startDate] = useState<string>(
    moment(new Date()).format('MM/DD/YYYY H:mm')
  );

  const resourceDataSource: Object[] = [
    {Name: 'Performance', Id:1, Color: Colors.RedColor},
    {Name: 'Travel', Id:2, Color: Colors.showColor},
    {Name: 'Accomodation', Id:3, Color: Colors.teamLeaderColor},

  ];



  const SetMyCustomerCalls = (passedCustomers: ICustomerCall[]) => {

    

    var result = passedCustomers.map(data => ({
      Id: data.callID,
      StartTime: data.dueDate,
      //EndTime: new Date(new Date(data.dueDate).getFullYear(), new Date(data.dueDate).getMonth(), new Date(data.dueDate).getDate(), new Date(data.dueDate).getHours() + 1, new Date(data.dueDate).getMinutes()), 
      EndTime: new Date(new Date(data.dueDate).setHours(new Date(data.dueDate).getHours() + 1)),
      Location: data.gpS_COORDINATES,
      //EndTime: moment().add(1, 'hours').format('YYYY-MM-DDTHH:mm'), 
      Subject: data.customerName,
      ResourceId: 1
      
    }) as IScheduleData);
    SetData(result);
    SetIsLoading(false);
    

  };

  const SetMyEvents = (passedEvents: IEvent[]) => {
    
     if (path === '/clients')
     {
    var result = passedEvents.map(data => ({
      Id: data.eventID,
      StartTime: data.startTime,
      EndTime: data.endTime,
      Location: '',
      Subject: 'Not available',
      //Subject: 'fdg',
      ResourceId: data.typeID,
      
      //ResourceId: 3
      
    }) as IScheduleData);
  } else 
  {
    var result = passedEvents.map(data => ({
      Id: data.eventID,
      StartTime: data.startTime,
      EndTime: data.endTime,
      Location: data.destination,
      Subject: data.typeID === 1 ? data.description + ', '+ (data.callNote === undefined ? '' : data.callNote)        : data.notes,
      ResourceId: data.typeID,
      
      //ResourceId: 3
      
    }) as IScheduleData);

  }


    SetData(result);
    SetIsLoading(false);

  };


 




  useEffect(() => {
    const fetchPerformances = async () => {
      try {
        await fetchCustomerAroundPerformancesCustomerCalls(12)
          .then(handleResponse)
          .then((rowData) => SetMyCustomerCalls(rowData));
      } catch (e) {
        console.log(e);
      }
    };

    const fetchEvents = async (startDate: string) => {
      try {
        await fetchEventsAPI(startDate, path != '/clients')
          .then(handleResponse)
          .then((rowData) => SetMyEvents(rowData));
      } catch (e) {
        console.log(e);
      }
    };

    fetchEvents(startDate);

    
    SetIsLoading(true);
    //fetchPerformances();



  }, [])


  return (
    <div className="full-width-height left">
      {isLoading ? <CircularProgress className="centered" /> : null}
      {!isLoading ? (<ScheduleComponent
        currentView='Month'
        firstDayOfWeek={1}
        height='100%'
        eventSettings={{ dataSource: data }}
      
      >
        <Inject services={[Day, Week, WorkWeek, Month, Agenda]}
        />
         <ResourcesDirective>
         <ResourceDirective field='ResourceId' title='Resource Name' name='Resources' textField='Name' idField='Id' colorField='Color'
            dataSource={resourceDataSource}></ResourceDirective>
        </ResourcesDirective> 
      </ScheduleComponent>
      ) : null}
    </div>

  );
};
