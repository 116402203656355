import React, { createContext, useReducer, Dispatch } from "react";
import { QuoteDataType, QuoteActions, quoteReducer } from "./QuoteReducer";
import { IChildrenProp } from "../interfaces/IChildren";

type InitialStateType = {
  quote: QuoteDataType;
};

const initialState = {
  quote: { 
      selectedQuote: undefined, 
      needToUpdate: false,
      FoundVisible: true,
      EditVisible: false,
      setShowNewButton: true,
      isNew: true,
      operationDisabled: false,
      operationOutcome: {message: '', success: true},
      isDirty: false,
    },
};

const QuoteContextUseReducer = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<QuoteActions>;
}>({
  state: initialState,
  dispatch: () => null
});

const mainReducer = (
  { quote  }: InitialStateType,
  action: QuoteActions 
) => ({
  quote: quoteReducer(quote, action),
});

const QuoteProviderUseReducer: React.FC<IChildrenProp> = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <QuoteContextUseReducer.Provider value={{ state, dispatch }}>
      {children}
    </QuoteContextUseReducer.Provider>
  );
};

export { QuoteProviderUseReducer, QuoteContextUseReducer };