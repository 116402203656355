
import { ActionMap } from "../general/ActionMap";
import { ICustomerCall } from "../interfaces/ICustomerCall";

export enum Types {
  SET_QUERY_ID = 'SET_QUERY_ID',
  SET_OPERATION = 'SET_OPERATION',
  SET_CURRENT_CUSTOMER_CALL = 'SET_CURRENT_CUSTOMER_CALL',
  SET_PRESENTER_VISIBLE = 'SET_PRESENTER_VISIBLE',
  SET_NEED_TO_REFRESH = 'SET_NEED_TO_REFRESH',
  SET_FILTER_TYPE = 'SET_FILTER_TYPE',

}

export type CustomerCallDataType = {
  queryID: string;
  operation: string;
  currentCustomerCall: ICustomerCall | undefined;
  presenterVisible: boolean;
  needToRefresh: boolean;
  filterType: string;

};

type CustomerCallPayload = {
  [Types.SET_QUERY_ID]: {
    queryID: string;
  };
  [Types.SET_OPERATION]: {
    operation: string;
  };
  [Types.SET_CURRENT_CUSTOMER_CALL]: {
    currentCustomerCall: ICustomerCall | undefined;
  };
  [Types.SET_PRESENTER_VISIBLE]: {
    presenterVisible: boolean;
  };
  [Types.SET_NEED_TO_REFRESH]: {
    needToRefresh: boolean;
  };
  [Types.SET_FILTER_TYPE]: {
    filterType: string;
  };
//  
  
  
};

export type CustomerCallActions = ActionMap<CustomerCallPayload>[keyof ActionMap<
  CustomerCallPayload
>];

export const customerCallReducer = (state: CustomerCallDataType, action: CustomerCallActions) => {
  switch (action.type) {
    case Types.SET_QUERY_ID:
      return {
        ...state,
        queryID: action.payload.queryID,
      };
      case Types.SET_OPERATION:
        return {
          ...state,
          operation: action.payload.operation,
        };

        case Types.SET_CURRENT_CUSTOMER_CALL:
          return {
            ...state,
            currentCustomerCall: action.payload.currentCustomerCall,
          };
          case Types.SET_PRESENTER_VISIBLE:
            return {
              ...state,
              presenterVisible: action.payload.presenterVisible,
            };

            case Types.SET_NEED_TO_REFRESH:
              return {
                ...state,
                needToRefresh: action.payload.needToRefresh,
              };            
              case Types.SET_FILTER_TYPE:
                return {
                  ...state,
                  filterType: action.payload.filterType,
                };   
                                  

                    
    default:
      return state;
  }
};


