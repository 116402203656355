import React from 'react'
import { withRouter } from 'react-router';
import { BackToEntityButton } from './GeneralButton';
  
 const BackToCustomers = (props: any) => {
    const { history } = props;
    
    return (
        <div>
        <BackToEntityButton 
            content='CUSTOMERS'
            onClick={() => history.push('/customers')}
        />
            
        </div>
    )
}
export default withRouter(BackToCustomers);