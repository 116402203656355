import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import {
  messages,
  Colors,
  generalControlColor,
  CreateStringDate,
  LightenDarkenColor,
  isBlank,
  CreateTodayDate4Control,
  typeEventList,
  CreateTodayDate4ControlPlus1hour,
} from '../Utils';
import {
  Theme,
  makeStyles,
  createStyles,
  Switch,
  withStyles,
} from '@material-ui/core';

import 'react-toastify/dist/ReactToastify.css';

import {
  Grid,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from '@material-ui/core';

import { useForm, Controller } from 'react-hook-form';

import { handleResponse } from '../autorization/handleResponse';

import GeneralButton, {
  GeneralAddButton,
  GeneralDeleteButton,
} from '../general/GeneralButton';

import ConfirmationDialog from '../general/ConfirmationDialog';
import { IEventCardData, IEvent } from '../interfaces/IEvent';
import { EventContextUseReducer } from './EventContext';
import { Types } from './EventManagerReducer';
import {
  createEventAPICall,
  updateEventAPICall,
  deleteCallAPICall,
  deleteEventAPICall,
} from '../services/DataServices';

const TealSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: generalControlColor,
    },
    '&$checked + $track': {
      backgroundColor: generalControlColor,
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      margin: theme.spacing(1),
    },
    generalButton: {
      background: Colors.mainBackgroundColor,
      '&:hover': {
        color: 'black',
        background: LightenDarkenColor(Colors.mainBackgroundColor, 40),
      },
    },

    alignedRight: {
      float: 'right',
    },
    bold: {
      fontWeight: 'bold',
    },

    innerForm: {
      padding: '10px',
    },
  })
);

const EventEdit = (props: IEventCardData) => {
  const eventObject = useContext(EventContextUseReducer);

  const emptyEvent: IEvent = {
    eventID: 0,
    description: '',
    startTime: new Date(),
    endTime: new Date(),
    hotelID: undefined,
    calendarEventId: '',
    callNote: '',
    typeID: 2,
  };

  const deleteEventHandler = () => {
    SetOpenConfirmationDialog(true);
  };

  useEffect(() => {
    eventObject.dispatch({ type: Types.SET_HOTEL_VISIBLE, payload: { hotelDropdownVisible: false }, });
    if (props.event?.typeID === 3) {
      eventObject.dispatch({ type: Types.SET_HOTEL_VISIBLE, payload: { hotelDropdownVisible: true }, });
    } 
  }, []);

  const [eventTypeID, SetEventTypeID] = useState<number>(
    props.event?.typeID === undefined ? 2 : props.event?.typeID
  );

  const handleChangeEventType = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const values = getValues();
    values.typeID = Number(event.target.value as string);
    setValue('typeID', values.typeID);
    SetEventTypeID(values.typeID);
    console.log(values.typeID);
    if (values.typeID === 3)
      eventObject.dispatch({ type: Types.SET_HOTEL_VISIBLE, payload: { hotelDropdownVisible: true }, });
    else
      eventObject.dispatch({ type: Types.SET_HOTEL_VISIBLE, payload: { hotelDropdownVisible: false }, });
  };

  const handlingNewEvent = () => {
    eventObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: true } });
  };

  const [openConfirmationDialog, SetOpenConfirmationDialog] = useState<boolean>(
    false
  );


  const [isLoading, SetIsLoading] = useState<boolean>(false);

  const [initialValues] = useState<IEvent | {}>(
    props.event == null ? {} : props.event
  );

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    errors,
    getValues,
    setValue,
  } = useForm<IEvent>({
    defaultValues: initialValues,
  });

  const handleNewEventEvent = () => {
    reset(emptyEvent);
    eventObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: true } });
    eventObject.dispatch({ type: Types.SET_OPEARTION_DISABLED, payload: { operationDisabled: false }, });
  };

  const onSubmit = async (data: IEvent) => {
    data.eventID = eventObject.state.event.selectedEvent?.event?.eventID;
    data.typeID = eventTypeID;
    data.calendarEventId = eventObject.state.event.selectedEvent?.event?.calendarEventId;

    if (data.typeID != 3) {
      data.hotelID = undefined; // erase hotel if not ACCOMODATION
    }
    eventObject.dispatch({ type: Types.SET_OPEARTION_DISABLED, payload: { operationDisabled: true }, });
    SetIsLoading(true);
    if (eventObject.state.event.isNew) CreateEvent(data);
    else UpdateEvent(data);
    
    if (props.onComplete != undefined)
      props.onComplete();
     
  };

  const CreateEvent = async (event: IEvent) => {
    await createEventAPICall(event)
      .then(handleResponse)
      .then(
        (eventID) => {
          eventObject.dispatch({ type: Types.SET_CURRENT_EVENT, payload: { selectedEvent: {} }, });
          eventObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: true, message: messages.SuccessCreateEvent, }, }, });
          eventObject.dispatch({ type: Types.SET_EVENT_EDIT_VISIBLE, payload: { EditVisible: false }, });
          eventObject.dispatch({ type: Types.SET_EVENT_FOUND_VISIBLE, payload: { FoundVisible: true }, });
          let flagToChange = eventObject.state.event.needToUpdate;
          eventObject.dispatch({ type: Types.UPDATE_EVENT_FLAG, payload: { needToUpdate: !flagToChange }, });

          SetIsLoading(false);
        },
        (error) => {
          SetIsLoading(false);
          eventObject.dispatch({
            type: Types.SET_OUTCOME,
            payload: { operationOutcome: { success: false, message: error } },
          });
        }
      );
  };
  const UpdateEvent = async (event: IEvent) => {
    await updateEventAPICall(event)
      .then(handleResponse)
      .then(
        (result) => {
          eventObject.dispatch({
            type: Types.SET_OUTCOME,
            payload: {
              operationOutcome: {
                success: true,
                message: messages.SuccessUpdatedEvent,
              },
            },
          });
          eventObject.dispatch({
            type: Types.SET_EVENT_EDIT_VISIBLE,
            payload: { EditVisible: false },
          });
          eventObject.dispatch({
            type: Types.SET_EVENT_FOUND_VISIBLE,
            payload: { FoundVisible: true },
          });
          let flagToChange = eventObject.state.event.needToUpdate;
          eventObject.dispatch({
            type: Types.UPDATE_EVENT_FLAG,
            payload: { needToUpdate: !flagToChange },
          });

          SetIsLoading(false);
        },
        (error) => {
          SetIsLoading(false);
          eventObject.dispatch({
            type: Types.SET_OUTCOME,
            payload: { operationOutcome: { success: false, message: error } },
          });
        }
      );
  };

  const DeleteEvent = async (eventToDelete: IEvent) => {
    await deleteEventAPICall(eventToDelete)
      .then(handleResponse)
      .then(
        (result) => {
          eventObject.dispatch({
            type: Types.UPDATE_EVENT_FLAG,
            payload: { needToUpdate: true },
          });
          eventObject.dispatch({
            type: Types.SET_EVENT_FOUND_VISIBLE,
            payload: { FoundVisible: true },
          });
          eventObject.dispatch({
            type: Types.SET_EVENT_EDIT_VISIBLE,
            payload: { EditVisible: false },
          });
          eventObject.dispatch({
            type: Types.SET_OUTCOME,
            payload: {
              operationOutcome: {
                success: true,
                message: messages.SuccessDeleteEvent,
              },
            },
          });
        },
        (error) => {
          eventObject.dispatch({
            type: Types.SET_OUTCOME,
            payload: { operationOutcome: { success: false, message: error } },
          });
        }
      );
  };

  const handleConfirmationDeleteDialog = (event: boolean) => {
    SetOpenConfirmationDialog(false);
    if (event) {
      DeleteEvent(eventObject.state.event.selectedEvent?.event as IEvent);
    }
  };

  const classes = useStyles();

  return (
    <div>
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={true}
          content="Are you sure you like to delete this event ?"
          onResult={(event) => handleConfirmationDeleteDialog(event)}
        />
      ) : null}

      {isLoading ? <CircularProgress className="centered" /> : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        *{' '}
        <input
          type="hidden"
          name="typeID"
          defaultValue=""
          ref={register}
        ></input>
        <Grid container spacing={1}>
          <Grid item sm={6} xs={12}>
            <TextField
              inputRef={register({ required: false })}
              className="full-width"
              name="startTime"
              id="datetime-local"
              label="Start Time"
              type="datetime-local"
              defaultValue={CreateTodayDate4Control()}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              inputRef={register({ required: false })}
              className="full-width"
              name="endTime"
              id="datetime-local"
              label="End Time"
              type="datetime-local"
              defaultValue={CreateTodayDate4ControlPlus1hour()}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <InputLabel>Type of event</InputLabel>
            <Select
              className="full-width"
              onChange={handleChangeEventType}
              value={eventTypeID}
            >
              {typeEventList.map((res) => (
                <MenuItem key={res.id} value={res.id}>
                  {res.Name}
                </MenuItem>
              ))}
            </Select>

            {/*  <FormControl className="full-width">
              <InputLabel>Type event</InputLabel>

              <Controller
                as={
                  <Select
                  >
                    {typeEventList.map((res) => (
                      <MenuItem key={res.id} value={res.id}>
                        {res.Name}
                      </MenuItem>
                    ))}
                  </Select>
                }
                name="typeID"
                rules={{ required: false }}
                control={control}
              />
            </FormControl> */}
          </Grid>
          <Grid item sm={6} xs={12}>
            {eventObject.state.event.hotelDropdownVisible ? (
              <FormControl className="full-width">
                <InputLabel>Hotel</InputLabel>

                <Controller
                  as={
                    <Select>
                      {!props.hotelList ||
                        props.hotelList.map((htl) => (
                          <MenuItem key={htl.hotelID} value={htl.hotelID}>
                            {htl.name}
                          </MenuItem>
                        ))}
                    </Select>
                  }
                  name="hotelID"
                  rules={{ required: false }}
                  control={control}
                />
              </FormControl>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="notes"
              className="full-width"
              label="Notes"
              multiline
              inputRef={register({ required: false })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="description"
              className="full-width"
              label="Description"
              multiline
              inputRef={register({ required: false })}
            />
          </Grid>
        </Grid>
        <div className="mt-1">
          <Grid container spacing={2}>
            <Grid item sm={3} md={3} xs={12}>
              <GeneralButton
                className="full-width"
                type="submit"
                content="update"
              />
            </Grid>
            {props.renderNewDeleteButtons === undefined ? (
              <Grid item sm={1} md={1} xs={12}>
                <GeneralAddButton
                  type="reset"
                  onClick={handlingNewEvent}
                  size="small"
                />
              </Grid>
            ) : null}

            {props.renderNewDeleteButtons === undefined ? (
              <Grid item sm={2} md={1} lg={1} xs={12}>
                <GeneralDeleteButton
                  content="delete"
                  onClick={deleteEventHandler}
                />
              </Grid>
            ) : null}
          </Grid>
        </div>
        <Grid container spacing={1}></Grid>
      </form>
    </div>
  );
};

export default EventEdit;
