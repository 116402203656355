import AddIcon from '@material-ui/icons/Add';
import { AdministratorContextUseReducer } from './AdministratorContext';
import React, { useContext, useState, useEffect } from 'react';
import { messages, validateEmail, copyToClipBoard, Colors, LightenDarkenColor } from '../Utils';
import { Theme, makeStyles, createStyles, Fab } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import 'react-toastify/dist/ReactToastify.css';

import { Grid, TextField, Button,  Select, MenuItem, InputLabel, CircularProgress, } from '@material-ui/core';

import { useForm,  useFieldArray } from 'react-hook-form';

import { makeAdminInactiveAPICall, createAdminAPICall, updateAdminAPICall, createAdminEmailsAPICall, deleteAdminAPICall, } from '../services/DataServices';
import { handleResponse } from '../autorization/handleResponse';

import { IAdministrator, IAdministratorCardData, } from '../interfaces/IAdministrator';
import { IAdminEmail } from '../interfaces/IAdminEmail';

import { CustomerContextUseReducer } from '../Customer/CustomerContext';
import { Types } from './AdministratorReducer';
import GeneralButton, { GeneralAddButton, GeneralDeleteButton } from '../general/GeneralButton';
import ConfirmationDialog from '../general/ConfirmationDialog';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    dirty_state:
    {
      borderColor: 'red',
      borderStyle: 'solid',
      borderWidth: '2px',
      padding: '5px',
    },
    new_state:
    {
      borderColor: 'green',
      borderStyle: 'solid',
      borderWidth: '2px',
      padding: '5px',
    },
    clean_state:
    {
      borderColor: 'white',
      borderStyle: 'solid',
      borderWidth: '2px',
    },
    deleteColor:
    {
      background: Colors.deleteColor,
      '&:hover': {
        color: 'black',
        background: LightenDarkenColor(Colors.deleteColor, 40),
      },
    },

    button: {
      margin: theme.spacing(1),
    },
    buttonRemove: {

      background: Colors.dangerBackgroundColor,
    },

    generalButton: {
      background: Colors.mainBackgroundColor,
      '&:hover': {
        color: 'black',
        background: LightenDarkenColor(Colors.mainBackgroundColor, 40),
      },
    },


    admin_emails: {
      backgroundColor: Colors.secondBackgroundColor,
      padding: '10px',
      borderRadius: '10px',
      marginTop: '5px',
      marginBottom: '5px',
    },
    admin_emails_empty: {
      display: 'none',
    },

    innerForm: {
      padding: '10px',
    },
  })
);

const AdministratorEdit = (props: IAdministratorCardData) => {
  const classes = useStyles();
  const adminObject = useContext(AdministratorContextUseReducer);
  const customerObject = useContext(CustomerContextUseReducer);

  const emptyAdministrator: IAdministrator = {
    name: '',
    phoneRegular: '',
    phoneMobile: '',
    familyName: '',
    notes: '',
    email: '',
    adminID: 0,

  };

  const [emailToAdd, SetEmailToAdd] = useState<string>();
  const [openConfirmationDialog, SetOpenConfirmationDialog] = useState<boolean>(false);
  const [emailToAddError, SetEmailToAddError] = useState<boolean>(false);
  const [isLoading, SetIsLoading] = useState(false);

  useEffect(() => {
    reset(props.admin);
    SetPositionID(props.admin?.positionID == null ? 1 : props.admin?.positionID);
  }, [props.admin]);


  const onSubmit = async (data: IAdministrator) => {
    data.customerID =
      customerObject.state.customer.selectedCustomer?.customer?.customerID;

    SetIsLoading(true);
    if (adminObject.state.admin.isNew) {
      data.adminID = 0;
      data.positionID = positionID;
      CreateAdministrator(data);
    } else UpdateAdministrator(data);
  };

  const CreateAdministrator = async (adminToCreate: IAdministrator) => {
    await createAdminAPICall(adminToCreate)
      .then(handleResponse)
      .then(
        (result) => {
          CreateAdministratorEmails(
            adminToCreate.adminEmails == undefined
              ? []
              : adminToCreate.adminEmails,
            messages.SuccessCreateAdministrator
          );
          adminObject.dispatch({ type: Types.SET_CURRENT_ADMIN, payload: { selectedAdministrator: {} }, });
          adminObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: true, message: messages.SuccessCreateAdministrator, }, }, });
          adminObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true }, });
          SetIsLoading(false);
        },
        (error) => {
          adminObject.dispatch({
            type: Types.SET_OUTCOME,
            payload: { operationOutcome: { success: false, message: error } },
          });
        }
      );
  };

  const UpdateAdministrator = async (adminToUpdate: IAdministrator) => {
    await updateAdminAPICall(adminToUpdate)
      .then(handleResponse)
      .then(
        (result) => {
          adminObject.dispatch({type: Types.SET_OUTCOME, payload: {
              operationOutcome: {success: true, message: messages.SuccessUpdatedAdministrator,},},
          });
          adminObject.dispatch({ type: Types.SET_IS_DIRTY, payload: { isDirty: false } });
          adminObject.dispatch({
            type: Types.SET_SHOW_NEW_BUTTON,
            payload: { setShowNewButton: true },
          });
          SetIsLoading(false);

          CreateAdministratorEmails(
            adminToUpdate.adminEmails == undefined
              ? []
              : adminToUpdate.adminEmails,
            messages.SuccessUpdatedAdministrator
          );
        },
        (error) => {
          adminObject.dispatch({
            type: Types.SET_OUTCOME,
            payload: { operationOutcome: { success: false, message: error } },
          });
        }
      );
  };
  const DeleteAdministrator = async (adminToDelete: IAdministrator) => {
    await deleteAdminAPICall(adminToDelete)
      .then(handleResponse)
      .then(
        (result) => {
          adminObject.dispatch({ type: Types.UPDATE_ADMIN_FLAG, payload: { needToUpdate: true }, });
          adminObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true }, });
          adminObject.dispatch({
            type: Types.SET_OUTCOME, payload: { operationOutcome: { success: true, message: messages.SuccessDeleteAdministrator, }, },
          });
        },
        (error) => {
          adminObject.dispatch({
            type: Types.SET_OUTCOME,
            payload: { operationOutcome: { success: false, message: error } },
          });
        }
      );
  };

  const CreateAdministratorEmails = async (
    adminEmailsToCreate: IAdminEmail[] | undefined,
    message: string
  ) => {
    let cust_1 = adminEmailsToCreate?.forEach((element) => {
      element.adminID = props.admin?.adminID;
    });

    await createAdminEmailsAPICall(adminEmailsToCreate)
      .then(handleResponse)
      .then(
        (result) => {
          let flagToChange = adminObject.state.admin.needToUpdate;
          adminObject.dispatch({
            type: Types.UPDATE_ADMIN_FLAG,
            payload: { needToUpdate: !flagToChange },
          });
        },
        (error) => {
          adminObject.dispatch({
            type: Types.SET_OUTCOME,
            payload: { operationOutcome: { success: false, message: error } },
          });
        }
      );
  };

  const [initialValues] = useState<IAdministrator | {}>(
    props.admin == null ? {} : props.admin
  );

  const {
    register,
    control,
    handleSubmit,
    reset,
    errors,
    setValue,
    getValues,
  } = useForm<IAdministrator>({
    defaultValues: initialValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'adminEmails',
    keyName: 'emailID',
  });

  const SetNewEmailValue = (value: string) => {
    SetEmailToAddError(false);
    SetEmailToAdd(value);
  };

  const getSTyle = () => {
    if (adminObject.state.admin.isDirty) return classes.dirty_state;
    else if (adminObject.state.admin.isNew) return classes.new_state;
    else return classes.clean_state;
  };

  const AddNewEmail = () => {
    let validEmail = validateEmail(emailToAdd == undefined ? '' : emailToAdd);

    if (emailToAdd === '' || emailToAdd === undefined || !validEmail) {
      SetEmailToAddError(true);
    } else {
      onModelChange();
      append({ address: emailToAdd });
      SetEmailToAdd('');
      SetEmailToAddError(false);
    }
  };

  const handleNewAdminEvent = () => {
    adminObject.dispatch({
      type: Types.SET_OPEARTION_DISABLED,
      payload: { operationDisabled: true },
    });
    adminObject.dispatch({
      type: Types.SET_IS_DIRTY,
      payload: { isDirty: false },
    });
    reset(emptyAdministrator);
    SetEmailToAdd('');
    fields.splice(0);

    adminObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: true } });
  };

  const removeAtIndex = (index: number) => {
    onModelChange();
    remove(index);
    fields.splice(index);
  };

  const handleConfirmationDeleteDialog = (event: boolean) => {
    SetOpenConfirmationDialog(false);
    if (event === true) {
      DeleteAdministrator(
        adminObject.state.admin.selectedAdministrator?.admin == undefined
          ? {}
          : adminObject.state.admin.selectedAdministrator.admin
      );
    }
  };

  const deleteAdminHandler = () => {
    SetOpenConfirmationDialog(true);
  };

  const makeInactive = async (admin: IAdministrator, note: string) => 
  {
    admin.notes = note;
    await makeAdminInactiveAPICall(admin)
    .then(handleResponse)
    .then(
      (result) => {
        adminObject.dispatch({ type: Types.UPDATE_ADMIN_FLAG, payload: { needToUpdate: true }, });
        adminObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true }, });
        adminObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: true, message: messages.SuccessUpdatedAdministrator, }, },
        });
      },
      (error) => {
        adminObject.dispatch({          type: Types.SET_OUTCOME,          payload: { operationOutcome: { success: false, message: error } }, });
      }
    );
    
 
  };

  const onModelChange = () => {
    adminObject.dispatch({
      type: Types.SET_IS_DIRTY,
      payload: { isDirty: true },
    });
  };

  const [positionID, SetPositionID] = useState<number>(
    props.admin?.positionID == null ? 1 : props.admin?.positionID
  );

  const handleChangePosition = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const values = getValues();
    values.positionID = Number(event.target.value as string);
    setValue('positionID', values.positionID);
    SetPositionID(values.positionID);
    onModelChange();
  };

  return (
    <div className={getSTyle()}>
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={true}
          content="Are you sure you like to delete this admin ?"
          onResult={(event) => handleConfirmationDeleteDialog(event)}
        />
      ) : null}

    {isLoading ? <CircularProgress className="centered" /> : null}

<div className='left'>
      <Grid container justify="flex-start" spacing={1}>
         <Grid item lg={12} xs={12} sm={12} md={12}>
         
              <GeneralButton 
                onClick={() => makeInactive(adminObject.state.admin.selectedAdministrator?.admin as IAdministrator, messages.AddressNotFound)}
                content='Address not found'
                />
         <div className='right ml-1 mb-1'>
              <GeneralButton 
              onClick={() => makeInactive(adminObject.state.admin.selectedAdministrator?.admin as IAdministrator,messages.HasBeenMoved)}
              content='Person has moved'/>
          </div>
          </Grid>
          
      </Grid>
      </div>

      

      
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" name="positionID" ref={register}></input>
        <div className="mt-1 mb-1">
          <Grid container spacing={1}>
            <Grid item sm={3} md={2} lg={2} xs={12}>
              <GeneralButton
                className="full-width"
                type="submit"
                content="update"
              />
            </Grid>

            {/* dont render DELETE and NEW button if we create a new admin */}
            {!adminObject.state.admin.isNew ? (

              <Grid item sm={2} md={1} lg={1} xs={12}>
                <GeneralAddButton
                  size="small"
                  onClick={handleNewAdminEvent}
                />
              </Grid>
            ) : null}
            {!adminObject.state.admin.isNew ? (
              <Grid item sm={3} md={2} lg={2} xs={12}>

                 <GeneralDeleteButton 
                   className="full-width"
                   content='delete'
                   onClick={deleteAdminHandler}
              />
              </Grid>
            ) : null}

          </Grid>
        </div>
        <TextField
          className="full-width"
          name="name"
          onChange={(event) => onModelChange()}
          label="Admin Name"
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={register({ required: true })}
        />
        <small className="red">{errors.name && 'Name is required'} </small>

        <input type="hidden" name="adminID" ref={register}></input>

        <input type="hidden" name="customerID" ref={register}></input>

        <TextField
          className="full-width"
          onChange={(event) => onModelChange()}
          name="familyName"
          label="Family Name"
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={register({ required: false })}
        />

        <TextField
          className="full-width"
          onChange={(event) => onModelChange()}
          name="email"
          label="Main email"
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={register({ required: true, maxLength: 50, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i })}
        />

        <small className="red">{errors.email && 'Email is required or invalid'} </small>

        <div
          className={
            fields.length != 0
              ? classes.admin_emails
              : classes.admin_emails_empty
          }
        >
          <InputLabel className="left">Additional emails</InputLabel>
          {fields.map((field, index) =>
            field.emailID != undefined ? (
              <Grid container spacing={1} key={index}>
                <Grid item sm={9}>
                  <TextField
                    key={field.emailID}
                    defaultValue={`${field.address}`}
                    disabled
                    name={`adminEmails[${index}].address`}
                    inputRef={register()}
                    className="full-width"
                  />
                </Grid>
                <Grid item sm={3}>
                  <div className="left">
                    <Fab
                      className={classes.buttonRemove}
                      onClick={() => removeAtIndex(index)}
                      aria-label="add"
                    >
                      <DeleteIcon />
                    </Fab>
                  </div>
                  <div className="left ml-3">
                    <Fab
                      className={classes.generalButton}
                      onClick={() => copyToClipBoard(field.address)}
                      aria-label="add"
                    >
                      <FileCopyTwoToneIcon />
                    </Fab>
                  </div>
                </Grid>
              </Grid>
            ) : null
          )}
        </div>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <TextField
              className="full-width"
              value={emailToAdd}
              label="Add EMAIL"
              onChange={(event) => SetNewEmailValue(event.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item sm={3}>
            <Button
              variant="contained"
              disabled={adminObject.state.admin.operationDisabled}
              onClick={() => AddNewEmail()}
              size="large"
              className={classes.generalButton}
              startIcon={<AddIcon />}
            >
              Add Email
            </Button>
          </Grid>
        </Grid>

        <small className="red">{emailToAddError && 'Email is invalid'} </small>

        {/* special approach as SELECT material UI does not work with OnChange event */}
        
        <Select
          className="full-width"
          onChange={handleChangePosition}
          value={positionID}
        >
          {!props.positionList ||
            props.positionList.map((p) => (
              <MenuItem key={p.positionID} value={p.positionID}>
                {p.name}
              </MenuItem>
            ))}
        </Select>

        <small className="red">
          {errors.positionID && 'Position is required'}{' '}
        </small>

        <TextField
          onChange={(event) => onModelChange()}
          className="full-width"
          name="phoneRegular"
          label="Phone"
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={register({ required: false })}
        />

        <TextField
          onChange={(event) => onModelChange()}
          className="full-width"
          name="phoneMobile"
          label="Mobile"
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={register({ required: false })}
        />

        <TextField
          onChange={(event) => onModelChange()}
          className="full-width"
          name="notes"
          multiline
          label="Notes"
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={register({ required: false })}
        />

       
      </form>
    </div>
  );
};

export default AdministratorEdit;
