

import React, { useContext, useState, useEffect } from 'react';
import { messages, Colors, LightenDarkenColor, CreateTodayDate4Control, CreateStringDate } from '../Utils';
import { Theme, makeStyles, createStyles, Fab, FormControlLabel, Switch, FormControl, InputLabel, Checkbox } from '@material-ui/core';



import 'react-toastify/dist/ReactToastify.css';

import { Grid, TextField, CircularProgress, } from '@material-ui/core';

import { useForm, Controller } from 'react-hook-form';

import { createReminderAPICall, updateReminderAPICall, deleteReminderAPICall, } from '../services/DataServices';
import { handleResponse } from '../autorization/handleResponse';


import { CustomerContextUseReducer } from '../Customer/CustomerContext';

import GeneralButton, { GeneralAddButton, GeneralDeleteButton } from '../general/GeneralButton';
import ConfirmationDialog from '../general/ConfirmationDialog';
import { IReminderCardData, IReminder } from '../interfaces/IReminder';
import { ReminderContextUseReducer } from './ReminderContext';
import { Types } from './ReminderReducer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    dirty_state:
    {
      borderColor: 'red',
      borderStyle: 'solid',
      borderWidth: '2px',
    },
    new_state:
    {
      borderColor: 'green',
      borderStyle: 'solid',
      borderWidth: '2px',
    },
    clean_state:
    {
      borderColor: 'white',
      borderStyle: 'solid',
      borderWidth: '2px',
    },
    deleteColor:
    {
      background: Colors.deleteColor,
      '&:hover': {
        color: 'black',
        background: LightenDarkenColor(Colors.deleteColor, 40),
      },
    },

    button: {
      margin: theme.spacing(1),
    },
    buttonRemove: {

      background: Colors.dangerBackgroundColor,
    },

    generalButton: {
      background: Colors.mainBackgroundColor,
      '&:hover': {
        color: 'black',
        background: LightenDarkenColor(Colors.mainBackgroundColor, 40),
      },
    },


    admin_emails: {
      backgroundColor: Colors.secondBackgroundColor,
      padding: '10px',
      borderRadius: '10px',
      marginTop: '5px',
      marginBottom: '5px',
    },
    admin_emails_empty: {
      display: 'none',
    },

    innerForm: {
      padding: '10px',
    },
  })
);

const ReminderEdit = (props: IReminderCardData) => {
  const reminderObject = useContext(ReminderContextUseReducer);
  const customerObject = useContext(CustomerContextUseReducer);

  const emptyReminder: IReminder = {
    dueDate: new Date(),
    reminderID: 0,
    closed: true,
    customerID: 0,
    note: '',
  };

  const deleteReminderHandler = () => {
    SetOpenConfirmationDialog(true);
  };

  const [openConfirmationDialog, SetOpenConfirmationDialog] = useState<boolean>(false);
  const [closedReminderStatus, SetClosedReminderStatus] = useState<boolean>(props.reminder?.closed as boolean);

  const [isLoading, SetIsLoading] = useState<boolean>(false);



  const handleChangeClosedStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    SetClosedReminderStatus(event.target.checked);
  };

  const [initialValues] = useState<IReminder | {}>(
    props.reminder == null ? {} : props.reminder
  );

  const {
    register,
    control,
    handleSubmit,
    reset,
    errors,
    getValues,
    setValue,
  } = useForm<IReminder>({
    defaultValues: initialValues,
  });

  const handleNewReminderEvent = () => {
    reset(emptyReminder);
    reminderObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: true }, });
    reminderObject.dispatch({ type: Types.SET_OPEARTION_DISABLED, payload: { operationDisabled: false } });
  };


  const onSubmit = async (data: IReminder) => {

    data.closed = closedReminderStatus;
    data.customerID = customerObject.state.customer.selectedCustomer?.customer?.customerID;
    data.reminderID = reminderObject.state.reminder.selectedReminder?.reminder?.reminderID;
    data.calendarEventId = reminderObject.state.reminder.selectedReminder?.reminder?.calendarEventId;
    data.dueDateStr = CreateStringDate(data.dueDate);

    reminderObject.dispatch({ type: Types.SET_OPEARTION_DISABLED, payload: { operationDisabled: true } });

    SetIsLoading(true);
    if (reminderObject.state.reminder.isNew)
      CreateReminder(data);
    else
      UpdateReminder(data);
  };

  const CreateReminder = async (reminder: IReminder) => {
    await createReminderAPICall(reminder)
      .then(handleResponse)
      .then(
        (reminderID) => {
          reminderObject.dispatch({ type: Types.SET_CURRENT_REMINDER, payload: { selectedReminder: {} }, });
          reminderObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: true, message: messages.SuccessCreateReminder } }, });
          reminderObject.dispatch({ type: Types.SET_REMINDER_EDIT_VISIBLE, payload: { EditVisible: false }, });
          reminderObject.dispatch({ type: Types.SET_REMINDER_FOUND_VISIBLE, payload: { FoundVisible: true }, });
          reminderObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true }, });
          let flagToChange = reminderObject.state.reminder.needToUpdate;
          reminderObject.dispatch({ type: Types.UPDATE_REMINDER_FLAG, payload: { needToUpdate: !flagToChange }, });


          SetIsLoading(false);
        },
        (error) => {
          SetIsLoading(false);
          reminderObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: false, message: error } }, });
        }
      );
  };
  const UpdateReminder = async (reminder: IReminder) => {
    await updateReminderAPICall(reminder)
      .then(handleResponse)
      .then(
        (result) => {
          reminderObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: true, message: messages.SuccessUpdatedReminder } }, });
          reminderObject.dispatch({ type: Types.SET_REMINDER_EDIT_VISIBLE, payload: { EditVisible: false }, });
          reminderObject.dispatch({ type: Types.SET_REMINDER_FOUND_VISIBLE, payload: { FoundVisible: true }, });
          reminderObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true }, });
          let flagToChange = reminderObject.state.reminder.needToUpdate;
          reminderObject.dispatch({ type: Types.UPDATE_REMINDER_FLAG, payload: { needToUpdate: !flagToChange }, });


          SetIsLoading(false);
        },
        (error) => {
          SetIsLoading(false);
          reminderObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: false, message: error } }, });
        }
      );
  };

  const DeleteReminder = async (reminderToDelete: IReminder) => {
    await deleteReminderAPICall(reminderToDelete)
      .then(handleResponse)
      .then(
        (result) => {
          reminderObject.dispatch({ type: Types.UPDATE_REMINDER_FLAG, payload: { needToUpdate: true } });
          reminderObject.dispatch({ type: Types.SET_REMINDER_FOUND_VISIBLE, payload: { FoundVisible: true } });
          reminderObject.dispatch({ type: Types.SET_REMINDER_EDIT_VISIBLE, payload: { EditVisible: false } });
          reminderObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true } });
          reminderObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: true, message: messages.SuccessDeleteReminder } } });
        },
        (error) => {
          reminderObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: false, message: error } }, });
        }
      );
  };


  const handleConfirmationDeleteDialog = (event: boolean) => {
    SetOpenConfirmationDialog(false);
    if (event === true) {
      DeleteReminder(reminderObject.state.reminder.selectedReminder?.reminder as IReminder);
    }
  };


  const classes = useStyles();

  return (
    <div>
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={true}
          content="Are you sure you like to delete this reminder ?"
          onResult={(event) => handleConfirmationDeleteDialog(event)}
        />
      ) : null}

      {isLoading ? <CircularProgress className="centered" /> : null}
      <form onSubmit={handleSubmit(onSubmit)}>

        <Grid container spacing={1}>
          <Grid item sm={6} xs={12}>

            <FormControlLabel
              control={
                <Switch
                  checked={closedReminderStatus}

                  onChange={handleChangeClosedStatus}
                  color="primary"
                />
              }
              label="Closed"
            />


          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              inputRef={register({ required: false })}
              className="full-width"
              name="dueDate"
              id="datetime-local"
              label="Reminder Date"
              type="datetime-local"
              defaultValue={CreateTodayDate4Control()}

              InputLabelProps={{
                shrink: true,
              }}
            />

          </Grid>

          <Grid item sm={6} xs={12}>

          </Grid>
          <Grid item sm={6} xs={12}>

          </Grid>
          <Grid item xs={12}>
            <TextField
              name="note"
              className="full-width"
              label="Notes"
              multiline
              inputRef={register({ required: false })}
            />
          </Grid>
        </Grid>
        <div className='mt-1'>
          <Grid container spacing={2}>
            <Grid item sm={3} md={3} xs={12}>
              <GeneralButton
                className="full-width"
                type="submit"
                content="update"
              />
            </Grid>
            <Grid item sm={1} md={1} xs={12}>
              <GeneralAddButton
                type="reset"
                onClick={handleNewReminderEvent}
                size='small'
              />

            </Grid>
            {reminderObject.state.reminder.isNew ? null : (<Grid item sm={2} md={1} lg={1} xs={12}>
              <GeneralDeleteButton
                content='delete'
                onClick={deleteReminderHandler}
              />
            </Grid>
            )}


          </Grid>
        </div>

        <Grid container spacing={1}>

        </Grid>
      </form>
    </div>
  );
};

export default ReminderEdit;
