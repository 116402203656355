import React from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GridOnIcon from '@material-ui/icons/GridOn';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import { makeStyles } from '@material-ui/core/styles';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import SportsKabaddiIcon from '@material-ui/icons/SportsKabaddi';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import PeopleIcon from '@material-ui/icons/People';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
//import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { createStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
//import { Link } from 'react-router-dom';

import { withRouter } from 'react-router-dom'
import classes from '*.module.css';
import { Colors } from './Utils';
import { EmailOutlined } from '@material-ui/icons';



const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    app_bar:
    {
      backgroundColor: Colors.teacherColor,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      
    },
    title: {
      flexGrow: 1,
    },
  })
);

type Anchor = 'left';

const  Navigation = (props: any) => {
  const { history } = props;
  

const itemList = [
  {
    text: "Create Booking",
    icon: <InboxIcon />,
    onClick: () => history.push("/createbooking")
  },
  {
    text: "Customers",
    icon: <SportsKabaddiIcon />,
    onClick: () => history.push("/customers")
  },
  {
    text: "Administrators",
    icon: <SupervisorAccountIcon />,
    onClick: () => history.push("/administrators")
  },
  {
    text: "Calls",
    icon: <PhoneInTalkIcon />,
    onClick: () => history.push("/calls")
  },
  {
    text: "Shows",
    icon: <ChildCareIcon />,
    onClick: () => history.push("/shows")
  },
  {
    text: "Customer Calls",
    icon: <LocalPhoneIcon />,
    onClick: () => history.push("/customercalls")
  },
  {
    text: "Time Line",
    icon: <AccessTimeIcon />,
    onClick: () => history.push("/events")
  },
  {
    text: "Schedule",
    icon: <CalendarTodayIcon />,
    onClick: () => history.push("/schedule")
  },
  {
    text: "Reminder",
    icon: <AddAlertIcon />,
    onClick: () => history.push("/reminders")
  },
  {
    text: "Business Expenses",
    icon: <MonetizationOnIcon   />,
    onClick: () => history.push("/expenses")
  },

  {
    text: "Booking Excel file",
    icon: <GridOnIcon   />,
    onClick: () => history.push("/excelViewer")
  },
  {
    text: "Quotes",
    icon: <DirectionsRunIcon   />,
    onClick: () => history.push("/quotes")
  },
  {
    text: "Hotels",
    icon: <AccountBalanceIcon   />,
    onClick: () => history.push("/hotels")
  },
  {
    text: "Invoices",
    icon: <EuroSymbolIcon   />,
    onClick: () => history.push("/invoices")
  },

  {
    text: "Due Invoices",
    icon: <EuroSymbolIcon   />,
    onClick: () => history.push("/dueinvoices")
  },
  {
    text: "Email campaign",
    icon: <EmailOutlined   />,
    onClick: () => history.push("/emailcampaign")
  },

    
];
  const classes1 = useStyles1();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: Anchor) => (
    <div
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
     <List>
         {itemList.map((item, index) => {
           const { text, icon, onClick } = item;
           return (
             <ListItem button key={text} onClick={onClick}>
               {icon && <ListItemIcon>{icon}</ListItemIcon>}
               <ListItemText primary={text}/>
             </ListItem>

           );
         }
         )

         }
   </List>
        
   
    </div>
  );

  

  return (
    <div>
      <div className={classes1.root}>
        <AppBar 
         className={classes1.app_bar}
         position="static">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes1.menuButton}
              color="inherit"
              onClick={toggleDrawer('left', true)}
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>

      {(['left'] as Anchor[]).map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
export default withRouter(Navigation);