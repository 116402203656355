import { InvoiceContextUseReducer } from './InvoiceContext';
import React, { useContext, useState, useEffect } from 'react';
import { messages, Colors, LightenDarkenColor, CreateTodayDate4Control, generalControlColor } from '../Utils';
import { Theme,   withStyles,FormControlLabel, makeStyles, createStyles, Fab, Switch, } from '@material-ui/core';


import 'react-toastify/dist/ReactToastify.css';

import { Grid, TextField,   Select, MenuItem,  CircularProgress, } from '@material-ui/core';

import { useForm,  } from 'react-hook-form';

import { deleteInvoiceAPICall, createInvoiceAPICall, updateInvoiceAPICall, sendInvoiceAPICall, } from '../services/DataServices';
import { handleResponse } from '../autorization/handleResponse';

import { IInvoice, IInvoiceCardData, } from '../interfaces/IInvoice';


import { CustomerContextUseReducer } from '../Customer/CustomerContext';
import { Types } from './InvoiceReducer';
import GeneralButton, { GeneralAddButton, GeneralDeleteButton } from '../general/GeneralButton';
import ConfirmationDialog from '../general/ConfirmationDialog';


const TealSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: generalControlColor,
    },
    '&$checked + $track': {
      backgroundColor: generalControlColor,
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    dirty_state:
    {
      borderColor: 'red',
      borderStyle: 'solid',
      borderWidth: '2px',
      padding: '5px',
    },
    new_state:
    {
      borderColor: 'green',
      borderStyle: 'solid',
      borderWidth: '2px',
      padding: '5px',
    },
    clean_state:
    {
      borderColor: 'white',
      borderStyle: 'solid',
      borderWidth: '2px',
    },
    deleteColor:
    {
      background: Colors.deleteColor,
      '&:hover': {
        color: 'black',
        background: LightenDarkenColor(Colors.deleteColor, 40),
      },
    },
    button: {
      margin: theme.spacing(1),
    },
    buttonRemove: {

      background: Colors.dangerBackgroundColor,
    },
    generalButton: {
      background: Colors.mainBackgroundColor,
      '&:hover': {
        color: 'black',
        background: LightenDarkenColor(Colors.mainBackgroundColor, 40),
      },
    },
    innerForm: {
      padding: '10px',
    },
  })
);

const InvoiceEdit = (props: IInvoiceCardData) => {

  const classes = useStyles();
  
  const invoiceObject = useContext(InvoiceContextUseReducer);
  const customerObject = useContext(CustomerContextUseReducer);
  const [openConfirmationDialog, SetOpenConfirmationDialog] = useState<boolean>(false);
  const [fixedSum, SetFixedSum] = useState<boolean>(false);
  
  
  
  const [totalWithGST, SetTotalWithGST] = useState<boolean>(false);
  const [disabledPricePerPersonTextField, SetDisabledPricePerPersonTextField] = useState<boolean>(false);
  const [subTotalDisabled, SetSubTotalDisabled] = useState<boolean>(false);
  const [fixedSumSwitchDisabled, SetfixedSumSwitchDisabled] = useState<boolean>(false);
  const [programID, SetProgramID] = useState<number | undefined>(
    props.invoice?.programID == undefined ? 1 : props.invoice?.programID


    
  );

  
  console.log(props.programList);
  
  const handleChangeProgramID = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const values = getValues();
    values.programID = Number(event.target.value as string);
    setValue('programID', values.programID);
    SetProgramID(values.programID);
    onModelChange();
  };


  

  const deleteInvoiceHandler = () => {
    SetOpenConfirmationDialog(true);
  };


  useEffect(() => {

    reset(props.invoice);
    let fixedPriceFlag = props.invoice?.fixedPrice as boolean;
    SetFixedSum(fixedPriceFlag);
    SetTotalWithGST(false);
    SetDisabledPricePerPersonTextField(fixedPriceFlag);  
    SetfixedSumSwitchDisabled(false);
    SetProgramID(props.invoice?.programID == null ? 1 : props.invoice?.programID);
    SetSubTotalDisabled(!fixedPriceFlag);
    
    
  }, [props.invoice]);

  
  const onIncludingGSTChanged = (value: boolean) => {
    setValue('numberStudents', undefined);
    setValue('pricePerStudent',undefined);
    SetDisabledPricePerPersonTextField(value);
    SetfixedSumSwitchDisabled(value);
    SetTotalWithGST(value);
    SetFixedSum(value);
    SetSubTotalDisabled(!value);
  }

  const onFixedSwitchChanged = (value: boolean) => {
    SetFixedSum(value);
    setValue('numberStudents', undefined);
    setValue('pricePerStudent', undefined);
    SetDisabledPricePerPersonTextField(value);
    SetSubTotalDisabled(!value);
  }

  const onModelChange = () => {
    invoiceObject.dispatch({
      type: Types.SET_IS_DIRTY,
      payload: { isDirty: true },
    });
  };

  const onCalculateSubTotal = () => {
    let currentValues = getValues();


   let pps = currentValues.pricePerStudent as number;
   let numStdts = currentValues.numberStudents as number;
   let subTotal = Math.round((numStdts * pps) * 100) / 100;
   setValue('subTotal',subTotal);
   invoiceObject.dispatch({type: Types.SET_IS_DIRTY, payload: { isDirty: true }, });
    
  }


  const emptyInvoice: IInvoice = {
    total: 0,
    programID: 0,
    pricePerStudent:  props.pricePerStudent,
  };


  const getSTyle = () => {
    if (invoiceObject.state.invoice.isDirty) return classes.dirty_state;
    else if (invoiceObject.state.invoice.isNew) return classes.new_state;
    else return classes.clean_state;
  };

  const handleConfirmationDeleteDialog = (event: boolean) => {
    SetOpenConfirmationDialog(false);
    if (event === true) {
      DeleteInvoice(
        invoiceObject.state.invoice.selectedInvoice?.invoice == undefined
          ? {}
          : invoiceObject.state.invoice.selectedInvoice?.invoice
      );
    }
  };

  const DeleteInvoice = async (invoiceToDelete: IInvoice) => {
    await deleteInvoiceAPICall(invoiceToDelete)
      .then(handleResponse)
      .then(
        (result) => {
          invoiceObject.dispatch({ type: Types.UPDATE_INVOICE_FLAG, payload: { needToUpdate: true }, });
          invoiceObject.dispatch({ type: Types.SET_INVOICE_NEW_BUTTON, payload: { setInvoiceNewButton: true }, });
          invoiceObject.dispatch({
            type: Types.SET_OUTCOME, payload: { operationOutcome: { success: true, message: messages.SuccessDeleteInvoice, }, },
          });
        },
        (error) => {
          invoiceObject.dispatch({
            type: Types.SET_OUTCOME,
            payload: { operationOutcome: { success: false, message: error } },
          });
        }
      );
  };

  const [isLoading, SetIsLoading] = useState(false); 

  const [initialValues] = useState<IInvoice | {}>(
    props.invoice == null ? {pricePerStudent: props.pricePerStudent} : props.invoice
  );


  const {
    register,
    control,
    handleSubmit,
    reset,
    errors,
    setValue,
    getValues,
  } = useForm<IInvoice>({
    defaultValues: initialValues,
  });

  const CreateInvoice = async (invoiceToCreate: IInvoice) => {
    await createInvoiceAPICall(invoiceToCreate)
      .then(handleResponse)
      .then(
        (result) => {
          invoiceObject.dispatch({ type: Types.SET_CURRENT_INVOICE, payload: { selectedInvoice: {} }, });
          invoiceObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: true, message: messages.SuccessCreateInvoice, }, }, });
          invoiceObject.dispatch({ type: Types.SET_INVOICE_NEW_BUTTON, payload: { setInvoiceNewButton: true }, });
          let flagToChange = invoiceObject.state.invoice.needToUpdate;
          invoiceObject.dispatch({ type: Types.UPDATE_INVOICE_FLAG, payload: { needToUpdate: !flagToChange }, });
          SetIsLoading(false);
        },
        (error) => {
          invoiceObject.dispatch({
            type: Types.SET_OUTCOME,
            payload: { operationOutcome: { success: false, message: error } },
          });
        }
      );
  };


  const sendToMeHandler = async () => {
    await sendInvoiceAPICall(invoiceObject.state.invoice.selectedInvoice?.invoice as IInvoice)
      .then(handleResponse)
      .then(
        (result) => {
          invoiceObject.dispatch({type: Types.SET_OUTCOME, payload: {
              operationOutcome: {success: true, message: messages.SuccessSentInvoice,},},
          });
        },
        (error) => {
          invoiceObject.dispatch({
            type: Types.SET_OUTCOME,
            payload: { operationOutcome: { success: false, message: error } },
          });
        }
      );
  };

  const handleNewInvoiceEvent = () => {
    invoiceObject.dispatch({
      type: Types.SET_OPEARTION_DISABLED,
      payload: { operationDisabled: true },
    });
    invoiceObject.dispatch({
      type: Types.SET_IS_DIRTY,
      payload: { isDirty: false },
    });
    reset(emptyInvoice);
    invoiceObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: true } });

  };


  const UpdateInvoice = async (invoiceToUpdate: IInvoice) => {
    await updateInvoiceAPICall(invoiceToUpdate)
      .then(handleResponse)
      .then(
        (result) => {
          invoiceObject.dispatch({type: Types.SET_OUTCOME, payload: {
              operationOutcome: {success: true, message: messages.SuccessUpdatedInvoice,},},
          });
          invoiceObject.dispatch({ type: Types.SET_IS_DIRTY, payload: { isDirty: false } });
          invoiceObject.dispatch({ type: Types.SET_INVOICE_NEW_BUTTON, payload: { setInvoiceNewButton: true },});
          SetIsLoading(false);
          let flagToChange = invoiceObject.state.invoice.needToUpdate;
          invoiceObject.dispatch({ type: Types.UPDATE_INVOICE_FLAG, payload: { needToUpdate: !flagToChange }, });


        },
        (error) => {
          invoiceObject.dispatch({
            type: Types.SET_OUTCOME,
            payload: { operationOutcome: { success: false, message: error } },
          });
        }
      );
  };

  const onSubmit = async (data: IInvoice) => {
    data.customerID = customerObject.state.customer.selectedCustomer?.customer?.customerID;
    data.dateInvoiceStr = data.date?.toLocaleString();
    data.customerName = customerObject.state.customer.selectedCustomer?.customer?.name;
    data.programID = programID;
    let showNameObject = props.programList?.find(x => x.programID == data.programID);
    data.nameShow = showNameObject?.invoiceDescription;
    data.fixedPrice = fixedSum;
    
    
    // when an invoice is created using the total, we need to submit minus GST
    if (totalWithGST)
    {
      let newSubTotal = data.subTotal as number * 3 / 23;
      data.subTotal = data.subTotal as number - newSubTotal;

    }

    SetIsLoading(true);
    if (invoiceObject.state.invoice.isNew) {
      data.invoiceID = 0;
      data.status = 1;
      CreateInvoice(data);
    } else UpdateInvoice(data);
  };

  return (
    <div className={getSTyle()}>
    {openConfirmationDialog ? (
      <ConfirmationDialog
        open={true}
        content="Are you sure you like to delete this invoice ?"
        onResult={(event) => handleConfirmationDeleteDialog(event)}
      />
    ) : null}

  {isLoading ? <CircularProgress className="centered" /> : null}
  
  
    
    <form onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" name="programID" ref={register}></input>
      <input type="hidden" name="fixedPrice" ref={register}></input>
      <input type="hidden" name="status" ref={register}></input>
      
      <div className="mt-1 mb-1">
        <Grid container spacing={1}>
        <Grid item sm={3} md={2} lg={2} xs={12}>
            <GeneralButton
              className="full-width"
              type="submit"
              content="update"
            />
          </Grid>
          <Grid item sm={3} md={2} lg={2} xs={12}>
            <GeneralButton
              className="full-width"
              content="SEND"
              onClick={sendToMeHandler}
              
            />
          </Grid>


          

            <Grid item sm={2} md={1} lg={1} xs={12}>
              <GeneralAddButton
                size="small"
                onClick={handleNewInvoiceEvent}
                
              />
            </Grid>
          
  
          {!invoiceObject.state.invoice.isNew ? (
            <Grid item sm={3} md={2} lg={2} xs={12}>

               <GeneralDeleteButton 
                 className="full-width"
                 content='delete'
                 onClick={deleteInvoiceHandler}
            />
            </Grid>
          ) : null}

        </Grid>
      </div>

        <TextField
              inputRef={register({ required: false })}
              className="full-width"
              onChange={(event) => onModelChange()}
              name="date"
              id="datetime-local"
              label="Due Date"
              type="datetime-local"
              defaultValue={CreateTodayDate4Control()}
              InputLabelProps={{
                shrink: true,
              }}
            />


      <small className="red">{errors.date && 'Date is required'} </small>

      <input type="hidden" name="invoiceID" ref={register}></input>

      <input type="hidden" name="customerID" ref={register}></input>




     


      <TextField
        className="full-width"
        onChange={(event) => onCalculateSubTotal()}
        name="numberStudents"
        disabled={disabledPricePerPersonTextField}
        label="Number of people attended"
        InputLabelProps={{
          shrink: true,
        }}
        inputRef={register({ required: false })}
      />

  


  <div>Current price per student: {props.pricePerStudent}</div>
      




<FormControlLabel
              control={
                <TealSwitch
                  checked={fixedSum}
                  disabled={fixedSumSwitchDisabled}
                  onChange={(event) => onFixedSwitchChanged(event.target.checked)}
                />
              }
              label="Fixed Sum"
            />

    <FormControlLabel
              control={
                <TealSwitch
                  checked={totalWithGST}
                  onChange={(event) => onIncludingGSTChanged(event.target.checked)}
                />
              }
              label="Including GST ?"
            />





      <TextField
          onChange={(event) => onCalculateSubTotal()}
          className="full-width"
          name="pricePerStudent"
          label="Price per person"
          disabled={disabledPricePerPersonTextField}
        
          
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={register({ required: false })}
        /> 



            

      <TextField
        onChange={(event) => onModelChange()}
        className="full-width"
        name="subTotal"
        disabled={subTotalDisabled}

        label="Sub Total"
        InputLabelProps={{
          shrink: true,
        }}
        
        inputRef={register({ required: true,  pattern: /^(?=.*\d)(?=.*[1-9]).{1,10}$/i })}
        
      />

          <small className="red">{errors.subTotal && 'Must be non-zero'} </small>

      <Select
          className="full-width"
          onChange={handleChangeProgramID}
          value={programID}
        >
          {!props.programList ||
            props.programList.map((p) => (
              <MenuItem key={p.programID} value={p.programID}>
                {p.invoiceDescription}
              </MenuItem>
            ))}
        </Select>

     
    </form>
  </div>
    )
};

export default InvoiceEdit;
