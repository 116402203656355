import React from 'react';
import CustomerCallsCommander from './CustomerCallsCommander';
import CustomerCallsPresenter from './CustomerCallsPresenter';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CustomerCallProviderUseReducer } from './CustomerCallContext';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '95%',
    margin: '0 auto',
  },
}));

const CustomerCalls = () => {
  const classes = useStyles();

  return (
    <div>
      <CustomerCallProviderUseReducer>
        <div className={classes.wrapper}>
              
          <Grid container spacing={2}>
            <Grid item xs={12}>
            <h3>Customer calls</h3>    
              <CustomerCallsCommander />
            </Grid>
            <Grid item xs={12}>
              <CustomerCallsPresenter />
            </Grid>
          </Grid>
        </div>
      </CustomerCallProviderUseReducer>
    </div>
  );
};

export default CustomerCalls;
