import React, { useContext } from 'react';
import './App.css';

import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import Navigation from './components/Navigation';
import Customer from './components/Customer/Customers';
import Login from './components/autorization/Login';
import {  PrivateRouteWebApiKeyProvider, PrivateRouteWorking } from './components/autorization/PrivateRoute';
//import { authenticationService } from './components/autorization/authentication.service';
import Home from './components/Home';
import CreateBooking from './components/Bookings/CreateBooking';

import { CustomerProviderUseReducer } from './components/Customer/CustomerContext';
import CurrentCustomer from './components/Customer/CurrentCustomer';
import Administrator from './components/AdministratorTwoViews/Administrator';
import CustomerCalls from './components/CustomerCalls/CustomerCalls';
import Call from './components/Calls/Call';

import Show from './components/Show/Show';
import EventManager from './components/EventManager/EventManager';
import { CalendarScheduler } from './components/Calendar/CalendarScheduler';
import Reminder from './components/Reminder/Reminder';
import Expense from './components/Expenses/Expense';
import ExcelViewer from './components/ExcelViewer/ExcelViewer';
import Quote from './components/Quotes/Quote';
import Hotel from './components/Hotels/Hotel';
import InvoiceCommander from './components/Invoices/InvoiceCommander';
import InvoiceDue from './components/Invoices/InvoiceDue';
import Invoice from './components/Invoices/Invoice';
import EmailCampaign from './components/EmailCampaign/EmailCampaign';



//import { makeStyles, FormHelperText } from '@material-ui/core';

/* const usesStyles = makeStyles({
container: {
  display: "flex"
}
}); */

function App() {
/*   const classes = usesStyles(); */


  return (
    <div className="App">
      <CustomerProviderUseReducer>
       <Router>
          
         {/* <Drawer/> */}
         <Navigation></Navigation>
         <CurrentCustomer />

          <Switch>

{/*           <PrivateRoute1 path="/createbooking"  isAuthenticated={isAuthenticated()}  component={CreateBooking} /> */}
          
          
          <PrivateRouteWebApiKeyProvider path="/createbooking"   component={CreateBooking} />
          <PrivateRouteWebApiKeyProvider path="/customers"   component={Customer} />
          <PrivateRouteWebApiKeyProvider path="/administrators"   component={Administrator} />
          <PrivateRouteWebApiKeyProvider path="/calls"   component={Call} />
          <PrivateRouteWebApiKeyProvider path="/shows"   component={Show} />
          <PrivateRouteWebApiKeyProvider path="/events"   component={EventManager} />

          <PrivateRouteWebApiKeyProvider path="/customercalls"   component={CustomerCalls} />
          <PrivateRouteWebApiKeyProvider path="/schedule"   component={CalendarScheduler} />
          <PrivateRouteWebApiKeyProvider path="/reminders"   component={Reminder} />
          <PrivateRouteWebApiKeyProvider path="/expenses"   component={Expense} />
          <PrivateRouteWebApiKeyProvider path="/excelViewer"   component={ExcelViewer} />
          <PrivateRouteWebApiKeyProvider path="/quotes"   component={Quote} />
          <PrivateRouteWebApiKeyProvider path="/hotels"   component={Hotel} />
          <PrivateRouteWebApiKeyProvider path="/invoices"   component={Invoice} /> 
          <PrivateRouteWebApiKeyProvider path="/dueinvoices"   component={InvoiceCommander} />
          <PrivateRouteWebApiKeyProvider path="/emailcampaign"   component={EmailCampaign} />
          
        
          <PrivateRouteWebApiKeyProvider path="/"  exact component={Home} />
          <Route path="/clients"  component={CalendarScheduler} />
          
          {/* <Route path="/customers"    component={Customers} /> */}
          
          <Route path='/login' component={Login}/> 
        
          <Route render={() => <Redirect to={{pathname: "/login"}} />} />
          </Switch>
       </Router>
       </CustomerProviderUseReducer>
       
    </div>
  );
}

export default App;
