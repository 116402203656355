import { BehaviorSubject } from 'rxjs';
import urls, { tempoPassword }  from '../Utils';
import { handleResponse } from './handleResponse';

const currentUser = JSON.parse(sessionStorage.getItem('currentUser') || '{}');
const currentUserSubject = new BehaviorSubject(currentUser);

let api_key_data = tempoPassword;

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value },
    get currentApiKey() { return api_key_data }
};


function login(username: string, password: string) {

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Api_Key: `${password}`,
        },

        body: JSON.stringify({ username, password })
    };

    return fetch(`${urls.mainDomain}/api/users/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // user.authdata = window.btoa(username + ':' + password);
            //localStorage.setItem('currentUser', JSON.stringify(user));
          //  sessionStorage.setItem('currentUser', JSON.stringify(username));
            console.log(username);
            api_key_data = password;
            


            //currentUserSubject.next(user);

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    //sessionStorage.removeItem('currentUser');
    api_key_data = 'tempo';

    currentUserSubject.next(null);
}