import React, { useContext, useState } from 'react';

import {
  messages,
  Colors,
  generalControlColor,
  CreateStringDate,
  LightenDarkenColor,
  CreateTodayDate4Control,
} from '../Utils';
import {
  Theme,
  makeStyles,
  createStyles,
  Switch,
  withStyles,
} from '@material-ui/core';

import 'react-toastify/dist/ReactToastify.css';

import {
  Grid,
  TextField,

  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from '@material-ui/core';

import { useForm, Controller } from 'react-hook-form';
import { handleResponse } from '../autorization/handleResponse';
import { CustomerContextUseReducer } from '../Customer/CustomerContext';
import GeneralButton, { GeneralAddButton, GeneralDeleteButton } from '../general/GeneralButton';
import ConfirmationDialog from '../general/ConfirmationDialog';
import { IShowCardData, IShow } from '../interfaces/IShow';
import { ShowContextUseReducer } from './ShowContext';
import { Types } from './ShowReducer';
import { createShowAPICall, updateShowAPICall, deleteShowAPICall } from '../services/DataServices';

const TealSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: generalControlColor,
    },
    '&$checked + $track': {
      backgroundColor: generalControlColor,
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      margin: theme.spacing(1),
    },
    generalButton: {
      background: Colors.mainBackgroundColor,
      '&:hover': {
        color: 'black',
        background: LightenDarkenColor(Colors.mainBackgroundColor, 40),
      },
    },

    alignedRight: {
      float: 'right',
    },
    bold: {
      fontWeight: 'bold',
    },


    innerForm: {
      padding: '10px',
    },
  })
);

const ShowEdit = (props: IShowCardData) => {
  const showObject = useContext(ShowContextUseReducer);
  const customerObject = useContext(CustomerContextUseReducer);

  const emptyShow: IShow = {
    datePerformance: new Date(),
    datePerformanceStr: '',
    customerID: 0,
    showID: 0,
    price: 0,
    numberOfPeople: 0,
    notes: '',
    programID: 24,
  };

  const deleteShowHandler = () => {
    SetOpenConfirmationDialog(true);
  };

  const [openConfirmationDialog, SetOpenConfirmationDialog] = useState<boolean>(false);
  const [isLoading, SetIsLoading] = useState<boolean>(false);
  const [initialValues] = useState<IShow | {}>(
    props.show == null ? {} : props.show
  );

  const {
    register,
    control,
    handleSubmit,
    reset,
    errors,
  } = useForm<IShow>({
    defaultValues: initialValues,
  });

  const handleNewShowEvent = () => {
    reset(emptyShow);
    showObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: true }, });
    showObject.dispatch({ type: Types.SET_OPEARTION_DISABLED, payload: { operationDisabled: false } });
  };


  const onSubmit = async (data: IShow) => {
    showObject.dispatch({ type: Types.SET_OPEARTION_DISABLED, payload: { operationDisabled: true } });
    data.customerID = customerObject.state.customer.selectedCustomer?.customer?.customerID;
    data.showID = showObject.state.show.selectedShow?.show?.showID;
    data.datePerformanceStr = CreateStringDate(data.datePerformance);

    SetIsLoading(true);
    if (showObject.state.show.isNew)
      CreateShow(data);
    else
      UpdateShow(data);
  };

  const CreateShow = async (show: IShow) => {
    await createShowAPICall(show)
      .then(handleResponse)
      .then(
        (showID) => {
          showObject.dispatch({ type: Types.SET_CURRENT_SHOW, payload: { selectedShow: {} }, });
          showObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: true, message: messages.SuccessCreateShow } }, });
          showObject.dispatch({ type: Types.SET_SHOW_EDIT_VISIBLE, payload: { EditVisible: false }, });
          showObject.dispatch({ type: Types.SET_SHOW_FOUND_VISIBLE, payload: { FoundVisible: true }, });
          showObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true }, });
          let flagToChange = showObject.state.show.needToUpdate;
          showObject.dispatch({ type: Types.UPDATE_SHOW_FLAG, payload: { needToUpdate: !flagToChange }, });


          SetIsLoading(false);
        },
        (error) => {
          SetIsLoading(false);
          showObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: false, message: error } }, });
        }
      );
  };
  const UpdateShow = async (show: IShow) => {
    await updateShowAPICall(show)
      .then(handleResponse)
      .then(
        (result) => {
          showObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: true, message: messages.SuccessUpdatedShow } }, });
          showObject.dispatch({ type: Types.SET_SHOW_EDIT_VISIBLE, payload: { EditVisible: false }, });
          showObject.dispatch({ type: Types.SET_SHOW_FOUND_VISIBLE, payload: { FoundVisible: true }, });
          showObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true }, });
          let flagToChange = showObject.state.show.needToUpdate;
          showObject.dispatch({ type: Types.UPDATE_SHOW_FLAG, payload: { needToUpdate: !flagToChange }, });


          SetIsLoading(false);
        },
        (error) => {
          SetIsLoading(false);
          showObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: false, message: error } }, });
        }
      );
  };

  const DeleteShow = async (showToDelete: IShow) => {
    await deleteShowAPICall(showToDelete)
      .then(handleResponse)
      .then(
        (result) => {
          showObject.dispatch({ type: Types.UPDATE_SHOW_FLAG, payload: { needToUpdate: true } });
          showObject.dispatch({ type: Types.SET_SHOW_FOUND_VISIBLE, payload: { FoundVisible: true } });
          showObject.dispatch({ type: Types.SET_SHOW_EDIT_VISIBLE, payload: { EditVisible: false } });
          showObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true } });
          showObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: true, message: messages.SuccessDeleteShow } } });
        },
        (error) => {
          showObject.dispatch({ type: Types.SET_OUTCOME, payload: { operationOutcome: { success: false, message: error } }, });
        }
      );
  };


  const handleConfirmationDeleteDialog = (event: boolean) => {
    SetOpenConfirmationDialog(false);
    if (event === true) {
      DeleteShow(showObject.state.show.selectedShow?.show as IShow);
    }
  };


  const classes = useStyles();

  return (
    <div>
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={true}
          content="Are you sure you like to delete this show ?"
          onResult={(event) => handleConfirmationDeleteDialog(event)}
        />
      ) : null}

      {isLoading ? <CircularProgress className="centered" /> : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item sm={6} xs={12}>
            <FormControl className="full-width">
              <InputLabel>Show</InputLabel>

              <Controller
                as={
                  <Select>
                    {!props.programList ||
                      props.programList.map((prg) => (
                        <MenuItem key={prg.programID} value={prg.programID}>
                          {prg.description}
                        </MenuItem>
                      ))}
                  </Select>
                }
                name="programID"
                rules={{ required: true }}
                control={control}
              />
            </FormControl>
            <small className="red">{errors.programID && 'Program is required'} </small>
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              inputRef={register({ required: true})}
              className="full-width"
              name="datePerformance"
              id="datetime-local"
              label="Performance date"
              type="datetime-local"
              defaultValue={CreateTodayDate4Control()}

              InputLabelProps={{
                shrink: true,
              }}
            />
            <small className="red">{errors.datePerformance && 'date is invalid'} </small>
          </Grid>

          <Grid item sm={6} xs={12}>
            <TextField
              name="price"
              className="full-width"
              id="standard-number"
              defaultValue={props.pricePerStudent}
              label="Price"
              type="decimal"
              inputRef={register({ required: true, pattern: /^^(\d*\.)?\d+$/i })}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <small className="red">{errors.price && 'Price is invalid'} </small>
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              name="numberOfPeople"
              className="full-width"
              id="standard-number"
              label="People"
              defaultValue="0"
              type="number"
              inputRef={register({ required: true, maxLength: 5 })}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <small className="red">{errors.price && 'Number is invalid'} </small>

          </Grid>
          <Grid item xs={12}>
            <TextField
              name="notes"
              className="full-width"
              label="Notes"
              multiline
              inputRef={register({ required: false })}
            />
          </Grid>
        </Grid>
        <div className='mt-1'>
          <Grid container spacing={2}>
            <Grid item sm={3} md={3} xs={12}>
              <GeneralButton
                className="full-width"
                type="submit"
                content="update"
              />
            </Grid>
            <Grid item sm={1} md={1} xs={12}>
              <GeneralAddButton
                type="reset"
                onClick={handleNewShowEvent}
                size='small'
              />
            </Grid>
            {showObject.state.show.isNew ? null : (<Grid item sm={2} md={1} lg={1} xs={12}>
              <GeneralDeleteButton
                content='delete'
                onClick={deleteShowHandler}
              />
            </Grid>
            )}

          </Grid>
        </div>

        <Grid container spacing={1}>

        </Grid>
      </form>
    </div>
  );
};

export default ShowEdit;
