import React, { useContext, useState, useEffect } from 'react';
import { CustomerContextUseReducer } from '../Customer/CustomerContext';
import { handleResponse } from '../autorization/handleResponse';
import {
  makeStyles,
  Grid,
  CircularProgress,
  
} from '@material-ui/core';


import { GeneralCard } from '../general/GeneralCard';
import {  Colors, LightenDarkenColor, isBlank } from '../Utils';
import { withRouter } from 'react-router';
import BackToCustomers from '../general/BackToCustomers';
import { GeneralAddButton } from '../general/GeneralButton';
import { ToastContainer, toast } from 'react-toastify';
import { ReminderContextUseReducer } from './ReminderContext';
import { IReminderCardData, IReminder } from '../interfaces/IReminder';
import { Types } from './ReminderReducer';
import { fetchReminderAPICall } from '../services/DataServices';
import Moment from 'react-moment';
import ReminderEdit from './ReminderEdit';
const useStyles = makeStyles({
  root: {},
  wrapper: {
    marginTop: '20px',
  },
  gray: {
    backgroundColor: 'lightgray',
  },
  generalButton: {
    background: Colors.mainBackgroundColor,
    '&:hover': {
      color: 'black',
      background: LightenDarkenColor(Colors.mainBackgroundColor, 40),
    },
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 1,
  },
});

const ReminderPresenter = (props: any) => {
  const { history } = props;

  const customerObjectNew = useContext(CustomerContextUseReducer);
  const reminderObject = useContext(ReminderContextUseReducer);

  
  const [reminders, SetReminders] = useState<IReminderCardData[]>([]);
  const [isLoading, SetIsLoading] = useState(false);
  const classes = useStyles();
  const SetMyReminders = (passedReminders: IReminder[]) => {
    
    let reminder_1 = passedReminders.map((v) => {
      return { reminder: v };
    }) as IReminderCardData[];

    SetReminders(reminder_1.sort((a, b) => ((a.reminder?.dueDate as Date) < (b.reminder?.dueDate as Date) ? 1 : -1)));
    SetIsLoading(false);
  };

  const addNewReminder = () => {
    reminderObject.dispatch({ type: Types.SET_REMINDER_EDIT_VISIBLE, payload: { EditVisible: true }, });
    reminderObject.dispatch({ type: Types.SET_REMINDER_FOUND_VISIBLE, payload: { FoundVisible: false }, });
    reminderObject.dispatch({ type: Types.SET_CURRENT_REMINDER, payload: { selectedReminder: {} }, });
    reminderObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: false }, });

    reminderObject.dispatch({ type: Types.SET_OPEARTION_DISABLED, payload: { operationDisabled: true }, });
    reminderObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: true }, });
    reminderObject.dispatch({ type: Types.SET_IS_DIRTY, payload: { isDirty: false }, });
  };


  const showSuccessMessage = (value: string, success: boolean) => {
    if (success)
      toast.success(value, { autoClose: 6000 });
    else
      toast.error(value, { autoClose: 6000 });
  }

  useEffect(() => {
    SetIsLoading(true);

    const fetchReminders = async (customerID: number | undefined) => {
      try {
        console.log('fetch reminder');
        await fetchReminderAPICall(customerID as number)
          .then(handleResponse)
          .then((rowData) => SetMyReminders(rowData));
      } catch (e) {
        console.log(e);
      }
    };


    fetchReminders(
      customerObjectNew?.state.customer.selectedCustomer?.customer === undefined
        ? 0
        : customerObjectNew?.state.customer.selectedCustomer?.customer
          ?.customerID
    );
  }, [
    customerObjectNew?.state.customer,
    reminderObject?.state.reminder.needToUpdate,
   
  ]);

  

  useEffect(() => {
    if (!isBlank(reminderObject.state.reminder.operationOutcome.message))
        showSuccessMessage(reminderObject.state.reminder.operationOutcome.message, true);

  }, [reminderObject.state.reminder.operationOutcome]);


  const handleClick = (passedBackReminder: IReminderCardData) => {
    reminderObject.dispatch({ type: Types.SET_CURRENT_REMINDER, payload: { selectedReminder: passedBackReminder }, });
    reminderObject.dispatch({ type: Types.SET_REMINDER_EDIT_VISIBLE, payload: { EditVisible: true }, });
    reminderObject.dispatch({ type: Types.SET_REMINDER_FOUND_VISIBLE, payload: { FoundVisible: false }, });
    reminderObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: false } });
    reminderObject.dispatch({type: Types.SET_IS_DIRTY, payload: { isDirty: false}});
    reminderObject.dispatch({ type: Types.UPDATE_REMINDER_FLAG, payload: { needToUpdate: false }, });
    reminderObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: false }, });
    reminderObject.dispatch({ type: Types.SET_OPEARTION_DISABLED, payload: { operationDisabled: false }, });

  };

  return (
    <div>
      <ToastContainer autoClose={6000} />

      <div className="mt-1 mb-1">
        {reminderObject.state.reminder.setShowNewButton ? (
          <div>
            <GeneralAddButton 
               onClick={addNewReminder}
            />
          </div>
        ) : null}
      </div>

      {reminderObject.state.reminder.FoundVisible ? (
        <Grid container className={classes.root} spacing={1}>
          {!reminders ||
            reminders.map((rmd) => (
              <Grid key={rmd.reminder?.reminderID} item sm={4} xs={12}>                {/*   <AdministratorCard admin={adm.admin} /> */}

                <GeneralCard
                  entity={rmd}
                  onSelect={handleClick}
                  typeId={rmd.reminder?.closed}
                  defaultBackgroundColor={'#66a1af'}
                  colorArray={[
                    { id: false, color: Colors.teacherColor },
                    { id: true, color: Colors.travelColor, },
                  ]}
                  entityId={
                    rmd.reminder?.reminderID === undefined ? 0 : rmd.reminder?.reminderID
                  }
                  children={() => (
                    <div>
                      <b className={rmd.reminder?.closed ? 'black' : 'white'}>{rmd.reminder?.note}</b>
                         <div className={rmd.reminder?.closed ? 'mt-1 black' : 'mt-1 white'}>
                            <span>Due date: </span>
                            <b>
                              <Moment format="DD MMMM, YYYY HH:mm">
                              {rmd.reminder?.dueDate}
                              </Moment>
                            </b>
                          </div>
                          <div className='mt-1'>
                              {rmd.reminder?.closed ? (<b>CLOSED</b>) : null}
                          </div>
                    </div>
                  )}
                />
              </Grid>
            ))}
        </Grid>
      ) : null}

    

      {isLoading ? <CircularProgress className="centered" /> : null}
      
      {reminderObject.state.reminder.EditVisible ? (
        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={12}>
            <ReminderEdit
              
              reminder={
                reminderObject?.state.reminder.selectedReminder?.reminder == null
                  ? undefined
                  : reminderObject?.state.reminder.selectedReminder?.reminder
              }
            />
          </Grid>
        </Grid>
      ) : null}
    
    
    </div>
  );
};

export default withRouter(ReminderPresenter);
