import React, { createContext, useReducer, Dispatch } from "react";
import { ReminderDataType, ReminderActions, reminderReducer } from "./ReminderReducer";
import { IChartService } from "ag-grid-community";
import { IChildrenProp } from "../interfaces/IChildren";

type InitialStateType = {
  reminder: ReminderDataType;
};

const initialState = {
  reminder: { 
      selectedReminder: undefined, 
      needToUpdate: false,
      FoundVisible: true,
      EditVisible: false,
      setShowNewButton: true,
      isNew: true,
      operationDisabled: false,
      operationOutcome: {message: '', success: true},
      isDirty: false,
    },
};

const ReminderContextUseReducer = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<ReminderActions>;
}>({
  state: initialState,
  dispatch: () => null
});

const mainReducer = (
  { reminder  }: InitialStateType,
  action: ReminderActions 
) => ({
  reminder: reminderReducer(reminder, action),
});

const ReminderProviderUseReducer: React.FC<IChildrenProp> = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <ReminderContextUseReducer.Provider value={{ state, dispatch }}>
      {children}
    </ReminderContextUseReducer.Provider>
  );
};

export { ReminderProviderUseReducer, ReminderContextUseReducer };