import React, { useContext } from 'react';

import { BackToEntityButton } from '../general/GeneralButton';
import { HotelContextUseReducer } from './HotelContext';

import { Types } from './HotelReducer';

const HotelCommander = () => {
  const hotelObject = useContext(HotelContextUseReducer);

  const handleFoundHotels = () => {
    hotelObject.dispatch({ type: Types.SET_HOTEL_EDIT_VISIBLE, payload: { EditVisible: false }, });
    hotelObject.dispatch({ type: Types.SET_HOTEL_FOUND_VISIBLE, payload: { FoundVisible: true }, });
    hotelObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: true }, });
  };

  return (
    <div>
      {hotelObject.state.hotel.EditVisible ? (
        <div>
          <BackToEntityButton
            content='hotel list'
            onClick={handleFoundHotels}
          />

        </div>
      ) : null}

    </div>
  );
};

export default HotelCommander;
