import React, { useContext, useState, useEffect } from 'react';
import { CustomerContextUseReducer } from '../Customer/CustomerContext';
import { ToastContainer, toast } from 'react-toastify';
import AddIcon from '@material-ui/icons/Add';
import { handleResponse } from '../autorization/handleResponse';
import {  fetchCallsByCustomerId,  fetchAdministratorsCall, fetchHotelsAPICall,} from '../services/DataServices';
import {  ICall,  ICallCardData,} from '../interfaces/ICall';
import {  makeStyles,  Grid,  CircularProgress,  Fab,  Typography,} from '@material-ui/core';

import CallEdit from './CallEdit';
import { CallContextUseReducer } from './CallContext';

import { Types } from './CallReducer';
import { GeneralCard } from '../general/GeneralCard';
import { callResultsList, Colors, LightenDarkenColor, CallResultID, isBlank } from '../Utils';
import { withRouter } from 'react-router';
import Moment from 'react-moment';
import { IAdministrator } from '../interfaces/IAdministrator';
import { IHotel } from '../interfaces/IHotel';
import BackToCustomers from '../general/BackToCustomers';
import { GeneralAddButton } from '../general/GeneralButton';
const useStyles = makeStyles({
  root: {},
  wrapper: {
    marginTop: '20px',
  },
  dueDate: {
    textAlign: 'left',
    backgroundColor: 'lightgray',
    borderRadius: '12px',
    padding: '12px',
    marginBottom: '10px',
  },

  gray: {
    backgroundColor: 'lightgray',
  },
  generalButton: {
    background: Colors.mainBackgroundColor,
    '&:hover': {
      color: 'black',
      background: LightenDarkenColor(Colors.mainBackgroundColor, 40),
    },
  },

  customerName: {
    textAlign: 'left',
    fontSize: '20px',
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 1,
  },
});

const CallPresenter = (props: any) => {
  const { history } = props;

  const customerObject = useContext(CustomerContextUseReducer);
  const callObject = useContext(CallContextUseReducer);
  const [adminList, SetAdminList] = useState<IAdministrator[]>([]);
  const [hotelList, SetHotelList] = useState<IHotel[]>([]);

  const [calls, SetCalls] = useState<ICallCardData[]>([]);
  const [isLoading, SetIsLoading] = useState(false);
  const classes = useStyles();
  

  const showSuccessMessage = (value: string, success: boolean) => {
    if (success)
      toast.success(value, { autoClose: 6000 });
    else
      toast.error(value, { autoClose: 6000 });
  }

  const fetchHotels = async () => {
    try {
      await fetchHotelsAPICall()
        .then(handleResponse)
        .then((data) => SetHotelList(data));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const fetchAdmins = async (customerID: number) => {
      try {
        console.log('fetch administrators' + customerID.toString());
        await fetchAdministratorsCall(customerID)
          .then(handleResponse)
          .then((data) => SetAdminList(data));
      } catch (e) {
        console.log(e);
      }
    };

    fetchAdmins(customerObject.state.customer.selectedCustomer?.customer?.customerID === undefined ? 0 :  customerObject.state.customer.selectedCustomer?.customer?.customerID);
    fetchHotels();
  }, []);

  useEffect(() => {
    if (!isBlank(callObject.state.call.operationOutcome.message))
        showSuccessMessage(callObject.state.call.operationOutcome.message, true);

  }, [callObject.state.call.operationOutcome]);



  const SetMyCalls = (passedCalls: ICall[]) => {
    let calls = passedCalls.map((v) => {
      return { call: v };
    }) as ICallCardData[];

    
    SetCalls(calls.sort((a, b) => ((a.call?.lastUpdated === undefined ? {} : a.call?.lastUpdated) < (b.call?.lastUpdated === undefined ? {} : b.call?.lastUpdated)) ? 1 : -1));
    SetIsLoading(false);
  };

  const addNewCall = () => {
    callObject.dispatch({ type: Types.SET_CALL_EDIT_VISIBLE, payload: { EditVisible: true }, });
    callObject.dispatch({ type: Types.SET_CALL_FOUND_VISIBLE, payload: { FoundVisible: false }, });
    callObject.dispatch({ type: Types.SET_CURRENT_CALL, payload: { selectedCall: {} }, });
    callObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: false }, });
    callObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: true }, });
    callObject.dispatch({ type: Types.SET_IS_DIRTY, payload: { isDirty: false }, });

    
  };

  useEffect(() => {
    SetIsLoading(true);

    const fetchCalls = async (customerID: number | undefined) => {
      try {
        console.log('fetch call');
        await fetchCallsByCustomerId(customerID === undefined ? 0 : customerID)
          .then(handleResponse)
          .then((rowData) => SetMyCalls(rowData));
      } catch (e) {
        console.log(e);
      }
    };
    fetchCalls(
      customerObject?.state.customer.selectedCustomer?.customer === undefined
        ? 0
        : customerObject?.state.customer.selectedCustomer?.customer
          ?.customerID
    );
  }, [
    customerObject?.state.customer,
    callObject?.state.call.needToUpdate,
  ]);
  
  const handleClick = (passedBackCall: ICallCardData) => {
    callObject.dispatch({ type: Types.SET_CURRENT_CALL, payload: { selectedCall: passedBackCall }, });
    callObject.dispatch({ type: Types.SET_CALL_EDIT_VISIBLE, payload: { EditVisible: true }, });
    callObject.dispatch({ type: Types.SET_CALL_FOUND_VISIBLE, payload: { FoundVisible: false }, });
    callObject.dispatch({ type: Types.SET_IS_NEW, payload: { isNew: false } });
    callObject.dispatch({ type: Types.UPDATE_CALL_FLAG, payload: { needToUpdate: false }, });
    callObject.dispatch({ type: Types.SET_SHOW_NEW_BUTTON, payload: { setShowNewButton: false }, });
    callObject.dispatch({ type: Types.SET_OPEARTION_DISABLED, payload: { operationDisabled: false  }, });
  };

  return (
    <div>
      <ToastContainer autoClose={6000} />

        {callObject.state.call.setShowNewButton ? (
          <div>
            <GeneralAddButton 
             onClick={addNewCall}
            />
          </div>
        ) : null}
      

      <div className="mt-1">
      {callObject.state.call.FoundVisible ? (
        <Grid container className={classes.root} spacing={1}>
          {!calls ||
            calls.map((callObj) => (
              <Grid key={callObj.call?.callID} item sm={4} xs={12}>                {/*   <AdministratorCard admin={adm.admin} /> */}

                <GeneralCard
                  entity={callObj}
                  onSelect={handleClick}
                  typeId={callObj.call?.result}
                  defaultBackgroundColor={'#66a1af'}
                  colorArray={[
                    { id: CallResultID.Closed, color: Colors.closedCallColor },
                    { id: CallResultID.ConfirmedPerformance, color: Colors.confirmedPerformanceCallColor },
                    { id: CallResultID.CustomerHasNotRead, color: Colors.rejectedCustomerColor },
                    { id: CallResultID.DateDiscussion, color: Colors.DateDiscussionColor },
                    { id: CallResultID.Declined, color: Colors.rejectedCustomerColor },
                    { id: CallResultID.ReshedulingProcess, color: Colors.ReshedulingProcessColor },
                    { id: CallResultID.GenralEnquiry, color: Colors.generalEnquiryColor },
                    { id: CallResultID.Performance, color: Colors.performanceCallColor },
                    { id: CallResultID.PeriodicalMonitoring, color: Colors.periodicalMonitoringColor },
                    { id: CallResultID.Travel, color: Colors.travelColor },
                  ]}
                  entityId={
                    callObj.call?.callID === undefined ? 0 : callObj.call?.callID
                  }
                  children={() => (
                    <div>

                      <p className={classes.customerName}>
                        <b>{callObj.call?.shortNotes}</b>
                      </p>

                      <p className={classes.customerName}>
                        <b> {callResultsList.find(item => item.id === callObj.call?.result)?.Name}</b>
                        
                      </p>


                      <div className={classes.dueDate}>
                        <span>Due date: </span>
                        <b>
                          <Moment format="DD MMMM, YYYY HH:mm">
                            {callObj.call?.callBackDate}
                          </Moment>
                        </b>
                      </div>

                    </div>
                  )}
                />
              </Grid>
            ))}
        </Grid>
      ) : null}

</div>

      {isLoading ? <CircularProgress className="centered" /> : null}

      {callObject.state.call.EditVisible ? (
        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={12}>
            <CallEdit
              adminList={adminList}
              hotelList={hotelList}
              call={
                callObject?.state.call.selectedCall?.call == null
                  ? undefined
                  : callObject?.state.call.selectedCall?.call
              }
            />
          </Grid>
        </Grid>
      ) : null}

    </div>
  );
};

export default withRouter(CallPresenter);
