import React, { ReactNode } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authenticationService } from './authentication.service';
import { tempoPassword } from '../Utils';

interface IProps {
    children: ReactNode;
    // any other props that come into the component
}

export const PrivateRoute = ({ component, Component, ...rest }: any) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue
        let isEmpty  = Object.keys(currentUser).length === 0;
        if (isEmpty) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }

        // authorised so return component
        return <Component {...props} />
    }} />
)

export const PrivateRouteWebApiKeyProvider = ({ component: Component, ...rest }: any) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentApiKey
         let userPopulated : boolean = currentUser !== tempoPassword;

        

        if (!userPopulated) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }

        // authorised so return component
        return <Component {...props} />
    }} />
)
export const PrivateRouteWorking = ({ component: Component, ...rest }: any) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue
         let userPopulated : boolean = Object.keys(currentUser).length !== 0;

        if (!userPopulated) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }

        // authorised so return component
        return <Component {...props} />
    }} />
)

export const PrivateRoute1 = ({component, isAuthenticated, ...rest}: any) => {
    
//const currentUser = authenticationService.currentUserValue
    
        
        
    const routeComponent = (props: any) => (
    
        isAuthenticated
            ? React.createElement(component, props)
            : <Redirect to={{pathname: '/login'}}/>
    );
    return <Route {...rest} render={routeComponent}/>;
};