import React, { useContext, useEffect, useState } from 'react';
import GeneralButton from '../general/GeneralButton';

import { CSVLink } from "react-csv";
import northIslandPicture from '../../images/nortIsland.png';
import { CreateDashStringDate, CreateStringDate } from '../Utils';
import southIslandPicture from '../../images/southIsland.png';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { CustomerCallContextUseReducer } from './CustomerCallContext';
import Moment from 'react-moment';
import { handleResponse } from '../autorization/handleResponse';
import {
  makeStyles,
  Grid,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import {
  fetchParticularCustomerCalls,
  fetchCustomerAroundPerformancesCustomerCalls,

} from '../services/DataServices';
import { ICustomerCall } from '../interfaces/ICustomerCall';
import { IExcelFormatIncome } from '../interfaces/ICustomerCall';
import { GeneralCard } from '../general/GeneralCard';
import {
  redirectToGoogleMap,
  colorConstants,
  CustomerCallQueryID,
  Colors,
  LightenDarkenColor,
  NorthIsland,
} from '../Utils';
import CustomerCallDetail from './CustomerCallDetail';
import { Types } from './CustomerCallReducer';
import { Types as TypesCustomer } from '../Customer/CustomerReducer';
import { CustomerContextUseReducer } from '../Customer/CustomerContext';

const LastUpdated = 'LAST_UPDATED';
const DueDate = 'DUE_DATE';

const useStyles = makeStyles({
  root: {},
  shortNote: {
    textAlign: 'left',
    fontStyle: 'italic',
  },
  FilterButton: {
    color: 'black',
    background: Colors.mainBackgroundColor,
    "&:hover": {
      color: "white",
      background: LightenDarkenColor(Colors.mainBackgroundColor, 40)
    },
    "&.Mui-selected": {
      color: "white",
      background: LightenDarkenColor(Colors.mainBackgroundColor, 40),
      "&:hover": {
        color: "white",
        background: LightenDarkenColor(Colors.mainBackgroundColor, 40)
      },

    },
  },
  googleButton: {
    marginBottom: '20px',
    float: 'left',
  },
  dueDate: {
    textAlign: 'left',
    backgroundColor: 'lightgray',
    borderRadius: '12px',
    padding: '12px',
    marginBottom: '10px',
  },
  customerName: {
    textAlign: 'left',
    fontSize: '20px',
  },
  gray: {
    backgroundColor: 'lightgray',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 1,
  },
});



const CustomerCallsPresenter = () => {
  const classes = useStyles();
  const customerCallObject = useContext(CustomerCallContextUseReducer);
  const customerObject = useContext(CustomerContextUseReducer);

  const isDueDate = (customerCall: ICustomerCall) => {
    let currentDate = new Date();
    let customerCallDate = new Date(customerCall.dueDate);
    let result =
      currentDate > customerCallDate
        ? colorConstants.dueDate
        : colorConstants.nonDue;
    return result;
  };

  const SetMyCustomerCalls = (passedCustomers: ICustomerCall[]) => {
    SetCurrentDateSortOrderCall(customerCallObject.state.customerCall.filterType, passedCustomers);
    SetIsLoading(false);
  };



  const SetExcelData = (passedCustomerCalls: ICustomerCall[]) => {
    let sortedCalls = passedCustomerCalls.sort((a, b) => ((a.dueDate as Date) > (b.dueDate as Date) ? 1 : -1));
    let invoicedCalls = sortedCalls.filter(a => a.isInvoiced == true);
    var result = invoicedCalls.map(data => ({
      description: data.customerName,
      date: CreateDashStringDate(data.dueDate),
      sumWithoutGST: '',
      fullTotal: data.invoiceTotal,

    }) as IExcelFormatIncome);
    SetExportIncomes(result);

  };

  const SetUpcomingShowsData = (passedCustomerCalls: ICustomerCall[]) => {
    let sortedCalls = passedCustomerCalls.sort((a, b) => ((a.dueDate as Date) > (b.dueDate as Date) ? 1 : -1));
    var result = sortedCalls.map(data => ({
      description: data.customerName,
      fulldate: CreateStringDate(data.dueDate),
      gpsCoordinates: data.gpS_COORDINATES,

    }) as IExcelFormatIncome);
    SetExportUpcomingShows(result);

  };


  const [customerCalls, SetCustomerCalls] = useState<ICustomerCall[]>([]);
  const [exportIncomes, SetExportIncomes] = useState<IExcelFormatIncome[]>([]);

  const [exportUpcomingShows, SetExportUpcomingShows] = useState<IExcelFormatIncome[]>([]);

  const handleClick = (passedCustomerCall: ICustomerCall) => {
    customerCallObject.dispatch({
      type: Types.SET_CURRENT_CUSTOMER_CALL,
      payload: { currentCustomerCall: passedCustomerCall },
    });


  };

  const fetchParticular = async (value: string) => {
    console.log('fetchParticular');
    try {
      await fetchParticularCustomerCalls(value)
        .then(handleResponse)
        .then((rowData) => SetMyCustomerCalls(rowData));
    } catch (e) {
      console.log(e);
    }
  };

  const fetchAround = async () => {
    console.log('fetchAround: daysAgo ' + customerObject.state.customer.daysAgo);
    try {
      await fetchCustomerAroundPerformancesCustomerCalls(customerObject.state.customer.daysAgo)
        .then(handleResponse)
        .then((rowData) => SetMyCustomerCalls(rowData));
    } catch (e) {
      console.log(e);
    }
  };

  const SetCurrentDateSortOrderCall = (data: string, passedCustomers: ICustomerCall[]) => {

    if (data === LastUpdated) {
      SetCustomerCalls(
        passedCustomers.sort((a, b) => (a.lastUpdated < b.lastUpdated ? 1 : -1))
      );
    }
    if (data === DueDate) {
      SetCustomerCalls(
        passedCustomers.sort((a, b) => (a.dueDate > b.dueDate ? 1 : -1))
      );
    }
    let needToRefresh = customerCallObject.state.customerCall.needToRefresh;
    customerCallObject.dispatch({ type: Types.SET_NEED_TO_REFRESH, payload: { needToRefresh: !needToRefresh }, });
  };


  useEffect(() => {
    SetIsLoading(true);
    switch (customerCallObject.state.customerCall.queryID) {
      case CustomerCallQueryID.PerformancesAround: {
        fetchAround();
        return;
      }

      default:
        fetchParticular(customerCallObject.state.customerCall.queryID);
    }
  }, [

    customerCallObject.state.customerCall.operation,
    customerObject.state.customer.daysAgo,
  ]);

  const [isLoading, SetIsLoading] = useState(true);

  const GetCallName = () => {
    return customerCallObject.state.customerCall.operation;
  };

  const changeDaysAgoData = (passedValue: number) => {
    customerObject.dispatch({ type: TypesCustomer.SET_DAYS_AGO, payload: { daysAgo: passedValue } });

  };


  const visitingGPS = (gps_coordinates: string) => {
    console.log(gps_coordinates);
    redirectToGoogleMap(gps_coordinates);
  };



  const handleFormat = (event: React.MouseEvent<HTMLElement>, newFilter: string) => {
    customerCallObject.dispatch({ type: Types.SET_FILTER_TYPE, payload: { filterType: newFilter } });
    SetCurrentDateSortOrderCall(newFilter, customerCalls);
  };

  return (
    <div>
      <div>{isLoading ? <CircularProgress className="centered" /> : null}</div>


      <h4 className="left">{GetCallName()}</h4>


      <Grid container spacing={0}>
        <Grid item sm={5} xs={12}>
          <CSVLink className='left mt-1 mb-1'
            data={exportUpcomingShows}
            filename='UpcomingShows'
            onClick={() => {
              SetUpcomingShowsData(customerCalls);
            }}
          >
            Download offline data
          </CSVLink>

        </Grid>
      </Grid>

      <Grid container spacing={0}>



        <div>{GetCallName() == "Performances around today" ? <CSVLink className='left mt-1 mb-1'
          data={exportIncomes}
          filename='Incomes'
          onClick={() => {
            SetExcelData(customerCalls);
          }}
        >
          Download Income CSV file

        </CSVLink>
          : null}</div>

        <Grid item sm={5} xs={12}>
          <ToggleButtonGroup
            value={customerCallObject.state.customerCall.filterType}

            onChange={handleFormat}
            exclusive
            aria-label="text formatting"
          >
            <ToggleButton
              value="LAST_UPDATED"
              className={classes.FilterButton}
              aria-label="bold">
              Sort by last updated (most recent first)
            </ToggleButton>
            <ToggleButton
              value="DUE_DATE"
              className={classes.FilterButton}
              aria-label="italic">
              Sort by due date
            </ToggleButton>
          </ToggleButtonGroup>


        </Grid>
      </Grid>

      <Grid container spacing={0} className='mt-1 mb-1'>
        <Grid item sm={2} xs={12}>
          <TextField
            onChange={(event) => changeDaysAgoData(Number(event?.target.value))}
            className="full-width"
            defaultValue={customerObject.state.customer.daysAgo}
            label="Number of days back"
            type="number"

            InputLabelProps={{
              shrink: true,
            }}
          />


        </Grid>

      </Grid>

      <Grid container spacing={1}>
        <Grid item sm={6}>
          <div className="scrollable">
            <Grid container spacing={1}>
              {!customerCalls ||
                customerCalls.map((cst) => (
                  <Grid key={cst.callID} item sm={12} xs={12}>
                    <GeneralCard
                      entity={cst}
                      onSelect={handleClick}
                      typeId={isDueDate(cst)}
                      defaultBackgroundColor={'#66a1af'}
                      colorArray={[
                        {
                          id: colorConstants.dueDate,
                          color: Colors.dangerBackgroundColor,
                        },
                        {
                          id: colorConstants.nonDue,
                          color: Colors.secondBackgroundColor,
                        },
                      ]}
                      entityId={cst.callID === undefined ? 0 : cst.callID}
                      children={() => (
                        <div>
                          <p className={classes.customerName}>
                            <b>{cst.customerName}</b>
                            {cst.island === NorthIsland ? (<img src={northIslandPicture} width='10%' height='10%' />) : (<img src={southIslandPicture} width='10%' height='10%' />)}
                          </p>

                          <p className={classes.shortNote}>
                            <b>{cst.shortNote}</b>
                          </p>

                          <div className={classes.dueDate}>
                            <span>Due date: </span>
                            <b>
                              <Moment format="DD MMMM, YYYY HH:mm">
                                {cst.dueDate}
                              </Moment>
                            </b>
                          </div>

                          <div className={classes.dueDate}>
                            <span>Last Updated: </span>
                            <b>
                              <Moment format="DD MMMM, YYYY HH:mm">
                                {cst.lastUpdated}
                              </Moment>
                            </b>
                          </div>

                          {cst.gpS_COORDINATES != '' ? (
                            <GeneralButton
                              className={classes.googleButton}
                              onClick={() => visitingGPS(cst.gpS_COORDINATES)}
                              content="GOOGLE MAP"
                            />
                          ) : null}
                        </div>
                      )}
                    />
                  </Grid>
                ))}
            </Grid>
          </div>
        </Grid>
        <Grid item sm={6}>
          {!isLoading ? (
            <div className="scrollable">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <CustomerCallDetail
                    customerCall={
                      customerCallObject.state.customerCall.currentCustomerCall
                    }
                  />
                </Grid>
              </Grid>
            </div>

          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomerCallsPresenter;
