import React, { createContext, useReducer, Dispatch } from "react";
import { ShowDataType, showReducer, ShowActions } from "./ShowReducer";
import { IChildrenProp } from "../interfaces/IChildren";

type InitialStateType = {
  show: ShowDataType;

};

const initialState = {
    show: { 
      selectedShow: undefined, 
      needToUpdate: false,
      FoundVisible: true,
      EditVisible: false,
      setShowNewButton: true,
      isNew: true,
      operationDisabled: false,
      operationOutcome: {message: '', success: true},
      isDirty: false,      
    },
  
};

const ShowContextUseReducer = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<ShowActions>;
}>({
  state: initialState,
  dispatch: () => null
});

const mainReducer = (
  { show  }: InitialStateType,
  action: ShowActions 
) => ({
  show: showReducer(show, action),
  
});

const ShowProviderUseReducer: React.FC<IChildrenProp> = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <ShowContextUseReducer.Provider value={{ state, dispatch }}>
      {children}
    </ShowContextUseReducer.Provider>
  );
};

export { ShowProviderUseReducer, ShowContextUseReducer };