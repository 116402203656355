import React, { useState, useContext } from 'react';
import CustomerSearch from './CustomerSearch';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormControlLabel, Grid } from '@material-ui/core';
import { TealSwitch } from '../Utils';
import CustomerCommander from './CustomerCommander';
import CustomerPresenter from './CustomerPresenter';
import CustomerQuickBooking from './CustomerQuickBooking';
import { CustomerContextUseReducer } from './CustomerContext';
import ConnectionInfo from '../general/ConnectionInfo';


const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '95%',
    margin: '0 auto',
  },
}));

const Customer = () => {
  const customerObject = useContext(CustomerContextUseReducer);
  const classes = useStyles();
  const [renderCallSwitch, SetRenderCallSwitch] = useState<boolean>(false);
  const renderQuickCall = (quickBooking: boolean) => {
    if (customerObject.state.customer.selectedCustomer != undefined) // we should not render if no customer selected
    SetRenderCallSwitch(quickBooking);
   
  }

  return (
    <div className={classes.wrapper}>

        <Grid container spacing={1}>
          <Grid item xs={12}>
          <h3>Customers</h3>
            <CustomerSearch />
          </Grid>
          <Grid item xs={12}>
          <ConnectionInfo />
                  
          </Grid>

          <Grid item xs={12}>
          {customerObject.state.customer.selectedCustomer != undefined ? (
          <FormControlLabel
                    control={
                      <TealSwitch
                      onChange={(event) => renderQuickCall(event.target.checked)}  
                    />
                    }
                    label="Quick booking"
                    />   ) : null}

          </Grid>
          <Grid item xs={12}>
            <CustomerCommander />
          </Grid>
          
          
        {!renderCallSwitch ? (
        <Grid item xs={12}>
            <CustomerPresenter />

        </Grid>
      ) : null}

        </Grid>

        {renderCallSwitch ? (
        <Grid>
          <CustomerQuickBooking />

        </Grid>
      ) : null}




    </div>
  );
};

export default Customer;
