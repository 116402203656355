import { ICustomerCardData } from "../interfaces/ICustomer";
import { ActionMap } from "../general/ActionMap";

export enum Types {
  SET_SEARCH_STRING = 'SET_SEARCH_STRING',
  SET_EXACT_SEARCH_STRING = 'SET_EXACT_SEARCH_STRING',
  SET_CUSTOMER_EDIT_VISIBLE = 'SET_CUSTOMER_EDIT_VISIBLE',
  SET_CUSTOMER_FOUND_VISIBLE = 'SET_CUSTOMER_FOUND_VISIBLE',
  SET_CURRENT_CUSTOMER = 'SET_CURRENT_CUSTOMER',
  SET_IS_DIRTY = 'SET_IS_DIRTY',
  SET_NEED_TO_FETCH = 'SET_NEED_TO_FETCH',
  SET_IS_NEW = 'SET_IS_NEW',
  SET_EDIT_DISABLE = 'SET_EDIT_DISABLE',
  SET_DAYS_AGO = 'SET_DAYS_AGO', // used on Customer calls screen to hold number of days bacjk to render performances around
  SET_COUNTRY = 'SET_COUNTRY', 
}

export type CustomerDataType = {
  whatToSearch: string;
  customerEditVisible: boolean;
  customerFoundVisible: boolean;
  selectedCustomer: ICustomerCardData | undefined;
  isDirty: boolean;
  needToFetch: boolean;
  exactSearch: boolean; // flag saying that we need to call API to search THIS particular customer name, not just part of the string.
  isNew: boolean;
  editDisable: boolean;
  daysAgo: number;
  country: string;
};

type CustomerPayload = {
  [Types.SET_EXACT_SEARCH_STRING]: {
    exactSearch: boolean;
  };

  [Types.SET_SEARCH_STRING]: {
    whatToSearch: string;
  };
  [Types.SET_CUSTOMER_EDIT_VISIBLE]: {
    customerEditVisible: boolean;
  };
  [Types.SET_CUSTOMER_FOUND_VISIBLE]: {
    customerFoundVisible: boolean;
  };
  [Types.SET_CURRENT_CUSTOMER]: {
    selectedCustomer: ICustomerCardData | undefined;
  };
  [Types.SET_IS_DIRTY]: {
    isDirty: boolean;
  };
  [Types.SET_NEED_TO_FETCH]: {
    needToFetch: boolean;
  };
  [Types.SET_IS_NEW]: {
    isNew: boolean;
  };
  [Types.SET_EDIT_DISABLE]: {
    editDisable: boolean;
  };
  [Types.SET_DAYS_AGO]: {
    daysAgo: number;
  };
  [Types.SET_COUNTRY]: {
    country: string;
  };

  
};

export type CustomerActions = ActionMap<CustomerPayload>[keyof ActionMap<
    CustomerPayload
>];

export const productReducer = (state: CustomerDataType, action: CustomerActions) => {
  switch (action.type) {
    case Types.SET_SEARCH_STRING:
      return {
        ...state,
        whatToSearch: action.payload.whatToSearch,
      };
      case Types.SET_EXACT_SEARCH_STRING:
        return {
          ...state,
          exactSearch: action.payload.exactSearch,
        };
  
    case Types.SET_CUSTOMER_EDIT_VISIBLE:
      return {
        ...state,
        customerEditVisible: action.payload.customerEditVisible,
      };
    case Types.SET_CUSTOMER_FOUND_VISIBLE:
      return {
        ...state,
        customerFoundVisible: action.payload.customerFoundVisible,
      };
      case Types.SET_CURRENT_CUSTOMER:
        return {
          ...state,
          selectedCustomer: action.payload.selectedCustomer,
        };
        case Types.SET_IS_DIRTY:
          return {
            ...state,
            isDirty: action.payload.isDirty,
          };
          case Types.SET_NEED_TO_FETCH:
            return {
              ...state,
              needToFetch: action.payload.needToFetch,
            };

            case Types.SET_IS_NEW:
              return {
                ...state,
                isNew: action.payload.isNew,
              };
              case Types.SET_EDIT_DISABLE:
                return {
                  ...state,
                  editDisable: action.payload.editDisable,
                };
                case Types.SET_DAYS_AGO:
                  return {
                    ...state,
                    daysAgo: action.payload.daysAgo,
                  };   
                  case Types.SET_COUNTRY:
                    return {
                      ...state,
                      country: action.payload.country,
                    };   
  
                
        
    default:
      return state;
  }
};


