
import urls from "../Utils";
import { authHeader, authHeaderClientCalendar } from "../autorization/auth-header";
import { IBookingForm } from "../interfaces/IBookingForm";
import { ICustomer } from "../interfaces/ICustomer";
import { IAdministrator } from "../interfaces/IAdministrator";
import { IAdminEmail } from "../interfaces/IAdminEmail";
import { ICall } from "../interfaces/ICall";
import { ICallNote } from "../interfaces/ICallNote";
import { IShow } from "../interfaces/IShow";
import { IEvent } from "../interfaces/IEvent";
import { IReminder } from "../interfaces/IReminder";
import { IExpenseCardData, IExpense } from "../interfaces/IExpense";
import { IQuote } from "../interfaces/IQuote";
import { IHotel } from "../interfaces/IHotel";
import {  IInvoice } from "../interfaces/IInvoice";
import { IQuickBooking } from "../interfaces/IQuickBooking";
import { IEmailJob } from "../interfaces/IEmailJob";


export const requestOptions = () => { 
    return { method: 'GET', headers: authHeader() } 
};

const requestOptionsCalendarClient = () => { 
    return { method: 'GET', headers: authHeaderClientCalendar() } 
};

export const createBooking = async (value : IBookingForm) =>
{    
    return fetch(`${urls.mainDomain}/api/QuickCustomer/CreateQuickCustomer`, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value),
    });
}


export const fetchProgramList = async () =>
{
    const response = await fetch(
        `${urls.mainDomain}/api/Program/GetPrograms`, requestOptions());
      return response;
}

export const fetchFilteredProgramList = async (typeCustomerID: any) =>
{
    const response = await fetch(
        `${urls.mainDomain}/api/Program/GetFilteredPrograms?typeCustomerID=${typeCustomerID}`, requestOptions());
      return response;
}

export const fetchTypeCustomerList = async () =>
{
    const response = await fetch(
        `${urls.mainDomain}/api/TypeCustomer/GetTypeCustomers`, requestOptions());
      return response;
}


export const fetchCustomersCall = async (value: any, country: string) =>
{
    const response = await fetch(
        `${urls.GetCustomersUrl}?whatToSearch=${value}&country=${country}`,requestOptions()
      );
      return response;

}

export const deleteCustomerAPI = async (value: ICustomer) =>
{
    return await fetch(urls.DeleteCustomerUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

export const fetchParticularCustomer = async (value: any) =>
{
    const response = await fetch(
        `${urls.GetParticularCustomerUrl}?customerIDToSearch=${value}`,requestOptions()
      );
      return response;
}

export const fetchParticularCustomerCalls = async (result: string) =>
{
    const response = await fetch(
        `${urls.GetParticularCustomerCallsUrl}?result=${result}`,requestOptions()
      );
      return response;
}

export const fetchCustomerAroundPerformancesCustomerCalls = async (daysAgo: number) =>
{
    const response = await fetch(
        `${urls.GetPerformancesAroundCustomerCallsUrl}?daysAgo=${daysAgo}`,requestOptions()
        
      );
      return response;
}

export const fetchAdministratorsCall = async (value: any) =>
{
    const response = fetch(
        `${urls.GetAdminUrl}?customerid=${value}`, requestOptions()
      )
      
      return response;
}

export const fetchAdministratorsEmailsCall = async (value: number) =>
{
    return await fetch(
        `${urls.GetAdminEmailsByAdminIDUrl}?adminid=${value}`, requestOptions()
      );
}

export const fetchCustomerCallNotesByCallId = async (callId: number) =>
{
    return await fetch(
        `${urls.GetCallNotesByCallIdUrl}?callId=${callId}`, requestOptions()
      );
}


export const createCustomerAPICall = async (value: ICustomer) =>
{
    return await fetch(urls.CreateCustomerUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}
export const createQuickCallAPICall = async (value: IQuickBooking) =>
{
    return await fetch(urls.CreateQuickPerformanceCallUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

export const updateCustomerAPICall = async (value: ICustomer) =>
{
    return await fetch(urls.UpdateCustomerUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});


}

export const createAdminAPICall = (value: any) =>
{
    return fetch(urls.CreateAdminUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

export const deleteAdminAPICall = async (value: IAdministrator) =>
{
    return await fetch(urls.DeleteAdminUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

export const makeAdminInactiveAPICall = async (value: IAdministrator) =>
{
    return await fetch(urls.MakeInactiveAdminUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

export const updateAdminAPICall = async (value: IAdministrator) =>
{
    return await fetch(urls.UpdateAdminUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}


export const createAdminEmailsAPICall = async (value: IAdminEmail[] | undefined) =>
{
    return await fetch(urls.CreateAdminEmailUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

export const  getCustomerSearchHistory = async () =>
{
   const response = await fetch(
    `${urls.GetHistorysUrl}`, requestOptions()
  );
  return response;

}



export const getDistrictAPICall = async (country : string) =>
{
    const response = fetch(
        `${urls.mainDomain}/api/District/GetDistricts?country=${country}`, requestOptions());
      
      return response;
}


export const getPositionsAPICall= async () =>
{
    const response = fetch(
        `${urls.GetPositionUrl}`, requestOptions()
      );
      return response;
}



export const fetchHotelsAPICall = async () =>
{
    const response = fetch(
        `${urls.getHotelsUrl}`, requestOptions()
      );
     return response;
}

export const createHotelAPICall = async (value: IHotel) =>
{
    return await fetch(urls.CreateHotelUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}



export const updateHotelAPICall = async (value: IHotel) =>
{
    return await fetch(urls.UpdateHotelUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

export const deleteHotelAPICall = async (value: IHotel) =>
{
    return await fetch(urls.DeleteHotelUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}


// CALLS
export const fetchCallsByCustomerId = async (value: number) =>
{
    const response = fetch(
        `${urls.GetCallByCustomerIDUrl}?customerid=${value}`, requestOptions()
      )
      
      return response;
}

export const createCallAPICall = async (value: ICall) =>
{
    return await fetch(urls.CreateCallUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

export const updateCallAPICall = async (value: ICall) =>
{
    return await fetch(urls.UpdateCallUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

export const createCallNotesAPICall = async (value: ICallNote[] | undefined) =>
{
    return await fetch(urls.CreateCallNoteUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}
export const deleteCallAPICall = async (value: ICall) =>
{
    return await fetch(urls.DeleteCallUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

// SHOWS

export const fetchPricePerStudentAPICall = async () =>
{
    const response = fetch(
        `${urls.GetPricePerStudentUrl}`, requestOptions()
      )
     
      return response;
}
export const fetchShowAPICall = async (customerID: number) =>
{
    const response = fetch(
        `${urls.GetShowUrl}?customerid=${customerID}`, requestOptions()
      )
     
      return response;
}

export const createShowAPICall = async (value: IShow) =>
{
    return await fetch(urls.CreateShowUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

export const updateShowAPICall = async (value: IShow) =>
{
    return await fetch(urls.UpdateShowUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

export const deleteShowAPICall = async (value: IShow) =>
{
    return await fetch(urls.DeleteShowUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}


// REMINDERS
export const fetchReminderAPICall = async (customerID: number) =>
{
    const response = fetch(
        `${urls.GetReminderUrl}?customerid=${customerID}`, requestOptions()
      )
     
      return response;
}

export const createReminderAPICall = async (value: IReminder) =>
{
    return await fetch(urls.CreateReminderUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

export const updateReminderAPICall = async (value: IReminder) =>
{
    return await fetch(urls.UpdateReminderUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

export const deleteReminderAPICall = async (value: IReminder) =>
{
    return await fetch(urls.DeleteReminderUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}


// EVENTS
export const fetchEventsAPI = async (startTime: string, isThatMe: boolean) =>
{
    if (isThatMe)  
    return await fetch(
        `${urls.GetEventsUrl}?startDate=${startTime}`, requestOptions()
      ) 
      return await fetch(
        `${urls.GetEventsUrl}?startDate=${startTime}`, requestOptionsCalendarClient()
      )

}
export const createEventAPICall = async (value: IEvent) =>
{
    return await fetch(urls.CreateEventUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

export const updateEventAPICall = async (value: IEvent) =>
{
    return await fetch(urls.UpdateEventUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

export const deleteEventAPICall = async (value: IEvent) =>
{
    return await fetch(urls.DeleteEventUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

// EXPENSES

export const fetchAccountsAPIcall = async () =>
{
    return await fetch(
        `${urls.GetAccountsUrl}`, requestOptions()
      )
}

export const fetchTypeExpenseAPIcall = async () =>
{
    return await fetch(
        `${urls.GetTypeExpenseUrl}`, requestOptions()
      )
}

export const fetchExpensesAPIcall = async (startTime: string, endTime: string) =>
{
    return await fetch(
        `${urls.GetExpensesUrl}?startDate=${startTime}&endDate=${endTime}`, requestOptions()
      )
}

export const createExpenseAPICall = async (value: IExpense) =>
{
    return await fetch(urls.CreateExpenseUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

export const updateExpenseAPICall = async (value: IExpense) =>
{
    return await fetch(urls.UpdateExpenseUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

export const deleteExpenseAPICall = async (value: IExpense) =>
{
    return await fetch(urls.DeleteExpenseUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

// QUOTES
export const fetchQuotesAPI = async () =>
{
    return await fetch(
        `${urls.GetQuotesUrl}`, requestOptions()
      )
}
export const createQuoteAPICall = async (value: IQuote) =>
{
    return await fetch(urls.CreateQuoteUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

export const sendQuoteAPICall = async (value: IQuote) =>
{
    return await fetch(urls.SendQuoteUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}


export const updateQuoteAPICall = async (value: IQuote) =>
{
    return await fetch(urls.UpdateQuoteUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

export const deleteQuoteAPICall = async (value: IQuote) =>
{
    return await fetch(urls.DeleteQuoteUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

// INVOICES
export const fetchDueInvoicesAPI = async () =>
{
    return await fetch(
        `${urls.GetDueInvoicesUrl}`, requestOptions()
      )
}

export const PayThisInvoiceAPI = async (value: IInvoice) =>
{
    return await fetch(urls.PayThisInvoiceUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

export const fetchInvoicesAPI = async (customerID: any) =>
{
    return await fetch(
        `${urls.GetInvoicesUrl}?customerid=${customerID}`, requestOptions()
      )
}
export const createInvoiceAPICall = async (value: IInvoice) =>
{
    return await fetch(urls.CreateInvoiceUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

export const sendInvoiceAPICall = async (value: IInvoice) =>
{
    return await fetch(urls.SendInvoiceUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}


export const updateInvoiceAPICall = async (value: IInvoice) =>
{
    return await fetch(urls.UpdateInvoiceUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

export const deleteInvoiceAPICall = async (value: IInvoice) =>
{
    return await fetch(urls.DeleteInvoiceUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

export const fetchEmailJobsAPI = async () =>
{
    return await fetch(
        `${urls.getEmailJobsUrl}`, requestOptions()
      )
}

export const sendEmailAPICall = async (value: IEmailJob) =>
{
    let url = urls.sendEmailUrl;
    if (value.password != null)
    url = urls.sendEmailPrivatePartyAccountUrl;
    return await fetch(url, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}
export const sendEmailPrivateAccountAPICall = async (value: IEmailJob) =>
{
    return await fetch(urls.sendEmailPrivatePartyAccountUrl, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(value)});
}

export const fetchNextAvailableEmailJobsAPI = async () =>
{
    return await fetch(
        `${urls.getNextAvailableEnailJobtUrl}`, requestOptions()
      )
}



