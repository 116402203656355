import React, { useContext, useState, useEffect } from 'react';
import { InvoiceContextUseReducer, InvoiceProviderUseReducer } from './InvoiceContext';
import { useHistory } from 'react-router';
import ConfirmationDialog from '../general/ConfirmationDialog';
import { Types } from './InvoiceReducer';
import { Types as TypesCustomer } from '../Customer/CustomerReducer';
import { handleResponse } from '../autorization/handleResponse';
import {
  makeStyles,
  Grid,
  CircularProgress,
  Fab,

} from '@material-ui/core';

import { GeneralCard } from '../general/GeneralCard';
import { messages, Colors, LightenDarkenColor, isBlank, TypeCustomer, copyToClipBoard } from '../Utils';
import { ToastContainer, toast } from 'react-toastify';
import { fetchDueInvoicesAPI, PayThisInvoiceAPI } from '../services/DataServices';
import Moment from 'react-moment';
import { IProgram } from '../interfaces/IProgram';
import { IInvoiceCardData, IInvoice } from '../interfaces/IInvoice';
import GeneralButton from '../general/GeneralButton';
import { CustomerContextUseReducer } from '../Customer/CustomerContext';
import { ICustomer, ICustomerCardData } from '../interfaces/ICustomer';
import FileCopyTwoTone from '@material-ui/icons/FileCopyTwoTone';


const useStyles = makeStyles({
  root: {},
  wrapper: {
    marginTop: '20px',
  },
  copy_email_button:
  {
    marginLeft: '10px',
    height:30,
    
    backgroundColor: Colors.mainBackgroundColor,
    "&:hover": {
      color: "black",
      background: LightenDarkenColor(Colors.mainBackgroundColor, 40),
    },
  },
  big_text : {
    fontSize: 20,
  },

  margin_top_10:
  {
    width: '95%',
    marginTop: '1em',
    margin: '0 auto',
  },

  gray: {
    backgroundColor: 'lightgray',
  },
  generalButton: {
    background: Colors.mainBackgroundColor,
    '&:hover': {
      color: 'black',
      background: LightenDarkenColor(Colors.mainBackgroundColor, 40),
    },
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 1,
  },
});

const InvoiceDue = () => {
  const invoiceObject = useContext(InvoiceContextUseReducer);
  const customerObject = useContext(CustomerContextUseReducer);
  let history = useHistory();  
  const [openConfirmationDialog, SetOpenConfirmationDialog] = useState<boolean>(false);
  const [totalInvoiceDue, SetTotalInvoiceDue] = useState<number>();
  const [invoices, SetInvoices] = useState<IInvoiceCardData[]>([]);
  const [isLoading, SetIsLoading] = useState(false);

  const classes = useStyles();


  
  const showSuccessMessage = (value: string, success: boolean) => {
    if (success)
      toast.success(value, { autoClose: 6000 });
    else
      toast.error(value, { autoClose: 6000 });
  }
  
  useEffect(() => {
    if (!isBlank(invoiceObject.state.invoice.operationOutcome.message))
      showSuccessMessage(invoiceObject.state.invoice.operationOutcome.message, true);

  }, [invoiceObject.state.invoice.operationOutcome]);
  
  const handleProceedToTheCustomer = (passedInvoice: IInvoiceCardData | undefined) => {
    let customer = { name: passedInvoice?.invoice?.customer?.name, customerID: passedInvoice?.invoice?.customer?.customerID } as ICustomer;
    let cCust = { customer: customer } as ICustomerCardData;
    
    customerObject.dispatch({ type: TypesCustomer.SET_CURRENT_CUSTOMER, payload: { selectedCustomer: cCust } });
    customerObject.dispatch({ type: TypesCustomer.SET_SEARCH_STRING, payload: { whatToSearch: cCust.customer?.name === undefined ? '' : cCust.customer?.name } });
    customerObject.dispatch({ type: TypesCustomer.SET_CUSTOMER_EDIT_VISIBLE, payload: { customerEditVisible: false }, });
    customerObject.dispatch({ type: TypesCustomer.SET_CUSTOMER_FOUND_VISIBLE, payload: { customerFoundVisible: true }, });
    customerObject.dispatch({ type: TypesCustomer.SET_EDIT_DISABLE, payload: { editDisable: true }, });
    customerObject.dispatch({ type: TypesCustomer.SET_EXACT_SEARCH_STRING, payload: { exactSearch: true }, });

    history.push('/customers');
  }


  const SetMyInvoiceDues = (passedInvoices: IInvoice[]) => {

    let invoice = passedInvoices.map((v) => {
      return { invoice: v };
    }) as IInvoiceCardData[];

    console.log(invoice);
    SetInvoices(invoice.sort((a, b) => ((a.invoice?.date as Date) < (b.invoice?.date as Date) ? -1 : 1)));
    SetIsLoading(false);

    let sum: number = 0;
    passedInvoices.forEach(a => sum += a.total as number);
    let TwoDecimalsSum = Math.round(sum * 100) / 100;
    SetTotalInvoiceDue(TwoDecimalsSum);
    invoiceObject.dispatch({ type: Types.UPDATE_INVOICE_FLAG, payload: { needToUpdate: false }, });
  };

 
  useEffect(() => {
    SetIsLoading(true);
    const fetchDueInvoices = async () => {
      try {
        console.log('fetch invoice');
        await fetchDueInvoicesAPI()
          .then(handleResponse)
          .then((rowData) => SetMyInvoiceDues(rowData));
      } catch (e) {
        console.log(e);
      }
    };

    
    fetchDueInvoices();
  }, [invoiceObject?.state.invoice.needToUpdate]);


  const handleConfirmationPayInvoice = (event: boolean) => {
    SetOpenConfirmationDialog(false);
    if (event === true) {
      console.log('CURRENT INVOICE: ');
      console.log(invoiceObject.state.invoice.selectedInvoice?.invoice?.customerName);
      PayThisInvoice(invoiceObject.state.invoice.selectedInvoice?.invoice == undefined ? {} : invoiceObject.state.invoice.selectedInvoice.invoice);
   }
  };


  const PayThisInvoice = async (invoiceToPay: IInvoice) => {
    await PayThisInvoiceAPI(invoiceToPay)
      .then(handleResponse)
      .then(
        (result) => {


          invoiceObject.dispatch({type: Types.SET_OUTCOME, payload: { operationOutcome: { success: true, message: messages.SuccessPaidInvoice, }, }, });
          SetIsLoading(false);
          let flagToChange = invoiceObject.state.invoice.needToUpdate;
          invoiceObject.dispatch({ type: Types.UPDATE_INVOICE_FLAG, payload: { needToUpdate: !flagToChange }, });


        },
        (error) => {
          invoiceObject.dispatch({  type: Types.SET_OUTCOME, payload: { operationOutcome: { success: false, message: error } }, });
        }
      );
  };


  const handleClickPayInvoice = (passedBackInvoice: IInvoiceCardData) => {
    SetOpenConfirmationDialog(true);
    invoiceObject.dispatch({ type: Types.SET_CURRENT_INVOICE, payload: { selectedInvoice: passedBackInvoice }, });
  };


  return (
    <div>
      <ToastContainer autoClose={6000} />

      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={true}
          content="This invoice has been paid ?"
          onResult={(event) => handleConfirmationPayInvoice(event)}
        />
      ) : null}



      {isLoading ? <CircularProgress className="centered" /> : null}
      <div className={classes.margin_top_10}>

        <Grid container className={classes.root} spacing={1}>
          {!invoices ||
            invoices.map((inv) => (
              <Grid key={inv.invoice?.invoiceID} item  lg={4} sm={6} xs={12}>

                <GeneralCard
                  entity={inv}
                   onSelect={() => {
                      // handleProceedToTheCustomer(inv)
                   }
                    }
                  defaultBackgroundColor={'#66a1af'}
                  entityId={
                    inv.invoice?.invoiceID === undefined ? 0 : inv.invoice?.invoiceID
                  }
                  children={() => (
                    <div>
                      <b className={classes.big_text}>{inv.invoice?.customerName}</b>
                      <div>
                        <span>Due Date: </span>
                        <b>
                          <Moment format="DD MMMM, YYYY HH:mm">
                            {inv.invoice?.date}
                          </Moment>
                        </b>
                      </div>
                      <br />

                      <div>
                        <b>${inv.invoice?.total}</b>
                      </div>
                      <br />
                      <div>
                        <b>Reference: {inv.invoice?.referenceData}</b>
                        <Fab
                          className={classes.copy_email_button}
              
              onClick={() =>
                {
                  copyToClipBoard(
                  inv.invoice?.referenceData === undefined
                    ? ''
                    : inv.invoice?.referenceData
                )
              }}
              aria-label="add"
            >
              <FileCopyTwoTone />
            </Fab>
                      <br />
                      <br />
                      </div>
                      <GeneralButton
                              
                              onClick={() => { 
                                handleClickPayInvoice(inv)}
                              }
                              content="PAY THIS INVOICE"
                            />
                            <div>
                      <br />
                      <GeneralButton
                              
                              onClick={() => { 
                                handleProceedToTheCustomer(inv)}}
                                content="PROCEED TO CUSTOMER"
                            />
                            </div>
                    </div>
                  )}
                />
              </Grid>
            ))}
        </Grid>
        <hr></hr>
        <div>

          <Grid container className={classes.root} spacing={1}>
            <Grid item xs={6}>
              <h3 className="left">Total: ${totalInvoiceDue}</h3>
            </Grid>
          </Grid>
        </div>

      </div>

    </div>

  );
};

export default InvoiceDue;
