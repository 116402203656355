import { ActionMap } from "../general/ActionMap";

import { IMessage } from "../interfaces/IMessage";
import { BasicDataType } from "../Calls/CallReducer";
import { IHotelCardData } from "../interfaces/IHotel";


export enum Types {
  SET_CURRENT_HOTEL = 'SET_CURRENT_HOTEL',
  UPDATE_HOTEL_FLAG = 'UPDATE_HOTEL_FLAG',
  SET_HOTEL_EDIT_VISIBLE = 'SET_HOTEL_EDIT_VISIBLE',
  SET_HOTEL_FOUND_VISIBLE = 'SET_HOTEL_FOUND_VISIBLE',
  SET_SHOW_NEW_BUTTON = 'SET_SHOW_NEW_BUTTON',
  SET_IS_NEW = 'SET_IS_NEW',
  SET_OPEARTION_DISABLED = 'SET_OPEARTION_DISABLED',
  SET_OUTCOME = 'SET_OUTCOME',
  SET_IS_DIRTY = 'SET_IS_DIRTY',
 
}

export interface HotelDataType extends BasicDataType   {
  selectedHotel: IHotelCardData | undefined;
};

type HotelPayload = {
  [Types.SET_CURRENT_HOTEL]: {
    selectedHotel: IHotelCardData;
    
  };
  [Types.UPDATE_HOTEL_FLAG]: {
    needToUpdate: boolean;
  };
    [Types.SET_HOTEL_EDIT_VISIBLE]: {
    EditVisible: boolean;
  }; 
   [Types.SET_HOTEL_FOUND_VISIBLE]: {
    FoundVisible: boolean;
  };  

  [Types.SET_SHOW_NEW_BUTTON]: {
    setShowNewButton: boolean;
  };  
 
  [Types.SET_IS_NEW]: {
    isNew: boolean;
  };  
  [Types.SET_OPEARTION_DISABLED]: {
    operationDisabled: boolean;
  };  
  [Types.SET_OUTCOME]: 
  {
    operationOutcome: IMessage;
  };
   [Types.SET_IS_DIRTY]: {
    isDirty: boolean;
  }; 
};

export type HotelActions = ActionMap<HotelPayload>[keyof ActionMap<
  HotelPayload
>];

export const hotelReducer = (state: HotelDataType, action: HotelActions) => {
  switch (action.type) {
    case Types.SET_CURRENT_HOTEL:
      return {
        ...state,
        selectedQuote: action.payload.selectedHotel,
      };
      case Types.UPDATE_HOTEL_FLAG:
        return {
          ...state,
          needToUpdate: action.payload.needToUpdate,
        };
       case Types.SET_HOTEL_EDIT_VISIBLE:
          return {
            ...state,
            EditVisible: action.payload.EditVisible,
          };        
           case Types.SET_HOTEL_FOUND_VISIBLE:
            return {
              ...state,
              FoundVisible: action.payload.FoundVisible,
            };     

            case Types.SET_SHOW_NEW_BUTTON:
              return {
                ...state,
                setShowNewButton: action.payload.setShowNewButton,
              };                 
              case Types.SET_IS_NEW:
                return {
                  ...state,
                  isNew: action.payload.isNew,
                };     
                case Types.SET_OPEARTION_DISABLED:
                  return {
                    ...state,
                    operationDisabled: action.payload.operationDisabled,
                  };     

                  case Types.SET_OUTCOME:
                    return {
                      ...state,
                      operationOutcome: action.payload.operationOutcome,
                    };                       
                     case Types.SET_IS_DIRTY:
                      return {
                        ...state,
                        isDirty: action.payload.isDirty,
                      }; 

    default:
      return state;
  }
};


