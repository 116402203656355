import { ActionMap } from "../general/ActionMap";
import {  ICallCardData  } from "../interfaces/ICall";
import { IMessage } from "../interfaces/IMessage";


export enum Types   {
  SET_CURRENT_CALL = 'SET_CURRENT_CALL',
  UPDATE_CALL_FLAG = 'UPDATE_CALL_FLAG',
  SET_CALL_EDIT_VISIBLE = 'SET_CALL_EDIT_VISIBLE',
  SET_CALL_FOUND_VISIBLE = 'SET_CALL_FOUND_VISIBLE',
  SET_SHOW_NEW_BUTTON = 'SET_SHOW_NEW_BUTTON',
  SET_IS_NEW = 'SET_IS_NEW',
  SET_OPEARTION_DISABLED = 'SET_OPEARTION_DISABLED',
  SET_OUTCOME = 'SET_OUTCOME',
  SET_IS_DIRTY = 'SET_IS_DIRTY',
}

export interface BasicDataType  
{
  EditVisible: boolean; 
  FoundVisible: boolean;  
  needToUpdate: boolean;
  setShowNewButton: boolean;  
  isNew: boolean;
  operationDisabled: boolean;
  operationOutcome: IMessage;
  isDirty: boolean;
  
}

export interface CallDataType  extends BasicDataType {
  selectedCall: ICallCardData | undefined;
};




type CallPayload = {
  [Types.SET_CURRENT_CALL]: {
    selectedCall: ICallCardData;
    
  };
  [Types.UPDATE_CALL_FLAG]: {
    needToUpdate: boolean;
  };
    [Types.SET_CALL_EDIT_VISIBLE]: {
    EditVisible: boolean;
  }; 
   [Types.SET_CALL_FOUND_VISIBLE]: {
    FoundVisible: boolean;
  };  

  [Types.SET_SHOW_NEW_BUTTON]: {
    setShowNewButton: boolean;
  };  
 
  [Types.SET_IS_NEW]: {
    isNew: boolean;
  };  
  [Types.SET_OPEARTION_DISABLED]: {
    operationDisabled: boolean;
  };  

  [Types.SET_OUTCOME]: 
  {
    operationOutcome: IMessage;
  };
  [Types.SET_IS_DIRTY]: {
    isDirty: boolean;
  }; 

  
};

export type CallActions = ActionMap<CallPayload>[keyof ActionMap<
  CallPayload
>];

export const callReducer = (state: CallDataType, action: CallActions) => {
  switch (action.type) {
    case Types.SET_CURRENT_CALL:
      return {
        ...state,
        selectedCall: action.payload.selectedCall,
      };
      case Types.UPDATE_CALL_FLAG:
        return {
          ...state,
          needToUpdate: action.payload.needToUpdate,
        };
       case Types.SET_CALL_EDIT_VISIBLE:
          return {
            ...state,
            EditVisible: action.payload.EditVisible,
          };        
           case Types.SET_CALL_FOUND_VISIBLE:
            return {
              ...state,
              FoundVisible: action.payload.FoundVisible,
            };     

            case Types.SET_SHOW_NEW_BUTTON:
              return {
                ...state,
                setShowNewButton: action.payload.setShowNewButton,
              };                 
              case Types.SET_IS_NEW:
                return {
                  ...state,
                  isNew: action.payload.isNew,
                };     
                case Types.SET_OPEARTION_DISABLED:
                  return {
                    ...state,
                    operationDisabled: action.payload.operationDisabled,
                  };     
                  case Types.SET_OUTCOME:
                    return {
                      ...state,
                      operationOutcome: action.payload.operationOutcome,
                    };                       
                    case Types.SET_IS_DIRTY:
                      return {
                        ...state,
                        isDirty: action.payload.isDirty,
                      }; 


  
    default:
      return state;
  }
};


