import React, { useEffect, useState } from 'react'
import urls from '../Utils';
import { requestOptions } from '../services/DataServices';


export const ConnectionInfo = () => {

    const [connection, setConnection] = useState<string>();

    useEffect(() => {
      
        const fetchHotels = async () => {
          try {
            const response = await fetch(`${urls.getConnectionInfoUrl}`, requestOptions());
      
            if (!response.ok) {
              // Handle HTTP errors explicitly
            console.log('errr!!!!!');
            }
      
            const result = await response.json();
            setConnection(result);
      
            
          } catch (error) {
            // Handle other errors (e.g., network issues, JSON parsing)
            console.log('error after catch : ' + error);
            window.location.reload();
          }
        };
      
        fetchHotels();
      }, []);

  return (
    <div>ConnectionInfo : {connection}</div>
  )
}


export default ConnectionInfo;