import React, { useState, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { GeneralCard } from './general/GeneralCard';
import { ICustomerCardData } from './interfaces/ICustomer';
import { Grid, Button } from '@material-ui/core';

import {
  Theme,
  makeStyles,
  createStyles,
  Fab,
  Switch,
  FormControlLabel,
  withStyles,
} from '@material-ui/core';
import { Colors, LightenDarkenColor } from './Utils';
import GeneralButton, { GeneralAddButton } from './general/GeneralButton';
import ConfirmationDialog from './general/ConfirmationDialog';
import { withRouter } from 'react-router';
const useStyles = makeStyles({
  root: {},
  wrapper: {
    marginTop: '20px',
  },
  dueDate: {
    textAlign: 'left',
    backgroundColor: 'lightgray',
    borderRadius: '12px',
    padding: '12px',
    marginBottom: '10px',
  },

  gray: {
    backgroundColor: 'lightgray',
  },
  addNoteButton: {
    width: '100%',
    background: Colors.privateCustomerCustomerColor,
    color: 'white',
    '&:hover': {
      color: 'white',
      background: LightenDarkenColor(Colors.privateCustomerCustomerColor, 40),
    },
  },
});



const Home = (props: any) => {
  const { history } = props;

  const classes = useStyles();
  const [name, SetName] = useState('PETYA');
  const [openConfirmationDialog, SetOpenConfirmationDialog] = useState<boolean>(false);
  const [position, SetPosition] = useState('Deputy Principal');
  const [customer] = useState<ICustomerCardData>({
    customer: { name: 'Vasya', customerID: 123 },
  });
  const [entityId, SetEntityId] = useState<number>(1);

  const handleClick = (passedObject: ICustomerCardData) => {
    console.log(passedObject);
  };

  const handleResult = (event: boolean) => 
  {
    SetOpenConfirmationDialog(false); 
    console.log("event :" + event);
  }

  const deleteHandler = () => 
  {
   SetOpenConfirmationDialog(true);
  }

  useEffect(() => {
    history.push("/customers");
    
  }, [])
  
  return (
    <div>
      <br></br>

     {/*  <Grid container spacing={0}>
            <Grid item sm={3} md={2} lg={2}  xs={12}>

              <GeneralButton
                className="full-width"
                
                type="submit"
                content="update"
              />

            </Grid>

            <Grid item sm={2} md={1} lg={1} xs={12}>
              <GeneralAddButton
                size="medium"
               
              />

            </Grid>

            <Grid item sm={2} md={1} lg={1} xs={12}>
              <Button
                size='large'
                type="button"
                variant="contained"
              
              
              >
                DELETE
          </Button>

            </Grid>

          </Grid> */}

     
    </div>
  );
};

export default withRouter(Home);
