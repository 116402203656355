import { ActionMap } from "../general/ActionMap";
import {  IAdministratorCardData } from "../interfaces/IAdministrator";
import { IMessage } from "../interfaces/IMessage";
import { BasicDataType } from "../Calls/CallReducer";

export enum Types {
  SET_CURRENT_ADMIN = 'SET_CURRENT_ADMIN',
  UPDATE_ADMIN_FLAG = 'UPDATE_ADMIN_FLAG',
  SET_SHOW_NEW_BUTTON = 'SET_SHOW_NEW_BUTTON',
  SET_IS_NEW = 'SET_IS_NEW',
  SET_OPEARTION_DISABLED = 'SET_OPEARTION_DISABLED',
  SET_OUTCOME = 'SET_OUTCOME',
  SET_IS_DIRTY = 'SET_IS_DIRTY',
 
}

export interface AdministratorDataType extends BasicDataType   {
  selectedAdministrator: IAdministratorCardData | undefined;
};

type AdministratorPayload = {
  [Types.SET_CURRENT_ADMIN]: {
    selectedAdministrator: IAdministratorCardData;
    
  };
  [Types.UPDATE_ADMIN_FLAG]: {
    needToUpdate: boolean;
  };

  [Types.SET_SHOW_NEW_BUTTON]: {
    setShowNewButton: boolean;
  };  
 
  [Types.SET_IS_NEW]: {
    isNew: boolean;
  };  
  [Types.SET_OPEARTION_DISABLED]: {
    operationDisabled: boolean;
  };  
  [Types.SET_OUTCOME]: 
  {
    operationOutcome: IMessage;
  };
   [Types.SET_IS_DIRTY]: {
    isDirty: boolean;
  }; 
};

export type AdministratorActions = ActionMap<AdministratorPayload>[keyof ActionMap<
  AdministratorPayload
>];

export const adminReducer = (state: AdministratorDataType, action: AdministratorActions) => {
  switch (action.type) {
    case Types.SET_CURRENT_ADMIN:
      return {
        ...state,
        selectedAdministrator: action.payload.selectedAdministrator,
      };
      case Types.UPDATE_ADMIN_FLAG:
        return {
          ...state,
          needToUpdate: action.payload.needToUpdate,
        };
              case Types.SET_IS_NEW:
                return {
                  ...state,
                  isNew: action.payload.isNew,
                };     
                case Types.SET_OPEARTION_DISABLED:
                  return {
                    ...state,
                    operationDisabled: action.payload.operationDisabled,
                  };     

                  case Types.SET_OUTCOME:
                    return {
                      ...state,
                      operationOutcome: action.payload.operationOutcome,
                    };                       
                     case Types.SET_IS_DIRTY:
                      return {
                        ...state,
                        isDirty: action.payload.isDirty,
                      }; 

    default:
      return state;
  }
};


